import React, { useState, useEffect, useMemo } from "react";
import axios from 'axios'
import Linkify from 'react-linkify';
import Select, { components } from 'react-select';
import firebase from "../../config"
import { storage } from "../../config";
/* import PDF from '../pdf/index' */
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import SaveAltTwoToneIcon from '@material-ui/icons/SaveAltTwoTone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useHistory } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import NotListedLocationIcon from '@material-ui/icons/Help';
import Weights from './weights'
import FWeight from './Fweight'
import HWeight from './Hweight'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import FirstPageTwoToneIcon from '@mui/icons-material/FirstPageTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import DatePicker from '../DatePicker/DatePicker';
import Paper from '@material-ui/core/Paper';
import TextFields from '@mui/material/TextField';

import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import TextareaAutosize from '@mui/base/TextareaAutosize';
import fileDownload from 'js-file-download';
import { format } from 'date-fns'
import { confirm } from "react-confirm-box";

import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';

import { isPlatform } from "@ionic/react";
import { FileOpener } from '@awesome-cordova-plugins/file-opener';
import { File } from '@awesome-cordova-plugins/file';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils } from '@fortawesome/free-solid-svg-icons'
import { faAppleAlt } from '@fortawesome/free-solid-svg-icons'
import { faRoute } from '@fortawesome/free-solid-svg-icons'
import { faCampground } from '@fortawesome/free-solid-svg-icons'
import { faTruckMonster, faGauge } from '@fortawesome/pro-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faSackDollar } from '@fortawesome/pro-solid-svg-icons'
import { faNotebook } from '@fortawesome/pro-solid-svg-icons'
import { faWeightScale } from '@fortawesome/pro-solid-svg-icons'
import { faGaugeCircleBolt } from '@fortawesome/pro-solid-svg-icons'
import { faCalendarDays } from '@fortawesome/pro-solid-svg-icons'
import { faSuitcase, faPencil, faListCheck } from '@fortawesome/pro-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import TableFooter from '@material-ui/core/TableFooter';

import Zoom from '@mui/material/Zoom';

/* import Pdf from 'react-to-pdf'; */

import data_meals from '../../trip_cats.json';
import { el } from "date-fns/locale";

const useItemsUser = () => {
  const user = firebase.auth().currentUser;
  const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
    const unsubscribe = firebase
      .firestore() //access firestore
      .collection("user") //access "items" collection
      .where('id', '==', `${user.uid}`)
      .onSnapshot(snapshot => {
        //You can "listen" to a document with the onSnapshot() method.
        const listItemsUsers = snapshot.docs.map(doc => ({
          //map each document into snapshot
          id: doc.id, //id and data pushed into items array
          ...doc.data() //spread operator merges data to id.
        }));
        setItems(listItemsUsers); //items is equal to listItems
      });
    return () => unsubscribe();
  }, []);
  return items;
};

/* 
So far we have passed the setEditing prop to index.js. 
Now we are going to pass the currentItem prop
*/
const UpdateItem = ({ setEditing, currentItem, editing, updateItem }) => {
  /*
  Sets the state of the item to the current item
  */

  const headerlogo = document.querySelector(".topnavigation img");
  
  headerlogo.classList.add('indent');

  const user = firebase.auth().currentUser;
  const user1 = user.uid;

  /*
  Side effect is that without UseEffect if you start editing one item, 
  then try to switch to another item, nothing will happen.
  The component is already open, and although the state 
  on the parent has changed, it's not registered down to the props.
  We want to let the UpdateItem form component know the props have changed.
  With the Effect Hook, we create a callback function that updates the item 
  state with the new prop thats being sent through.
  */

  const [gearcat, setGearCat] = useState("")

  const dataT = useItemsUser()
  const newata = dataT[0]
  const getdataT = dataT.length ? dataT[0] : []

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    // setItem(currentItem);
    // console.log("useEffect passes the currentItem: ", currentItem);
    setGearCat(currentItem?.Category)
  }, [currentItem]);
  const [allImages, setImages] = useState(currentItem?.Document);
  useEffect(() => {
    setImages(allImages?.reduce((acc, current) => {
      const x = acc.find(item => item.Test2 === current.Test2);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []))
  }, [allImages?.length])

  const [searched, setSearched] = useState("");

  const onSubmit = () => {
    item.Gear = (listUser);
    item.Food = (listUserF);
    item.Meals = (listUserM);
    item.Document = allImages;
    updateItem({ currentItem }, item);
    setSearched("");
  };


  var [item, setItem] = useState(currentItem);

  useEffect(() => {
    setItem(currentItem)
  }, [currentItem])

  const onChange = e => {
    setItem({
      ...item,
      [e.target.name]: e.target.value
    });
  };

  const onChange2 = e => {
    setItem({
      ...item,
      [e.target.name]: e.target.value
    });
  };
  const [list, setList] = React.useState([item]);

  const handleBrandChange = (obj) => {
    setItem({ ...item, Brand: obj.label });
  };

  const [country, setCountry] = useState({ id: 52, value: item.Category, label: item.Category });

  useEffect(() => {
    setCountry({ id: 52, value: item.Category, label: item.Category })
  },[item])
  const [lang, setLang] = useState(null);
  const [langList, setLangList] = useState([]);
  const [addtraivler, setaddTraverler] = useState(currentItem?.Travelers)

  const handleCategoryChange = (obj) => {
    setCountry(obj);
    setLangList(obj.subCategory);
    setLang(null);
    setGearCat(obj.label)
    setItem({ ...item, Category: obj.label });
  };

  const handleSubCategoryChange = (obj) => {
    setItem({ ...item, SubCategory: obj.label });
  };

  const style = {
    control: base => ({
      ...base,
      border: 0,
      borderRadius: 0,
      // This line disable the blue border
      boxShadow: "none",
      margin: 0,
      padding: 0,
      zIndex: 9999,
    }),
    valueContainer: (provided, state) => ({
      padding: 0,

    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
  }



  const [selectedTab, setSelectedTab] = useState(0);

  // Function called anytime the tab changes
  function handleTabChange(event, value) {
    setSelectedTab(value);
  }



  const getFromFirebase = () => {
    // setImages((allImages) => []);
    //1.
    let storageRef = storage.ref(`${user1}/${currentItem.id}`);
    //2.
    storageRef.listAll().then(function (res) {
      //3.
      res.items.forEach((imageRef) => {
        imageRef.getDownloadURL().then((url) => {
          //4.
          if (allImages.indexOf(url) === -1) {
            setImages((allImages) => [...allImages, ({ Test: url, Test2: imageRef.name })]);
          }
        });
      });

    })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteFromFirebase = (url) => {
    //1.
    let pictureRef = storage.refFromURL(url);
    //2.
    pictureRef.delete()
      .then(() => {
        //3.
        setImages(allImages.filter((image) => image.Test !== url));
        alert("File has been removed");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [image, setImage] = useState(null);

  const onImageChange = (e) => {
    const reader = new FileReader();
    let file = e.target.files[0]; // get the supplied file
    // if there is a file, set image to that file
    if (file) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          console.log(file);
          setImage(file);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
      // if there is no file, set image back to null
    } else {
      setImage(null);
    }
  };
  const uploadToFirebase = e => {
    e.preventDefault();
    //1.
    if (image) {
      //2.
      const storageRef = storage.ref(`${user1}/${currentItem.id}`);
      //3.
      const imageRef = storageRef.child(image.name);
      //4.
      imageRef.put(image)
        //5.
        .then(() => {
          alert("Successfully uploaded.");
          getFromFirebase();
        });
    } else {
      alert("Please upload an image first.");
    }
  };

  const [inputFields, setInputFields] = useState([
    { Date: '', From: '', To: '', Distance: '', Stay: '', Activities: '', Notes: '', Activities: '' }
  ]);

  const handleAddFields = () => {
    const values = [...inputFields];
    item.Itinerary.push({ Date: '', From: '', To: '', Document: [], Distance: '', Stay: '', Notes: '', Activities: '' });
    setInputFields(values);
  };


  const handleRemoveFields = index => {
    const values = [...inputFields];
    item.Itinerary.splice(index, 1);
    setInputFields(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "Date") {
      values[index].Date = event.target.value;
    }
    if (event.target.name === "From") {
      values[index].From = event.target.value;
    }
    if (event.target.name === "To") {
      values[index].To = event.target.value;
    }
    if (event.target.name === "Distance") {
      values[index].Distance = event.target.value;
    }
    if (event.target.name === "Stay") {
      values[index].Stay = event.target.value;
    }
    if (event.target.name === "Notes") {
      values[index].Notes = event.target.value;
    }
    if (event.target.name === "Activities") {
      values[index].Activities = event.target.value;
    }

    setInputFields(values);
  };

  const optionsWithLabelChangeTrip = {
    closeOnOverlayClick: false,
    labels: {
      confirmable: "Yes",
      cancellable: "No"
    }
  };

  const deleteItem = async (id,options) => {
    const result = await confirm("Are you sure you want to delete this trip?", optionsWithLabelChangeTrip);
    if (result) {
      firebase
        .firestore()
        .collection("trips")
        .doc(id)
        .delete();
      setEditing(false);
    }
  };

  const [packeditem, setpackeditem] = useState(false);
  const [packeditem2, setpackeditem2] = useState(false);
  const [packeditem3, setpackeditem3] = useState(false);

  const handleChange = (event) => {
    setItem({ ...item, Bucket: event.target.checked });

  };

  const handleChange2 = (event) => {
    setpackeditem(event.target.checked);
  };
  const handleChange21 = (event) => {
    setpackeditem2(event.target.checked);
  };
  const handleChange22 = (event) => {
    setpackeditem3(event.target.checked);
  };
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);

  // This is my handler method that gets triggered when a checkbox get's checked/unchecked
  // ..and toggles the state of the checkbox
  const handleCheckboxChangeN = (vag) => {
    const isChecked = checkedCheckboxes.some(checkedCheckbox => checkedCheckbox.value === vag.value)
    if (isChecked) {
      setCheckedCheckboxes(
        checkedCheckboxes.filter(
          (checkedCheckbox) => checkedCheckbox.value !== vag.value
        )
      );
    } else {
      setCheckedCheckboxes(checkedCheckboxes.concat(vag));
    }
  };

  const useItems = () => {
    const user = firebase.auth().currentUser;
    const user1 = user.uid;
    const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
    useEffect(() => {
      const unsubscribe = firebase
        .firestore() //access firestore
        .collection("gear") //access "items" collection
        .where("User", "==", user1)
        .orderBy("SubCategory", "asc")
        .orderBy("Brand", "asc")
        .orderBy("Name", "asc")
        .onSnapshot(snapshot => {
          //You can "listen" to a document with the onSnapshot() method.
          const listItems = snapshot.docs?.map(doc => ({
            //map each document into snapshot
            id: doc.id, //id and data pushed into items array
            Name: doc.data().Name,
            Weight: doc.data().Weight,
            Category: doc.data().Category,
            SubCategory: doc.data().SubCategory,
            Brand: doc.data().Brand,
            value: doc.data().Name.replace(/( )/g, ''),
            ...doc.data()
          }));
          setItems(listItems); //items is equal to listItems
        });
    }, []);
    return items;
  };

  const useItemsF = () => {
    const user = firebase.auth().currentUser;
    const user1 = user.uid;
    const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
    useEffect(() => {
      const unsubscribe = firebase
        .firestore() //access firestore
        .collection("food") //access "items" collection
        .where("User", "==", user1)
        .orderBy("Name", "asc")
        .onSnapshot(snapshot => {
          //You can "listen" to a document with the onSnapshot() method.
          const listItems = snapshot.docs?.map(doc => ({
            //map each document into snapshot
            id: doc.id, //id and data pushed into items array
            Name: doc.data().Name,
            Weight: doc.data().Weight,
            Category: doc.data().Category,
            Quantity: "1",
            value: doc.data().Name.replace(/( )/g, ''),
            ...doc.data()
          }));
          setItems(listItems); //items is equal to listItems
        });
    }, []);
    return items;
  };

  const useItemsM = () => {
    const user = firebase.auth().currentUser;
    const user1 = user.uid;
    const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
    useEffect(() => {
      const unsubscribe = firebase
        .firestore() //access firestore
        .collection("meals") //access "items" collection
        .where("User", "==", user1)
        .orderBy("CategoryValue", "asc")
        .orderBy("Name", "asc")
        .onSnapshot(snapshot => {
          //You can "listen" to a document with the onSnapshot() method.
          const listItems = snapshot.docs?.map(doc => ({
            //map each document into snapshot
            id: doc.id, //id and data pushed into items array
            Name: doc.data().Name,
            Weight: doc.data().Weight,
            Quantity: '1',
            Category: doc.data().Category,
            value: doc.data().Name.replace(/( )/g, ''),
            ...doc.data()
          }));
          setItems(listItems); //items is equal to listItems
        });
    }, []);
    return items;
  };

  const listItem = useItems();
  const listItemF = useItemsF();
  const listItemM = useItemsM()
  const [listUser, setListUser] = useState(item.Gear);
  const [listUserF, setListUserF] = useState(item.Food);
  const [listUserM, setListUserM] = useState(item.Meals);
  const [togles, settogles] = useState(false)
  const [SearchOwner, setSearchOwner] = useState({ Name: "ANY" })
  const [saveState, setSaveState] = useState(false)

  const getDaysArray = function(s, e) {
    const a = []
    for (let d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
      a.push(new Date(d));
    }
    return a;
  };
  
  const abc = new Date(item.DateTo.toDate())
  const def = new Date(item.DateFrom.toDate())
  
  var daylist = getDaysArray(def, abc);

  const optionsWithLabelChange = {
    closeOnOverlayClick: false,
    labels: {
      confirmable: "Automatic",
      cancellable: "Manual"
    }
  };

  const populateItinerary = async (options) => {
    const result = await confirm("If choosing Automatic the itinerary will be recreated to suit your new dates and all existing data will be cleared. When selecting Manual, data will be retained but all dates will need to be adjusted manually.", optionsWithLabelChange);
    if (result) {
      const thesedays = daylist?.map(v => new Date(Date.parse(new Intl.DateTimeFormat("fr-CA", {year: "numeric", month: "2-digit", day: "2-digit"}).format(v))).toISOString().slice(0,10));
      const countdays = thesedays.length;
      item.Itinerary = [];
      for (var i = 0; i < thesedays.length; i++) {
        item.Itinerary.push({ Date: thesedays[i], From: '', To: '', Distance: '', Stay: '', Notes: '', Activities: '' });
      }
      setInputFields(item.Itinerary);
      settogles(false)
    }
  }

  const CombineeItinerary = () => {
    if (togles == false) {
      const thesedays = daylist?.map(v => new Date(Date.parse(new Intl.DateTimeFormat("fr-CA", {year: "numeric", month: "2-digit", day: "2-digit"}).format(v))).toISOString().slice(0,10));
      if (saveState == false) {
        const values = [];
        for (var i = 0; i < thesedays.length; i++) {
          values.push({ Date: thesedays[i], From: '', To: '', Distance: '', Stay: '', Notes: '', Activities: '' });
        }
        setInputFields(values);
      }
      settogles(false)
    } /*  else {
      populateItinerary()
    } */
  }
  const [num, setnum] = useState(0)
  useEffect(() => {
    setnum(num + 1)
    if (num >= 1) {
      settogles(true)
      setnum(0)
    }
  }, [item.DateFrom, item.DateTo])


    useEffect(() => {
    CombineeItinerary()
  }, [togles])

  const [url, seturl] = useState(null)
  const [togle, setTogle] = useState(false)
  const openImage = (image) => {
    seturl(image)
    setTogle(!togle)
  }
  const history = useHistory()


  const [selectGearCategory, setSelectGearCategory] = useState({ Category: "ANY" })
  const handleSelectChange = (e) => {
    setSelectGearCategory(e)
  }

  const [Foodpacking, setfoodpacking] = useState({ status: "ALL" })
  const [Foodpackings, setfoodpackings] = useState({ status: "ALL" })
  const [Foodpackingss, setfoodpackingss] = useState({ status: "ALL" })
  const foodpackingLocationtwoingTower = [
    { status: "ALL" },
    { status: "4x4" },
    { status: "Boat" }
  ]
  const foodpackingLocationtwoingTower2 = [
    { status: "ALL" },
    { status: "Base Weight" },
    { status: "Consumables" },
    { status: "Wear" },
    { status: "Travel Bag" }
  ]
  const foodpackingLocationtwoingTower3 = [
    { status: "ALL" },
    { status: "4x4" },
    { status: "Camper" }
  ]
  const foodpackingLocationtwoingTower4 = [
    { status: "ALL" },
    { status: "4x4" },
    { status: "Caravan" }
  ]
  const foodpackingLocationtwoingTower5 = [
    { status: "ALL" },
    { status: "Carry On" },
    { status: "Wear" },
    { status: "Bag 1" },
    { status: "Bag 2" },
    { status: "Bag 3" },
    { status: "Bag 4" },
    // { status: "Bag 5" },
  ]
  const slectdata = country?.label == "4x4 TOWING BOAT" ? foodpackingLocationtwoingTower : country?.label == "HIKING" || country?.label == "BIKEPACKING" ? foodpackingLocationtwoingTower2 : country?.label == "OVERLANDING WITH TRAILER" ? foodpackingLocationtwoingTower3 : country?.label == "4x4 TOWING CARAVAN" ? foodpackingLocationtwoingTower4 : country?.label == "AIR +" ? foodpackingLocationtwoingTower5 : [];
  const listItemUser = useItemsUser();
  const tr = addtraivler

  const newarrtr = [...tr];
  newarrtr?.sort(function (a, b) {
    var textA = a.Name.toUpperCase();
    var textB = b.Name.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  newarrtr.unshift({ Name: "ANY" })
  const [owne, setowne] = useState({ Name: "ANY" })
  // Gear Component
  // const categorizedData = listItem.reduce((acc, curr) => {
  //   const { id, Category, SubCategory, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation } = curr;
  //   if (!acc[Category]) {
  //     acc[Category] = {
  //       items: [],
  //     };
  //   }
  //   acc[Category].items.push({ Category, SubCategory, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, id });
  //   return acc;
  // }, {});

  const awawa = listItem?.filter(el => {
    if(el.Owner == SearchOwner.Name || SearchOwner.Name == "ANY" ){
      return true
    }else{
      return false
    }
  })
  const categorizedData = awawa.reduce((acc, curr) => {
    const { id, Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation } = curr;
    if (!acc[CategoryId]) {
      acc[CategoryId] = {
        items: [],
      };
    }
    acc[CategoryId].items.push({ Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, id });
    return acc;
  }, {});


  const getCategory = (key) => {
    const foundCategorykey = categorizedData[key].items[0].CategoryId
    const foundCategory = newata?.GearCategories?.find((element) => element.id == foundCategorykey)
    if (foundCategory) {
    return foundCategory.label
    } else {
      return '-'
    }
}

const getCategorySort = (key) => {
  const foundCategorykey = categorizedData[key].items[0].CategoryId
  const foundCategory = newata?.GearCategories?.find((element) => element.id == foundCategorykey)
  if (foundCategory) {
  return foundCategory.catid
  } else {
    return '-'
  }
}

  const newdataarr = []
 listItem?.filter(el => {
    return listUser?.map(e => {
      if(e.id == el.id &&  Foodpackings.status == e.packingLocation){
        newdataarr.push(el)
        return el
      }else if(el.Owner == SearchOwner.Name){
        newdataarr.push(el)
        return el
      }
    })
  })
  const fooaw = Foodpackings.status == "ALL" ? awawa : newdataarr;

  const categorizedDatadatwa = fooaw?.reduce((acc, curr) => {
    const { id, Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation } = curr;
    if (!acc[CategoryId]) {
      acc[CategoryId] = {
        items: [],
      };
    }
    acc[CategoryId].items.push({ Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, id });
    return acc;
  }, {});

    const getCategoryAlt2 = (key) => {
    const foundCategorykey = categorizedDatadatwa[key].items[0].CategoryId
    const foundCategory = newata?.GearCategories?.find((element) => element.id == foundCategorykey)
    if (foundCategory) {
    return foundCategory.label
    } else {
      return '-'
    }
}

  const newdataarr2 = []
  listItem?.filter(el => {
    return listUser?.map(e => {
      e?.gearHiking?.map(item => {
        if(e.id == el.id &&  Foodpackings.status == item.packingLocation){
          newdataarr2.push(el)
          return el
        }else if(el.Owner == SearchOwner.Name){
          newdataarr2.push(el)
          return el
        }else if(e.id == el.id &&  owne.Name == item.Name){
          newdataarr2.push(el)
        }
      })
    })
  })
  const fooaw2 = Foodpackings.status == "ALL" && owne.Name == "ANY"  ? awawa : newdataarr2;

  const categorizedDatadatwa2 = fooaw2?.reduce((acc, curr) => {
    const { id, Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation } = curr;
    if (!acc[CategoryId]) {
      acc[CategoryId] = {
        items: [],
      };
    }
    acc[CategoryId].items.push({ Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, id });
    return acc;
  }, {});

  const getCategoryAlt = (key) => {
    const foundCategorykey = categorizedDatadatwa2[key].items[0].CategoryId
    const foundCategory = newata?.GearCategories?.find((element) => element.id == foundCategorykey)
    if (foundCategory) {
    return foundCategory.label
    } else {
      return '-'
    }
}

  const checkboxGearFunctionRender = () => {
    if (country?.label == "HIKING" || country?.label == "BIKEPACKING") {
      return (
        <>
          {Object.keys(categorizedDatadatwa2).sort().map((key, index) => {
            return (
              <>
              <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion" style={{order:getCategorySort(key), margin: '3px 0'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                      <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? getCategoryAlt(key) : 'Unassigned'}</h3></>
                      ) : null}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                      <div className="tpgrid">
                  {
                    categorizedDatadatwa2[key].items.map((t, index) => {
                      const boat = listUser.filter(el => el.id == t.id && el.packingLocation == "Base Weight" ? true : false)
                      const da4x4 = listUser.filter(el => el.id == t.id && el.packingLocation == "Consumables" ? true : false)
                      const da4x4x = listUser.filter(el => el.id == t.id && el.packingLocation == "Wear" ? true : false)
                      const da4x4xx = listUser.filter(el => el.id == t.id && el.packingLocation == "Travel Bag" ? true : false)

                      const travelers = listUser.filter(el => el.id == t.id && el?.gearHiking?.length && el?.gearHiking[0]?.Name == owne.Name ? true : false)
                      const travelers2 = listUser.filter(el => el.id == t.id && el?.gearHiking?.length && el?.gearHiking[1]?.Name == owne.Name ? true : false)

                      const boat2 = listUser.filter(el => el.id == t.id && el.packingLocation == "Base Weight" && el.Owner == owne.Name ? true : false)
                      const da4x42 = listUser.filter(el => el.id == t.id && el.packingLocation == "Consumables" && el.Owner == owne.Name ? true : false)
                      const da4x4x2 = listUser.filter(el => el.id == t.id && el.packingLocation == "Wear" && el.Owner == owne.Name ? true : false)
                      const da4x4xx2 = listUser.filter(el => el.id == t.id && el.packingLocation == "Travel Bag" && el.Owner == owne.Name ? true : false)
                      const checkFunc = () => (
                        <>
                          {SearchOwner.Name == t.Owner || SearchOwner.Name == "ANY" ? (
                            <div className="avoid selection-first">
                              <FormControlLabel className="" key={index}
                                control={
                                  <Checkbox checked={listUser.some((p) => p.id === t.id ? true : false)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setListUser((state) => [...state, { ...t, Quantity: 1, packingLocation: "Base Weight",LoanedTo:t.LoanedTo == undefined ? "" : t.LoanedTo }]);
                                      }
                                      else {
                                        let list = listUser.filter((o) => o.id !== t.id ? true : false);
                                        setListUser(list);
                                      }
                                    }}
                                  />
                                }
                                label={
                                  <>
                                    <span style={{ fontWeight: 'bold' }}>
                                    <b className="subcat-label">{t.SubCategory} <span className="jright">{t.Owner}</span></b><br/>
                                      <span style={{ fontWeight: 'normal' }}>
                                      {t.Brand == "- NO BRAND" || t.Brand == "" || t.Brand == "-"  ? '-' : t.Brand} {" "}
                                      </span>
                                      <span style={{ fontWeight: 'normal' }}>
                                      {t.Name}
                                      </span>
                                    </span>
                                  </>
                                }
                              />
                              <div className="selection-second">
                                {listUser.map(el => {
                                  if (el.id == t.id) {
                                    const getdear = listUser.filter(el => el.id == t.id ? true : false)
                                    const getdear2 = listUser.filter(el => el.id != t.id ? true : false)
                                    return (
                                      <>
                                        <div className="selection-third">
                                          {tr?.map((item, i) => {
                                            const checka = el?.gearHiking?.filter(a => a.Name == item.Name && a.id == t.id ? true : false)
                                            return (
                                              <FormControlLabel key={i} className="sub-select"
                                                control={
                                                  <Checkbox
                                                    key={`cb-${i}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    checked={checka?.length ? true : false}
                                                    color="primary"
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        const getd = getdear.map(els => {
                                                          const getoldda = els?.gearHiking || [];
                                                          const newitem = { ...item }
                                                          newitem.Quantity = 1;
                                                          newitem.id = els.id
                                                          newitem.Weight = els.Weight
                                                          newitem.packingLocation = "Base Weight"
                                                          getoldda.push(newitem)
                                                          return {
                                                            ...els,
                                                            Owner: item.Name,
                                                            RealOwner: el.Owner,
                                                            gearHiking: getoldda
                                                          }
                                                        })
                                                        const ar = [...getdear2, ...getd]
                                                        setListUser(ar)
                                                      } else {
                                                        const getd = getdear.map(els => {
                                                          const fil = els?.gearHiking.filter(u => u.Name != item.Name ? true : false)
                                                          return {
                                                            ...els,
                                                            gearHiking: fil
                                                          }
                                                        })
                                                        const ar = [...getdear2, ...getd]
                                                        setListUser(ar)
                                                      }
                                                    }}
                                                  />
                                                }
                                                label={
                                                  <span style={{ fontWeight: 'bold' }}>
                                                    {item.Name}
                                                  </span>}
                                              />
                                            )
                                          })}
                                        </div>
                                        {el?.gearHiking?.map(it => {
                                          const numbers = it.id == t.id && it.Quantity;
                                          return (
                                            <>
                                            <div className="selection-wrapper">
                                            <span className="selection-wrapper-traveller">{it.Name}</span>
                                                <TextField value={numbers} InputLabelProps={{ shrink: true, }}
                                                  id="standard-basic"
                                                  label="Qty"
                                                  // label={"Qty for " + it.Name}
                                                  name="quantity" onWheel={(e) => e.target.blur()}
                                                  className="second-selection-qty"
                                                  onChange={e => {
                                                    const getd = getdear.map(els => {
                                                      const getsingledata = els.gearHiking.filter(elm => elm.Name == it.Name && elm.id == els.id ? true : false)
                                                      if (getsingledata?.length) {
                                                        getsingledata[0].Quantity = e.target.value;
                                                      }
                                                      return {
                                                        ...els,
                                                        Quantity: e.target.value
                                                      }
                                                    })
                                                    const ar = [...getdear2, ...getd]
                                                    setListUser(ar)
                                                  }}
                                                  type="Number"
                                                  variant="outlined"
                                                />
                                                <RadioGroup value={it.packingLocation ? it.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                                                  const val = e.target.value
                                                  const getd = getdear.map(els => {
                                                    const getsingledata = els.gearHiking.filter(elm => elm.Name == it.Name && elm.id == it.id ? true : false)
                                                    getsingledata[0].packingLocation = val;
                                                    return {
                                                      ...els,
                                                      packingLocation: val
                                                    }
                                                  })
                                                  const ar = [...getdear2, ...getd]
                                                  setListUser(ar)
                                                }}>
                                                <FormControlLabel value="Base Weight" control={<Radio color="primary" />} label="Base" />
                                                <FormControlLabel value="Wear" control={<Radio color="primary" />} label="Wear" />

                                                  </RadioGroup></div>
                                            </>
                                          )
                                        })}
                                      </>
                                    )
                                  }
                                })}
                              </div>
                            </div>
                          ) : null}
                        </>
                      )
                      if (selectGearCategory?.Category == key && owne.Name == "ANY" && Foodpackings.status == "ALL") {
                        return (

                          checkFunc()

                        );
                      } else if (selectGearCategory?.Category == key && travelers.length && Foodpackings.status == "ALL") {
                        return (
                          checkFunc()
                        )
                      }
                      else if (selectGearCategory?.Category == "ANY" && travelers.length && Foodpackings.status == "ALL") {
                        return (
                          checkFunc()
                        )
                      }
                      else if (selectGearCategory?.Category == key && travelers2.length && Foodpackings.status == "ALL") {
                        return (
                          checkFunc()
                        )
                      }
                      else if (selectGearCategory?.Category == "ANY" && travelers2.length && Foodpackings.status == "ALL") {
                        return (
                          checkFunc()
                        )
                      }
                      else if (
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Base Weight" && boat2.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Consumables" && da4x42.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Travel Bag" && da4x4xx2.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Wear" && da4x4x2.length
                      ) {
                        return (
                          <>
                            {checkFunc()}
                          </>
                        );
                      }
                      else if (
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Base Weight" && boat.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Consumables" && da4x4.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Travel Bag" && da4x4xx.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Wear" && da4x4x.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "ALL" && travelers.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "ALL" && owne.Name == "ANY"
                      ) {
                        return (
                          <>
                            {checkFunc()}
                          </>
                        );
                      }
                    })
                  }
                  </div>
                      </AccordionDetails>
                      </Accordion>
              </>
            )
          })}

        </>
      )
    } else if (country?.label == "OVERLANDING WITH TRAILER") {
      return (
        <>
          {Object.keys(categorizedDatadatwa).sort().map((key, index) => {
            return (
              <>
              <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion" style={{order:getCategorySort(key), margin: '3px 0'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                      <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? getCategoryAlt(key) : 'Unassigned'}</h3></>
                      ) : null}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                      <div className="tpgrid">
                  {
                    categorizedDatadatwa[key].items.map((t, index) => {
                      const boat = listUser.filter(el => el.id == t.id && el.packingLocation == "Camper" ? true : false)
                      const da4x4 = listUser.filter(el => el.id == t.id && el.packingLocation == "4x4" ? true : false)
                      const checkFunc = () => (
                        <>
                          {SearchOwner.Name == t.Owner || SearchOwner.Name == "ANY" ? (
                            <div className="avoid selection-first">
                              <FormControlLabel key={index}
                                control={
                                  <Checkbox checked={listUser.some((p) => p.id === t.id ? true : false)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setListUser((state) => [...state, { ...t, Quantity: 1, packingLocation: "Camper",LoanedTo:t.LoanedTo == undefined ? "" : t.LoanedTo }]);
                                      }
                                      else {
                                        let list = listUser.filter((o) => o.id !== t.id ? true : false);
                                        setListUser(list);
                                      }
                                    }}
                                  />
                                }
                                label={
                                  <>
                                    <span style={{ fontWeight: 'bold' }}>
                                    <b className="subcat-label">{t.SubCategory} <span className="jright">{t.Owner}</span></b><br/>
                                      <span style={{ fontWeight: 'normal' }}>
                                      {t.Brand == "- NO BRAND" || t.Brand == "" || t.Brand == "-"  ? '-' : t.Brand} {" "}
                                      </span>
                                      <span style={{ fontWeight: 'normal' }}>
                                      {t.Name}
                                      </span>
                                    </span>
                                  </>
                                }
                              />
                              <div className="selection-second" style={{borderLeft: '0'}}>
                                {listUser?.map(el => {
                                  if (el.id == t.id) {
                                    const number = el.Quantity
                                    const getdear = listUser.filter(el => el.id == t.id ? true : false)
                                    const getdear2 = listUser.filter(el => el.id != t.id ? true : false)
                                    return (
                                      <>
                                        <div className="selection-wrapper">
                                          <TextField value={number} InputLabelProps={{ shrink: true, }}
                                            id="standard-basic"
                                            label="Qty"
                                            name="quantity" onWheel={(e) => e.target.blur()}
                                            className="second-selection-qty"
                                            InputProps={{
                                              inputProps: { min: 1, inputMode: 'numeric' }
                                            }}
                                            onKeyPress={(event) => {
                                              if (event?.key === '-' || event?.key === '+') {
                                                event.preventDefault();
                                              }
                                            }}
                                            style={{ marginLeft: '10px' }}
                                            onChange={e => {
                                              const getd = getdear.map(els => {
                                                return {
                                                  ...els,
                                                  Quantity: e.target.value
                                                }
                                              })
                                              const ar = [...getdear2, ...getd]
                                              setListUser(ar)
                                            }}
                                            type="number"
                                            variant="outlined"
                                          />
                                          <RadioGroup value={el.packingLocation ? el.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                const val = e.target.value
                const getd = getdear.map(els => {
                  return {
                    ...els,
                    packingLocation: val
                  }
                })
                const ar = [...getdear2, ...getd]
                setListUser(ar)
              }}>
                <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                <FormControlLabel value="Camper" control={<Radio color="primary" />} label="Camper" />
              </RadioGroup>
                                        </div>
                                      </>
                                    )
                                  }
                                })}
                              </div>
                            </div>
                          ) : null}
                        </>
                      )
                      if (selectGearCategory?.Category == key) {
                        return (
                          checkFunc()
                        );
                      }
                      else if (
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Camper" && boat.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "4x4" && da4x4.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "ALL"
                      ) {
                        return (
                          checkFunc()
                        );
                      }
                    })
                  }
                </div>
                      </AccordionDetails>
                      </Accordion>            
              </>
            )
          })}
        </>
      )
    }
    else if (country?.label == "4x4 TOWING CARAVAN") {
      return (
        <>
          {Object.keys(categorizedDatadatwa).sort().map((key, index) => {
            return (
              <>
              <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion" style={{order:getCategorySort(key), margin: '3px 0'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                      <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? getCategoryAlt2(key) : 'Unassigned'}</h3></>
                      ) : null}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                      <div className="tpgrid">
                  {
                    categorizedDatadatwa[key].items.map((t, index) => {
                      const category = listUser.filter(el => el.id == t.id ? true : false)
                      const boat = listUser.filter(el => el.id == t.id && el.packingLocation == "Caravan" ? true : false)
                      const da4x4 = listUser.filter(el => el.id == t.id && el.packingLocation == "4x4" ? true : false)
                      const checkFunc = () => (
                        <>
                          {SearchOwner.Name == t.Owner || SearchOwner.Name == "ANY" ? (
                            <div className="avoid selection-first">
                              <FormControlLabel key={index}
                                control={
                                  <Checkbox checked={listUser.some((p) => p.id === t.id ? true : false)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setListUser((state) => [...state, { ...t, Quantity: 1, packingLocation: "Caravan",LoanedTo:t.LoanedTo == undefined ? "" : t.LoanedTo }]);
                                      }
                                      else {
                                        let list = listUser.filter((o) => o.id !== t.id ? true : false);
                                        setListUser(list);
                                      }
                                    }}
                                  />
                                }
                                label={
                                  <>
                                    <span style={{ fontWeight: 'bold' }}>
                                    <b className="subcat-label">{t.SubCategory} <span className="jright">{t.Owner}</span></b><br/>
                                      <span style={{ fontWeight: 'normal' }}>
                                      {t.Brand == "- NO BRAND" || t.Brand == "" || t.Brand == "-"  ? '-' : t.Brand} {" "}
                                      </span>
                                      <span style={{ fontWeight: 'normal' }}>
                                      {t.Name}
                                      </span>
                                    </span>
                                  </>
                                }
                              />
                              <div className="selection-second" style={{borderLeft: '0'}}>
                                {listUser?.map(el => {
                                  if (el.id == t.id) {
                                    const number = el.Quantity
                                    const getdear = listUser.filter(el => el.id == t.id ? true : false)
                                    const getdear2 = listUser.filter(el => el.id != t.id ? true : false)
                                    return (
                                      <>
                                        <div className="selection-wrapper">
                                          <TextField value={number} InputLabelProps={{ shrink: true, }}
                                            id="standard-basic"
                                            label="Qty"
                                            name="quantity" onWheel={(e) => e.target.blur()}
                                            className="second-selection-qty"
                                            InputProps={{
                                              inputProps: { min: 1, inputMode: 'numeric' }
                                            }}
                                            onKeyPress={(event) => {
                                              if (event?.key === '-' || event?.key === '+') {
                                                event.preventDefault();
                                              }
                                            }}
                                            style={{ marginLeft: '10px' }}
                                            onChange={e => {
                                              const getd = getdear.map(els => {
                                                return {
                                                  ...els,
                                                  Quantity: e.target.value
                                                }
                                              })
                                              const ar = [...getdear2, ...getd]
                                              setListUser(ar)
                                            }}
                                            type="number"
                                            variant="outlined"
                                          />
                                          <RadioGroup value={el.packingLocation ? el.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                  const val = e.target.value
                  const getd = getdear.map(els => {
                    return {
                      ...els,
                      packingLocation: val
                    }
                  })
                  const ar = [...getdear2, ...getd]
                  setListUser(ar)
                }}>  
                  <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                  <FormControlLabel value="Caravan" control={<Radio color="primary" />} label="Caravan" />
              </RadioGroup>
                                        </div>
                                      </>
                                    )
                                  }
                                })}
                              </div>
                            </div>
                          ) : null}
                        </>
                      )
                      if (selectGearCategory?.Category == key) {
                        return (
                          checkFunc()
                        );
                      }
                      else if (
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Caravan" && boat.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "4x4" && da4x4.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "ALL"
                      ) {
                        return (
                          checkFunc()
                        );
                      }
                    })
                  }
                </div>
                      </AccordionDetails>
                      </Accordion>
              </>
            )
          })}

        </>
      )
    } else if (country?.label == "4x4 TOWING BOAT") {
      return (
        <>
          {Object.keys(categorizedDatadatwa).sort().map((key, index) => {
            return (
              <>
              <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion" style={{order:getCategorySort(key), margin: '3px 0'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                      <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? getCategoryAlt2(key) : 'Unassigned'}</h3></>
                      ) : null}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                      <div className="tpgrid">
                  {
                    categorizedDatadatwa[key].items.map((t, index) => {
                      const boat = listUser.filter(el => el.id == t.id && el.packingLocation == "Boat" ? true : false)
                      const da4x4 = listUser.filter(el => el.id == t.id && el.packingLocation == "4x4" ? true : false)
                      const checkFunc = () => (
                        <>
                          {SearchOwner.Name == t.Owner || SearchOwner.Name == "ANY" ? (
                            <div className="avoid selection-first">
                              <FormControlLabel key={index}
                                control={
                                  <Checkbox checked={listUser.some((p) => p.id === t.id ? true : false)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setListUser((state) => [...state, { ...t, Quantity: 1, packingLocation: "Boat",LoanedTo:t.LoanedTo == undefined ? "" : t.LoanedTo }]);
                                      }
                                      else {
                                        let list = listUser.filter((o) => o.id !== t.id ? true : false);
                                        setListUser(list);
                                      }
                                    }}
                                  />
                                }
                                label={
                                  <>
                                    <span style={{ fontWeight: 'bold' }}>
                                    <b className="subcat-label">{t.SubCategory} <span className="jright">{t.Owner}</span></b><br/>
                                      <span style={{ fontWeight: 'normal' }}>
                                      {t.Brand == "- NO BRAND" || t.Brand == "" || t.Brand == "-"  ? '-' : t.Brand} {" "}
                                      </span>
                                      <span style={{ fontWeight: 'normal' }}>
                                      {t.Name}
                                      </span>
                                    </span>
                                  </>
                                }
                              />
                              <div className="selection-second" style={{borderLeft: '0'}}>
                                {listUser?.map(el => {
                                  if (el.id == t.id) {
                                    const number = el.Quantity
                                    const getdear = listUser.filter(el => el.id == t.id ? true : false)
                                    const getdear2 = listUser.filter(el => el.id != t.id ? true : false)
                                    return (
                                      <>
                                        <div className="selection-wrapper">
                                          <TextField value={number} InputLabelProps={{ shrink: true, }}
                                            id="standard-basic"
                                            label="Qty"
                                            name="quantity" onWheel={(e) => e.target.blur()}
                                            className="second-selection-qty"
                                            InputProps={{
                                              inputProps: { min: 1, inputMode: 'numeric' }
                                            }}
                                            onKeyPress={(event) => {
                                              if (event?.key === '-' || event?.key === '+') {
                                                event.preventDefault();
                                              }
                                            }}
                                            style={{ marginLeft: '10px' }}
                                            onChange={e => {
                                              const getd = getdear.map(els => {
                                                return {
                                                  ...els,
                                                  Quantity: e.target.value
                                                }
                                              })
                                              const ar = [...getdear2, ...getd]
                                              setListUser(ar)
                                            }}
                                            type="number"
                                            variant="outlined"
                                          />
                                          <RadioGroup value={el.packingLocation ? el.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                  const val = e.target.value
                  const getd = getdear.map(els => {
                    return {
                      ...els,
                      packingLocation: val
                    }
                  })
                  const ar = [...getdear2, ...getd]
                  setListUser(ar)
                }}>  
                  <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                  <FormControlLabel value="Boat" control={<Radio color="primary" />} label="Boat" />
              </RadioGroup>
                                        </div>
                                      </>
                                    )
                                  }
                                })}
                              </div>
                            </div>
                          ) : null}
                        </>
                      )
                      if (selectGearCategory?.Category == key) {
                        return (
                          checkFunc()
                        );
                      }
                      else if (
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Boat" && boat.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "4x4" && da4x4.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "ALL"
                      ) {
                        return (
                          checkFunc()
                        );
                      }
                    })
                  }
                </div>
                      </AccordionDetails>
                      </Accordion>
              </>
            )
          })}

        </>
      )
    } else if (country?.label == "AIR +") {
      return (
        <>
          {Object.keys(categorizedDatadatwa2).sort().map((key, index) => {
            return (
              <>
              <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion" style={{order:getCategorySort(key), margin: '3px 0'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                      <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? getCategoryAlt(key) : 'Unassigned'}</h3></>
                      ) : null}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                      <div className="tpgrid">
                  {
                    categorizedDatadatwa2[key].items.map((t, index) => {
                      const boat = listUser.filter(el => el.id == t.id && el.packingLocation == "Carry On" ? true : false)
                      const da4x4 = listUser.filter(el => el.id == t.id && el.packingLocation == "Wear" ? true : false)
                      const ddasa4x4x = listUser.filter(el => el.id == t.id && el.packingLocation == "Bag 1" ? true : false)
                      const da4xdas4xx = listUser.filter(el => el.id == t.id && el.packingLocation == "Bag 2" ? true : false)
                      const da4awdx4xx = listUser.filter(el => el.id == t.id && el.packingLocation == "Bag 3" ? true : false)
                      const da4adsax4xx = listUser.filter(el => el.id == t.id && el.packingLocation == "Bag 4" ? true : false)
                      const da4dasdx4xx = listUser.filter(el => el.id == t.id && el.packingLocation == "Bag 5" ? true : false)

                      // const travelers = listUser.filter(el => el.id == t.id && el.Owner == owne.Name ? true : false)

                      const travelers = listUser.filter(el => el.id == t.id && el?.gearHiking?.length && el?.gearHiking[0]?.Name == owne.Name ? true : false)
                      const travelers2 = listUser.filter(el => el.id == t.id && el?.gearHiking?.length && el?.gearHiking[1]?.Name == owne.Name ? true : false)
                      const checkFunc = () => (
                        <>
                          {SearchOwner.Name == t.Owner || SearchOwner.Name == "ANY" ? (
                            <div className="avoid selection-first">
                              <FormControlLabel key={index}
                                control={
                                  <Checkbox checked={listUser.some((p) => p.id === t.id ? true : false)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setListUser((state) => [...state, { ...t, Quantity: 2, LoanedTo:t.LoanedTo == undefined ? "" : t.LoanedTo }]);
                                      }
                                      else {
                                        let list = listUser.filter((o) => o.id !== t.id ? true : false);
                                        setListUser(list);
                                      }
                                    }}
                                  />
                                }
                                label={
                                  <>
                                    <span style={{ fontWeight: 'bold' }}>
                                    <b className="subcat-label">{t.SubCategory} <span className="jright">{t.Owner}</span></b><br/>
                                      <span style={{ fontWeight: 'normal' }}>
                                      {t.Brand == "- NO BRAND" || t.Brand == "" || t.Brand == "-"  ? '-' : t.Brand} {" "}
                                      </span>
                                      <span style={{ fontWeight: 'normal' }}>
                                      {t.Name}
                                      </span>
                                    </span>
                                  </>
                                }
                              />
                              <div className="selection-second">
                                {listUser.map(el => {
                                  if (el.id == t.id) {
                                    const getdear = listUser.filter(el => el.id == t.id ? true : false)
                                    const getdear2 = listUser.filter(el => el.id != t.id ? true : false)
                                    return (
                                      <>
                                        <div className="selection-third">
                                          {tr?.map((item, i) => {
                                            const checka = el?.gearHiking?.filter(a => a.Name == item.Name && a.id == t.id ? true : false)
                                            return (
                                              <FormControlLabel key={i} className="sub-select"
                                                control={
                                                  <Checkbox
                                                    key={`cb-${i}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    checked={checka?.length ? true : false}
                                                    color="primary"
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        const getd = getdear.map(els => {
                                                          const getoldda = els?.gearHiking || [];
                                                          const newitem = { ...item }
                                                          newitem.Quantity = 1;
                                                          newitem.packingLocation = 'BAG1 - ' + tr[0]?.Name;
                                                          newitem.id = els.id
                                                          newitem.Weight = els.Weight
                                                          getoldda.push(newitem)
                                                          return {
                                                            ...els,
                                                            Owner: item.Name,
                                                            gearHiking: getoldda
                                                          }
                                                        })
                                                        const ar = [...getdear2, ...getd]
                                                        setListUser(ar)
                                                      } else {
                                                        const getd = getdear.map(els => {
                                                          const fil = els?.gearHiking.filter(u => u.Name != item.Name ? true : false)
                                                          return {
                                                            ...els,
                                                            gearHiking: fil
                                                          }
                                                        })
                                                        const ar = [...getdear2, ...getd]
                                                        setListUser(ar)
                                                      }
                                                    }}
                                                  />
                                                }
                                                label={
                                                  <span style={{ fontWeight: 'bold' }}>
                                                    {item.Name}
                                                  </span>}
                                              />
                                            )
                                          })}
                                        </div>
                                        {el?.gearHiking?.map(it => {
                                          const numbers = it.id == t.id && it.Quantity;
                                          return (
                                            <><div className="selection-wrapper">
                                              <span className="selection-wrapper-traveller">{it.Name}</span>
                                                <TextField value={numbers} InputLabelProps={{ shrink: true, }}
                                                  id="standard-basic"
                                                  label="Qty"
                                                  name="quantity" onWheel={(e) => e.target.blur()}
                                                  className="second-selection-qty"
                                                  InputProps={{
                                                    inputProps: { min: 1, inputMode: 'numeric' }
                                                  }}
                                                  onKeyPress={(event) => {
                                                    if (event?.key === '-' || event?.key === '+') {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                  style={{ marginLeft: '10px' }}
                                                  onChange={e => {
                                                    const getd = getdear.map(els => {
                                                      const getsingledata = els.gearHiking.filter(elm => elm.Name == it.Name && elm.id == els.id ? true : false)
                                                      if (getsingledata?.length) {
                                                        getsingledata[0].Quantity = e.target.value;
                                                      }
                                                      return {
                                                        ...els,
                                                        Quantity: e.target.value
                                                      }
                                                    })
                                                    const ar = [...getdear2, ...getd]
                                                    setListUser(ar)
                                                  }}
                                                  type="number"
                                                  variant="outlined"
                                                />
                                                <RadioGroup value={it.packingLocation ? it.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                                                  const val = e.target.value
                                                  const getd = getdear.map(els => {
                                                    const getsingledata = els.gearHiking.filter(elm => elm.Name == it.Name && elm.id == it.id ? true : false)
                                                    getsingledata[0].packingLocation = val;
                                                    return {
                                                      ...els,
                                                      packingLocation: val
                                                    }
                                                  })
                                                  const ar = [...getdear2, ...getd]
                                                  setListUser(ar)
                                                }}>
                                                  <FormControlLabel value={"CARRY - " + it.Name}
                                                control={
                                                  <Radio
                                                    name={(it.Name) + ' ' + (it.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={"Carry On"}
                                                  
                                              />
                                              <FormControlLabel value={"BAG1 - " + it.Name}
                                                control={
                                                  <Radio
                                                    name={(it.Name) + ' ' + (it.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={"Bag 1"}
                                                  
                                              />
                                              <FormControlLabel value={"BAG2 - " + it.Name}
                                                control={
                                                  <Radio
                                                    name={(it.Name) + ' ' + (it.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={"Bag 2"}
                                                  
                                              />
                                              <FormControlLabel value={"BAG3 - " + it.Name}
                                                control={
                                                  <Radio
                                                    name={(it.Name) + ' ' + (it.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={"Bag 3"}
                                                  
                                              />
                                              <FormControlLabel value={"WEAR - " + it.Name}
                                                control={
                                                  <Radio
                                                    name={(it.Name) + ' ' + (it.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={"Wear"}
                                                  
                                              />
                                              </RadioGroup>
                                            </div>
                                              </>
                                          )
                                        })}
                                      </>
                                    )
                                  }
                                })}
                              </div>
                            </div>
                          ) : null}
                        </>
                      )

                      if (selectGearCategory?.Category == key && Foodpackings.status == "ALL" && owne.Name == "ANY") {
                        return (
                          checkFunc()
                        );
                      }
                      else if (selectGearCategory?.Category == key && travelers.length && Foodpackings.status == "ALL") {
                        return (
                          checkFunc()
                        )
                      }
                      else if (selectGearCategory?.Category == "ANY" && travelers.length && Foodpackings.status == "ALL") {
                        return (
                          checkFunc()
                        )
                      }

                      else if (selectGearCategory?.Category == key && travelers2.length && Foodpackings.status == "ALL") {
                        return (
                          checkFunc()
                        )
                      }
                      else if (selectGearCategory?.Category == "ANY" && travelers2.length && Foodpackings.status == "ALL") {
                        return (
                          checkFunc()
                        )
                      }
                      else if (
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Carry On" && boat.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Wear" && da4x4.length ||
                        selectFoodCategory.Category == "ANY" && Foodpackings.status == "Bag 1" && ddasa4x4x.length ||
                        selectFoodCategory.Category == "ANY" && Foodpackings.status == "Bag 2" && da4xdas4xx.length ||
                        selectFoodCategory.Category == "ANY" && Foodpackings.status == "Bag 3" && da4awdx4xx.length ||
                        selectFoodCategory.Category == "ANY" && Foodpackings.status == "Bag 4" && da4adsax4xx.length ||
                        selectFoodCategory.Category == "ANY" && Foodpackings.status == "Bag 5" && da4dasdx4xx.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "ALL" && owne.Name == "ANY"
                      ) {
                        return (
                          checkFunc()
                        );
                      } else {
                        return null
                      }
                    })
                  }
                </div>
                      </AccordionDetails>
                      </Accordion>           
              </>
            )
          })}
        </>
      )
    } else {
      return (
        <>
          {Object.keys(categorizedData).sort().map((key, index) => {
             return (
                <>
                <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion" style={{order:getCategorySort(key), margin: '3px 0'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                      <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? getCategory(key) : 'Unassigned'}</h3></>
                      ) : null}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                      <div className="tpgrid">
                    {categorizedData[key].items.map((t, index) => {
                      if (SearchOwner.Name == t.Owner || SearchOwner.Name == "ANY") {
                        return (
                          <>
                            <div className="avoid selection-first">
                              <FormControlLabel key={index}
                                control={
                                  <Checkbox checked={listUser.some((p) => p.id === t.id ? true : false)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        if (country?.label == "MOTORBIKE") {
                                        setListUser((state) => [...state, { ...t, Quantity: 1, packingLocation: "Motorbike",LoanedTo:t.LoanedTo == undefined ? "" : t.LoanedTo }])
                                        } else {
                                          setListUser((state) => [...state, { ...t, Quantity: 1, packingLocation: "4x4",LoanedTo:t.LoanedTo == undefined ? "" : t.LoanedTo }])
                                        }

                                      }
                                      else {
                                        let list = listUser.filter((o) => o.id !== t.id ? true : false);
                                        setListUser(list);
                                      }
                                    }}
                                  />
                                }
                                label={<span style={{ fontWeight: 'bold' }}>
                                <b className="subcat-label">{t.SubCategory} <span className="jright">{t.Owner}</span></b><br/>
                                  <span style={{ fontWeight: 'normal' }}>
                                  {t.Brand == "- NO BRAND" || t.Brand == "" || t.Brand == "-"  ? '-' : t.Brand} {" "}
                                  </span>
                                  <span style={{ fontWeight: 'normal' }}>
                                  {t.Name}
                                  </span>
                                </span>}
                              />
                              <div className="selection-second" style={{borderLeft: '0'}}>
                                {listUser?.map(el => {
                                  if (el.id == t.id) {
                                    const number = el.Quantity
                                    const getdear = listUser.filter(el => el.id == t.id ? true : false)
                                    const getdear2 = listUser.filter(el => el.id != t.id ? true : false)
                                    return (
                                      <>
                                        <div className="selection-wrapper">
                                          <TextField value={number} InputLabelProps={{ shrink: true, }}
                                            id="standard-basic"
                                            label="Qty"
                                            name="quantity" onWheel={(e) => e.target.blur()}
                                            className="second-selection-qty"
                                            InputProps={{
                                              inputProps: { min: 1, inputMode: 'numeric' }
                                            }}
                                            onKeyPress={(event) => {
                                              if (event?.key === '-' || event?.key === '+') {
                                                event.preventDefault();
                                              }
                                            }}
                                            style={{ marginLeft: '10px' }}
                                            onChange={e => {
                                              const getd = getdear.map(els => {
                                                return {
                                                  ...els,
                                                  Quantity: e.target.value
                                                }
                                              })
                                              const ar = [...getdear2, ...getd]
                                              setListUser(ar)
                                            }}
                                            type="number"
                                            variant="outlined"
                                          />
                                          <RadioGroup value={el.packingLocation ? el.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                                            const val = e.target.value
                                            const getd = getdear.map(els => {
                                              return {
                                                ...els,
                                                packingLocation: val
                                              }
                                            })
                                            const ar = [...getdear2, ...getd]
                                            setListUser(ar)
                                          }}>
                                          {country?.label == "OVERLANDING" || country?.label == "PADDLING" ? (
                                            <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                          ) : null }
                                          {country?.label == "MOTORBIKE" ? (
                                            <FormControlLabel value="Motorbike" control={<Radio color="primary" />} label="Motorbike" />
                                          ) : null }
                                          </RadioGroup>
                                        </div>
                                      </>
                                    )
                                  }
                                })}
                              </div>
                            </div>
                          </>
                        )
                      }
                    })}
                  </div>
                      </AccordionDetails>
                      </Accordion>
                </>
              )
            
          })}
        </>
      )
    }
  }
           
  const [selectFoodCategory, setSelectFoodCategory] = useState({ Category: "ANY" })
  const handleFoodChange = (e) => {
    setSelectFoodCategory(e)
  }


  const [getFoodID, setFoodID] = useState([])


  useEffect(() => {
    const datasid = []
    listUserF?.map(el => {
      if (!getFoodID.length) {
        datasid.push(el.id)
        setFoodID(datasid)
      }
      return null
    })
  }, [listUserF])

  listItemF.filter(function (array_el) {
    return getFoodID.filter(function (anotherOne_el) {
      return anotherOne_el == array_el.id;
    })
  });
  const [foodweights, setFoodWeight] = useState([])
  useMemo(() => {
    if (!foodweights?.length) {
      const dataaw = listUserF?.map(el => {
        return {
          id: el.id,
          Weight: el.Weight,
          ...el
        }
      })
      setFoodWeight(dataaw)
    }
  }, [listUserF]);
  const quantityfunctionFoods = (number, id, item) => {
    const filla = listUserF?.filter(v => v.id !== id)
    const nfilter = listUserF?.filter(v => v.id == id)
    let awa;
    nfilter.map(el => {
      awa = el
    })
    setListUserF([...filla, { id, ...awa, Quantity: number }])

    const awaaaa = foodweights.map(el => {
      if (el.id == id) {
        return {
          ...el,
          Quantity: number
        }
      } else {
        return {
          ...el
        }
      }
    })
    setFoodWeight(awaaaa)
  }

  useEffect(() => {
    const awaaaa = foodweights.map((el, i) => {
      if (el.id == listUserF[i].id) {
        return {
          ...el,
          Quantity: listUserF[i].Quantity
        }
      } else {
        return {
          ...el
        }
      }
    })
    setFoodWeight(awaaaa)
  }, [])

  const [FoodStatusCheck, setFoodStatusCheck] = useState({ status: "ALL" })

  const stat = [
    { status: "ALL" },
    { status: "SHOPPING LIST" },
    { status: "BOUGHT" },
    { status: "PACKED" }
  ];

  const awawafoods = listItemF.filter(el => {
    if(el.Owner == SearchOwner.Name || SearchOwner.Name == "ANY" ){
      return true
    }else{
      return false
    }
  })

  const categorizedDataFoods = awawafoods.reduce((acc, curr) => {
    const { id, Category, Name, Weight } = curr;
    if (!acc[Category]) {
      acc[Category] = {
        items: [],
      };
    }
    acc[Category].items.push({ Category, id, Name, Weight });
    return acc;
  }, {});

  const food_sorted = ['BREAKFAST', 'LUNCH', 'DINNER', 'SNACKS', 'PANTRY', 'COOL DRINKS', 'HOT DRINKS', 'ALCOHOL'];

  const meal_sorted = ['BREAKFAST', 'LUNCH', 'DINNER'];

  const checkboxFoodFunctionRender = () => {
    if (country?.label == "4x4 TOWING BOAT") {
      return (
        <>
          {food_sorted.map((key, index) => {
        return ( <>
        <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                      <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? key : 'Unassigned'}</h3></>
                      ) : null}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                        <div className="tpgrid">
          {categorizedDataFoods[key]?.items.map((t, index) => {
            const aawa = listUserF.filter(el => el.id == t.id ? true : false)
            const getselecteddata = listUserF.filter(el => el.id == t.id ? true : false)
            const getalldata = listUserF.filter(el => el.id != t.id ? true : false)
            const category = listUserF.filter(el => el.id == t.id && el.bought == true && el.packed == false ? true : false)
            const packed = listUserF.filter(el => el.id == t.id && el.packed == true && el.bought == false ? true : false)
            const UNDER = getFoodID.filter(el => el == t.id ? true : false)
            const both = listUserF.filter(el => el.id == t.id && el.packed == true && el.bought == true ? true : false)
            const gaag = listUserF.filter(elaa => elaa.id == t.id ? true : false)
            const boat = listUserF.filter(el => el.id == t.id && el.packingLocation == "Boat" ? true : false)
            const da4x4 = listUserF.filter(el => el.id == t.id && el.packingLocation == "4x4" ? true : false)
            const da4x42 = listUserF.filter(el => el.id == t.id && el.packed == true && el.packingLocation == "4x4" ? true : false)
            const da4x42x = listUserF.filter(el => el.id == t.id && el.bought == true && el.packingLocation == "4x4" ? true : false)
            const da4x42xx = listUserF.filter(el => el.id == t.id && el.packed == true && el.packingLocation == "Boat" ? true : false)
            const da4x42xxx = listUserF.filter(el => el.id == t.id && el.bought == true && el.packingLocation == "Boat" ? true : false)

            const checkFunc = () => {
              return (
                <div className="avoid selection-first">
                <FormControlLabel key={index}
                  control={
                    <Checkbox checked={getFoodID.some((p) => p === t.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          const filter = listUserF.filter(el => el.id != t.id ? true : false)
                          setListUserF((state) => [...filter, { id: t.id, ...t, bought: false, packed: false, Quantity: '1' }]);
                          const getids = [...getFoodID]
                          getids.push(t.id)
                          setFoodID(getids);
                          const getwight = [...foodweights]
                          getwight.push({ id: t.id, Weight: t.Weight, Quantity: '1' })
                          setFoodWeight(getwight)
                        }
                        else {
                          let removeweight = foodweights.filter((o) => o.id !== t.id);
                          setFoodWeight(removeweight);
                          let list = listUserF.filter((o) => o.id !== t.id);
                          setListUserF(list);
                          let lists = getFoodID.filter((o) => o != t.id);
                          setFoodID(lists)
                        }
                      }}
                    />
                  }
                  label={
                    <span className="mobspanedit" style={{ fontWeight: 'bold' }}>
                      <span style={{ fontWeight: 'normal' }}>
                        {t.Name}
                      </span></span> }
                      />
                      <div className="selection-second" style={{borderLeft: '0'}}>
                      {getFoodID.map(el => {
                        if (el == t.id) {
                          let number = "1";
                          aawa.map(el => {
                            number = el.Quantity
                          });
                          const gaag = listUserF.filter(elaa => elaa.id == el ? true : false)
                          return (
                            <>
                              <div className="selection-wrapper">
                                <TextField value={number}
                                defaultValue="1"
                                className="second-selection-qty"
                                  InputLabelProps={{ shrink: true, }}
                                  id="standard-basic"
                                  label="Qty" name="quantity" onWheel={(e) => e.target.blur()}
                                  InputProps={{
                                    inputProps: { min: 1, inputMode: 'numeric' }
                                  }}
                                  onChange={e => {
                                    quantityfunctionFoods(e.currentTarget.value, t.id, t)
                                  }} type="number" variant="outlined" />
                              
                              {getselecteddata.map(els => {
                                        return (
                                        <>
                                      <RadioGroup value={els.packingLocation ? els.packingLocation : "To Pack"} defaultValue="To Pack" row
                                      onChange={e => {
                                      const val = e.target.value
                                      const getd = getselecteddata.map(els => {
                                        return {
                                          ...els,
                                          packingLocation: val,
                                        }
                                      })
                                      const ar = [...getalldata, ...getd]
                                      setListUserF(ar)
                                      }}>
                                      <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                      <FormControlLabel value="Boat" control={<Radio color="primary" />} label="Boat" />
                                      </RadioGroup>
                                      </>
                                        )
                                      })}
                                      </div>
                            </>
                          )
                        }
                      })}
                      </div>
                </div>
              )
            }

            if (
              selectFoodCategory?.Category == t.Category &&
              FoodStatusCheck.status == "ALL"
            ) {
              return (
                checkfunction()
              );
            } else if (
              category.length && FoodStatusCheck.status == "BOUGHT" &&
              Foodpacking.status == "ALL" &&
              selectFoodCategory?.Category == t.Category ||
              Foodpacking.status == "ALL" && packed.length &&
              FoodStatusCheck.status == "PACKED" && selectFoodCategory?.Category == t.Category || UNDER.length &&
              Foodpacking.status == "ALL" &&
              FoodStatusCheck.status == "SHOPPING LIST" && selectFoodCategory?.Category == checkbox.Category) {
              return (
                checkfunction()
              )
            } else if (
              category.length &&
              FoodStatusCheck.status == "BOUGHT" &&
              selectFoodCategory.Category == "ANY" &&
              Foodpacking.status == "ALL" ||
              packed.length &&
              FoodStatusCheck.status == "PACKED" &&
              Foodpacking.status == "ALL" &&
              selectFoodCategory.Category == "ANY" ||
              Foodpacking.status == "ALL" &&
              UNDER.length &&
              FoodStatusCheck.status == "SHOPPING LIST" &&
              selectFoodCategory.Category == "ANY"
            ) {
              return (
                checkfunction()
              )
            }
            else if (
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Boat" && boat.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "4x4" && da4x4.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "4x4" && da4x42.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "4x4" && da4x42x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Boat" && da4x42xx.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Boat" && da4x42xxx.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "ALL"
            ) {
              return (
                checkFunc()
                );
              }
          })}
          </div>
                      </AccordionDetails>
                      </Accordion>
          
          </> ) } ) }
            
          </>
        )
      } else if (country?.label == "AIR +") {
        return (
          <>
            {food_sorted.map((key, index) => {
          return ( <>
            <>
            <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                      <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? key : 'Unassigned'}</h3></>
                      ) : null}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                        <div className="tpgrid">
          {categorizedDataFoods[key]?.items.map((t, index) => {
            const getselecteddata = listUserF.filter(el => el.id == t.id ? true : false)
            const getalldata = listUserF.filter(el => el.id != t.id ? true : false)
            const category = listUserF.filter(el => el.id == t.id && el.bought == true && el.packed == false ? true : false)
            const packed = listUserF.filter(el => el.id == t.id && el.packed == true && el.bought == false ? true : false)
            const UNDER = getFoodID.filter(el => el == t.id ? true : false)
            const both = listUserF.filter(el => el.id == t.id && el.packed == true && el.bought == true ? true : false)

            const boat = listUserF.filter(el => el.id == t.id && el.packingLocation == "Base Weight" ? true : false)
            const da4x4 = listUserF.filter(el => el.id == t.id && el.packingLocation == "Consumables" ? true : false)
            const da4x4x = listUserF.filter(el => el.id == t.id && el.packingLocation == "Wear" ? true : false)
            const da4x4xx = listUserF.filter(el => el.id == t.id && el.packingLocation == "Travel Bag" ? true : false)
            const da4x422 = listUserF.filter(el => el.id == t.id && el.packed == true && el.packingLocation == "Base Weight" ? true : false)
            const da4x42a = listUserF.filter(el => el.id == t.id && el.packed == true && el.packingLocation == "Consumables" ? true : false)
            const da4x4223 = listUserF.filter(el => el.id == t.id && el.packed == true && el.packingLocation == "Wear" ? true : false)
            const da4x4a2 = listUserF.filter(el => el.id == t.id && el.packed == true && el.packingLocation == "Travel Bag" ? true : false)
            const dsa4x42x = listUserF.filter(el => el.id == t.id && el.bought == true && el.packingLocation == "Base Weight" ? true : false)
            const da4x4dc2x = listUserF.filter(el => el.id == t.id && el.bought == true && el.packingLocation == "Consumables" ? true : false)
            const da4x4a2x = listUserF.filter(el => el.id == t.id && el.bought == true && el.packingLocation == "Wear" ? true : false)
            const da4cx42x = listUserF.filter(el => el.id == t.id && el.bought == true && el.packingLocation == "Travel Bag" ? true : false)

            const checkFunc = () => (
              <div className="avoid selection-first">
              <FormControlLabel className="avoid sub-select-options" key={index + t.Name} style={{pointerEvents:'none'}}
                control={
                  <Checkbox checked={getFoodID.some((p) => p === t.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const filter = listUserF.filter(el => el.id != t.id ? true : false)
                        setListUserF((state) => [...filter, { id: t.id, ...t, bought: false, packed: false, packingLocation: 'PACK - ' + tr[0]?.Name, Quantity: '1' }]);
                        const getids = [...getFoodID]
                        getids.push(t.id)
                        setFoodID(getids);
                        const getwight = [...foodweights]
                        getwight.push({ id: t.id, Weight: t.Weight, Quantity: '1' })
                        setFoodWeight(getwight)
                      }
                      else {
                        let removeweight = foodweights.filter((o) => o.id !== t.id);
                        setFoodWeight(removeweight);
                        let list = listUserF.filter((o) => o.id !== t.id);
                        setListUserF(list);
                        let lists = getFoodID.filter((o) => o != t.id);
                        setFoodID(lists)
                      }
                    }}
                  />
                }
                label={
                  <span className="mobspanedit" style={{ fontWeight: 'bold' }}>
                    <span style={{ fontWeight: 'normal' }}>
                      {t.Name}
                    </span>
                    </span> } />
                    <div className="selection-second" style={{borderLeft: '0'}}>
                    {getFoodID.map(el => {
                      if (el == t.id) {
                        let number = "1";
                        getselecteddata.map(el => {
                          number = el.Quantity
                        });
                        const gaag = listUserF.filter(elaa => elaa.id == el ? true : false)
                        return (
                          <>
                            <div className="selection-wrapper">
                              <TextField value={number}
                              defaultValue="1"
                                InputLabelProps={{ shrink: true, }}
                                style={{pointerEvents: 'all'}}
                                id="standard-basic"
                                label="Qty" name="quantity" onWheel={(e) => e.target.blur()}
                                InputProps={{
                                  inputProps: { min: 1, inputMode: 'numeric' }
                                }}
                                className="second-selection-qty"
                                onChange={e => {
                                  quantityfunctionFoods(e.currentTarget.value, t.id, t)
                                }} type="number" variant="outlined" />
                                {getselecteddata.map((el) => {
  const selectedTraveler = el.packingLocationTemp || "To Pack";
  const selectedOption = el.selectedOption || "";

  return (
    <>
      {/* RadioGroup for selecting traveler */}
      <RadioGroup
        value={selectedTraveler}
        row
        onChange={(e) => {
          const val = e.target.value;
          const updatedData = getselecteddata.map((data) =>
            data.id === el.id
              ? {
                  ...data,
                  packingLocationTemp: val,
                }
              : data
          );
          const mergedData = [...getalldata, ...updatedData];
          setListUserF(mergedData); // Persist updated data to state
        }}
      >
        {tr?.map((item, i) => (
          <FormControlLabel
            key={i}
            control={
              <Radio
                name={`traveler-${el.id}`}
                color="primary"
                value={`${item.Name} ${item.Weight}`}
              />
            }
            label={item.Name}
          />
        ))}
      </RadioGroup>

      {/* Dependent options based on selected traveler */}
      {tr?.map((item, i) => {
  if (selectedTraveler === `${item.Name} ${item.Weight}`) {
    return (
      <RadioGroup
        key={`options-${i}`}
        row
        value={selectedOption}
        onChange={(e) => {
          const val = e.target.value;
          const updatedData = getselecteddata.map((data) =>
            data.id === el.id
              ? {
                  ...data,
                  selectedOption: val,
                  packingLocation: val,
                }
              : data
          );
          const mergedData = [...getalldata, ...updatedData];
          setListUserF(mergedData); // Persist updated data to state
          console.log(listUserF);
        }}
      >
        <FormControlLabel
          control={
            <Radio
              name={`option-${item.Name}-1`}
              color="secondary"
              value={`CARRY - ${item.Name}`}
            />
          }
          label={"Carry On"}
        />
        <FormControlLabel
          control={
            <Radio
              name={`option-${item.Name}-2`}
              color="secondary"
              value={`BAG1 - ${item.Name}`}
            />
          }
          label={"Bag 1"}
        />
        <FormControlLabel
          control={
            <Radio
              name={`option-${item.Name}-2`}
              color="secondary"
              value={`BAG2 - ${item.Name}`}
            />
          }
          label={"Bag 2"}
        />
        <FormControlLabel
          control={
            <Radio
              name={`option-${item.Name}-2`}
              color="secondary"
              value={`BAG3 - ${item.Name}`}
            />
          }
          label={"Bag 3"}
        />
      </RadioGroup>
    );
  }
  return null;
})}
    </>
  );
})}

                          </div></>
                        )
                      }
                    })}</div>
            </div>)
            if (selectFoodCategory?.Category == t.Category && FoodStatusCheck.status == "ALL" && Foodpacking.status == "ALL") {
              return (
                checkFunc()
              );
            } else if (Foodpacking.status == "ALL" && category.length && FoodStatusCheck.status == "BOUGHT" && selectFoodCategory?.Category == t.Category || packed.length && Foodpacking.status == "ALL" && FoodStatusCheck.status == "PACKED" && selectFoodCategory?.Category == t.Category || UNDER.length && Foodpacking.status == "ALL" && FoodStatusCheck.status == "SHOPPING LIST" && selectFoodCategory?.Category == t.Category) {
              return (
                checkFunc()
              )
            } else if (Foodpacking.status == "ALL" && category.length && FoodStatusCheck.status == "BOUGHT" && selectFoodCategory.Category == "ANY" || packed.length && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "ALL" && selectFoodCategory.Category == "ANY" || UNDER.length && Foodpacking.status == "ALL" && FoodStatusCheck.status == "SHOPPING LIST" && selectFoodCategory.Category == "ANY") {
              return (
                checkFunc()
              )
            }
            else if (
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Base Weight" && boat.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Consumables" && da4x4.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Travel Bag" && da4x4xx.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Wear" && da4x4x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Base Weight" && da4x422.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Consumables" && da4x42a.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Wear" && da4x4223.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Travel Bag" && da4x4a2.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Base Weight" && dsa4x42x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Consumables" && da4x4dc2x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Wear" && da4x4a2x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Travel Bag" && da4cx42x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "ALL"
            ) {
              return (
                checkFunc()
                );
              }
          })}
          </div>
                      </AccordionDetails>
                      </Accordion>
            </>
            </> ) } ) }
            
          </>
        )
      } else if (country?.label == "HIKING" || country?.label == "BIKEPACKING") {
        return (
          <>
            {food_sorted.map((key, index) => {
          return ( <>
            <>
            <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                      <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? key : 'Unassigned'}</h3></>
                      ) : null}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                        <div className="tpgrid">
          {categorizedDataFoods[key]?.items.map((t, index) => {
            const getselecteddata = listUserF.filter(el => el.id == t.id ? true : false)
            const getalldata = listUserF.filter(el => el.id != t.id ? true : false)
            const category = listUserF.filter(el => el.id == t.id && el.bought == true && el.packed == false ? true : false)
            const packed = listUserF.filter(el => el.id == t.id && el.packed == true && el.bought == false ? true : false)
            const UNDER = getFoodID.filter(el => el == t.id ? true : false)
            const both = listUserF.filter(el => el.id == t.id && el.packed == true && el.bought == true ? true : false)

            const boat = listUserF.filter(el => el.id == t.id && el.packingLocation == "Base Weight" ? true : false)
            const da4x4 = listUserF.filter(el => el.id == t.id && el.packingLocation == "Consumables" ? true : false)
            const da4x4x = listUserF.filter(el => el.id == t.id && el.packingLocation == "Wear" ? true : false)
            const da4x4xx = listUserF.filter(el => el.id == t.id && el.packingLocation == "Travel Bag" ? true : false)
            const da4x422 = listUserF.filter(el => el.id == t.id && el.packed == true && el.packingLocation == "Base Weight" ? true : false)
            const da4x42a = listUserF.filter(el => el.id == t.id && el.packed == true && el.packingLocation == "Consumables" ? true : false)
            const da4x4223 = listUserF.filter(el => el.id == t.id && el.packed == true && el.packingLocation == "Wear" ? true : false)
            const da4x4a2 = listUserF.filter(el => el.id == t.id && el.packed == true && el.packingLocation == "Travel Bag" ? true : false)
            const dsa4x42x = listUserF.filter(el => el.id == t.id && el.bought == true && el.packingLocation == "Base Weight" ? true : false)
            const da4x4dc2x = listUserF.filter(el => el.id == t.id && el.bought == true && el.packingLocation == "Consumables" ? true : false)
            const da4x4a2x = listUserF.filter(el => el.id == t.id && el.bought == true && el.packingLocation == "Wear" ? true : false)
            const da4cx42x = listUserF.filter(el => el.id == t.id && el.bought == true && el.packingLocation == "Travel Bag" ? true : false)

            const checkFunc = () => (
              <div className="avoid selection-first">
              <FormControlLabel className="avoid sub-select-options" key={index + t.Name} style={{pointerEvents:'none'}}
                control={
                  <Checkbox checked={getFoodID.some((p) => p === t.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const filter = listUserF.filter(el => el.id != t.id ? true : false)
                        setListUserF((state) => [...filter, { id: t.id, ...t, bought: false, packed: false, packingLocation: 'PACK - ' + tr[0]?.Name, Quantity: '1' }]);
                        const getids = [...getFoodID]
                        getids.push(t.id)
                        setFoodID(getids);
                        const getwight = [...foodweights]
                        getwight.push({ id: t.id, Weight: t.Weight, Quantity: '1' })
                        setFoodWeight(getwight)
                      }
                      else {
                        let removeweight = foodweights.filter((o) => o.id !== t.id);
                        setFoodWeight(removeweight);
                        let list = listUserF.filter((o) => o.id !== t.id);
                        setListUserF(list);
                        let lists = getFoodID.filter((o) => o != t.id);
                        setFoodID(lists)
                      }
                    }}
                  />
                }
                label={
                  <span className="mobspanedit" style={{ fontWeight: 'bold' }}>
                    <span style={{ fontWeight: 'normal' }}>
                      {t.Name}
                    </span>
                    </span> } />
                    <div className="selection-second" style={{borderLeft: '0'}}>
                    {getFoodID.map(el => {
                      if (el == t.id) {
                        let number = "1";
                        getselecteddata.map(el => {
                          number = el.Quantity
                        });
                        const gaag = listUserF.filter(elaa => elaa.id == el ? true : false)
                        return (
                          <>
                            <div className="selection-wrapper">
                              <TextField value={number}
                              defaultValue="1"
                                InputLabelProps={{ shrink: true, }}
                                style={{pointerEvents: 'all'}}
                                id="standard-basic"
                                label="Qty" name="quantity" onWheel={(e) => e.target.blur()}
                                InputProps={{
                                  inputProps: { min: 1, inputMode: 'numeric' }
                                }}
                                className="second-selection-qty"
                                onChange={e => {
                                  quantityfunctionFoods(e.currentTarget.value, t.id, t)
                                }} type="number" variant="outlined" />
                                
                                {getselecteddata.map(el => {
                                        return (
                                        <>
                                <RadioGroup value={el.packingLocation ? el.packingLocation : "To Pack"} defaultValue="To Pack" row
                                      onChange={e => {
                                      const val = e.target.value
                                      const getd = getselecteddata.map(el => {
                                        return {
                                          ...el,
                                          packingLocation: val,
                                        }
                                      })
                                      const ar = [...getalldata, ...getd]
                                      setListUserF(ar)
                                      }}>
                                        {tr?.map((item, i) => {
                                            return (
                                              <>
                                              
                                              <FormControlLabel key={i} value={"PACK - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name}
                                                  
                                              />
                                              </>
                                            )
                                          })}
                                      </RadioGroup>
                                      </>
                                        )})}
                                        </div>
                          </>
                        )
                      }
                    })}</div>
            </div>)
            if (selectFoodCategory?.Category == t.Category && FoodStatusCheck.status == "ALL" && Foodpacking.status == "ALL") {
              return (
                checkFunc()
              );
            } else if (Foodpacking.status == "ALL" && category.length && FoodStatusCheck.status == "BOUGHT" && selectFoodCategory?.Category == t.Category || packed.length && Foodpacking.status == "ALL" && FoodStatusCheck.status == "PACKED" && selectFoodCategory?.Category == t.Category || UNDER.length && Foodpacking.status == "ALL" && FoodStatusCheck.status == "SHOPPING LIST" && selectFoodCategory?.Category == t.Category) {
              return (
                checkFunc()
              )
            } else if (Foodpacking.status == "ALL" && category.length && FoodStatusCheck.status == "BOUGHT" && selectFoodCategory.Category == "ANY" || packed.length && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "ALL" && selectFoodCategory.Category == "ANY" || UNDER.length && Foodpacking.status == "ALL" && FoodStatusCheck.status == "SHOPPING LIST" && selectFoodCategory.Category == "ANY") {
              return (
                checkFunc()
              )
            }
            else if (
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Base Weight" && boat.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Consumables" && da4x4.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Travel Bag" && da4x4xx.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Wear" && da4x4x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Base Weight" && da4x422.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Consumables" && da4x42a.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Wear" && da4x4223.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Travel Bag" && da4x4a2.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Base Weight" && dsa4x42x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Consumables" && da4x4dc2x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Wear" && da4x4a2x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Travel Bag" && da4cx42x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "ALL"
            ) {
              return (
                checkFunc()
                );
              }
          })}
          </div>
                      </AccordionDetails>
                      </Accordion>
            </>
            </> ) } ) }
            
          </>
        )
      } else if (country?.label == "OVERLANDING WITH TRAILER") {
        return (
          <>
            {food_sorted.map((key, index) => {
          return ( <>
          <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel-items-content"
                          id="panel-items"
                          style={{marginTop:'0'}}
                        >
                        <Typography>
                        {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                        <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? key : 'Unassigned'}</h3></>
                        ) : null}
                        </Typography></AccordionSummary>
                        <AccordionDetails>
                          <div className="tpgrid">
            {categorizedDataFoods[key]?.items.map((t, index) => {
              const aawa = listUserF.filter(el => el.id == t.id ? true : false)
              const getselecteddata = listUserF.filter(el => el.id == t.id ? true : false)
              const getalldata = listUserF.filter(el => el.id != t.id ? true : false)
              const category = listUserF.filter(el => el.id == t.id && el.bought == true && el.packed == false ? true : false)
              const packed = listUserF.filter(el => el.id == t.id && el.packed == true && el.bought == false ? true : false)
              const UNDER = getFoodID.filter(el => el == t.id ? true : false)
              const both = listUserF.filter(el => el.id == t.id && el.packed == true && el.bought == true ? true : false)
              const gaag = listUserF.filter(elaa => elaa.id == t.id ? true : false)
              const boat = listUserF.filter(el => el.id == t.id && el.packingLocation == "Boat" ? true : false)
              const da4x4 = listUserF.filter(el => el.id == t.id && el.packingLocation == "4x4" ? true : false)
              const da4x42 = listUserF.filter(el => el.id == t.id && el.packed == true && el.packingLocation == "4x4" ? true : false)
              const da4x42x = listUserF.filter(el => el.id == t.id && el.bought == true && el.packingLocation == "4x4" ? true : false)
              const da4x42xx = listUserF.filter(el => el.id == t.id && el.packed == true && el.packingLocation == "Boat" ? true : false)
              const da4x42xxx = listUserF.filter(el => el.id == t.id && el.bought == true && el.packingLocation == "Boat" ? true : false)
  
              const checkFunc = () => {
                return (
                  <div className="avoid selection-first">
                  <FormControlLabel key={index}
                  control={
                    <Checkbox checked={getFoodID.some((p) => p === t.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                      const filter = listUserF.filter(el => el.id != t.id ? true : false)
                      setListUserF((state) => [...filter, { id: t.id, ...t, bought: false, packed: false, Quantity: '1', packingLocation: 'Camper' }]);
                      const getids = [...getFoodID]
                      getids.push(t.id)
                      setFoodID(getids);
                      const getwight = [...foodweights]
                      getwight.push({ id: t.id, Weight: t.Weight, Quantity: '1' })
                      setFoodWeight(getwight)
                      }
                      else {
                      let removeweight = foodweights.filter((o) => o.id !== t.id);
                      setFoodWeight(removeweight);
                      let list = listUserF.filter((o) => o.id !== t.id);
                      setListUserF(list);
                      let lists = getFoodID.filter((o) => o != t.id);
                      setFoodID(lists)
                      }
                    }}
                    />
                  }
                  label={
                    <span className="mobspanedit" style={{ fontWeight: 'bold' }}>
                    <span style={{ fontWeight: 'normal' }}>
                      {t.Name}
                    </span></span> }
                    />
                    <div className="selection-second" style={{borderLeft: '0'}}>
                    {getFoodID.map(el => {
                      if (el == t.id) {
                      let number = "1";
                      aawa.map(el => {
                        number = el.Quantity
                      });
                      const gaag = listUserF.filter(elaa => elaa.id == el ? true : false)
                      return (
                        <>
                        <div className="selection-wrapper">
                          <TextField value={number}
                          defaultValue="1"
                          className="second-selection-qty"
                          InputLabelProps={{ shrink: true, }}
                          id="standard-basic"
                          label="Qty" name="quantity" onWheel={(e) => e.target.blur()}
                          InputProps={{
                            inputProps: { min: 1, inputMode: 'numeric' }
                          }}
                          onChange={e => {
                            quantityfunctionFoods(e.currentTarget.value, t.id, t)
                          }} type="number" variant="outlined" />
                        
                        {getselecteddata.map(els => {
                              return (
                              <>
                            <RadioGroup value={els.packingLocation ? els.packingLocation : "Camper"} defaultValue="Camper" row
                            onChange={e => {
                            const val = e.target.value
                            const getd = getselecteddata.map(els => {
                              return {
                              ...els,
                              packingLocation: val,
                              }
                            })
                            const ar = [...getalldata, ...getd]
                            setListUserF(ar)
                            }}>
                            <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                            <FormControlLabel value="Camper" control={<Radio color="primary" />} label="Camper" />
                            </RadioGroup>
                            </>
                              )
                            })}
                            </div>
                        </>
                      )
                      }
                    })}
                    </div>
                  </div>
                )
              }
  
              if (
                selectFoodCategory?.Category == t.Category &&
                FoodStatusCheck.status == "ALL"
              ) {
                return (
                  checkfunction()
                );
              } else if (
                category.length && FoodStatusCheck.status == "BOUGHT" &&
                Foodpacking.status == "ALL" &&
                selectFoodCategory?.Category == t.Category ||
                Foodpacking.status == "ALL" && packed.length &&
                FoodStatusCheck.status == "PACKED" && selectFoodCategory?.Category == t.Category || UNDER.length &&
                Foodpacking.status == "ALL" &&
                FoodStatusCheck.status == "SHOPPING LIST" && selectFoodCategory?.Category == checkbox.Category) {
                return (
                  checkfunction()
                )
              } else if (
                category.length &&
                FoodStatusCheck.status == "BOUGHT" &&
                selectFoodCategory.Category == "ANY" &&
                Foodpacking.status == "ALL" ||
                packed.length &&
                FoodStatusCheck.status == "PACKED" &&
                Foodpacking.status == "ALL" &&
                selectFoodCategory.Category == "ANY" ||
                Foodpacking.status == "ALL" &&
                UNDER.length &&
                FoodStatusCheck.status == "SHOPPING LIST" &&
                selectFoodCategory.Category == "ANY"
              ) {
                return (
                  checkfunction()
                )
              }
              else if (
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Boat" && boat.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "4x4" && da4x4.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "4x4" && da4x42.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "4x4" && da4x42x.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Boat" && da4x42xx.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Boat" && da4x42xxx.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "ALL"
              ) {
                return (
                  checkFunc()
                  );
                }
            })}
            </div>
                        </AccordionDetails>
                        </Accordion>
            
            </> ) } ) }
              
            </>
          )
      } else if (country?.label == "4x4 TOWING CARAVAN") {
        return (
          <>
            {food_sorted.map((key, index) => {
          return ( <>
          <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel-items-content"
                          id="panel-items"
                          style={{marginTop:'0'}}
                        >
                        <Typography>
                        {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                        <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? key : 'Unassigned'}</h3></>
                        ) : null}
                        </Typography></AccordionSummary>
                        <AccordionDetails>
                          <div className="tpgrid">
            {categorizedDataFoods[key]?.items.map((t, index) => {
              const aawa = listUserF.filter(el => el.id == t.id ? true : false)
              const getselecteddata = listUserF.filter(el => el.id == t.id ? true : false)
              const getalldata = listUserF.filter(el => el.id != t.id ? true : false)
              const category = listUserF.filter(el => el.id == t.id && el.bought == true && el.packed == false ? true : false)
              const packed = listUserF.filter(el => el.id == t.id && el.packed == true && el.bought == false ? true : false)
              const UNDER = getFoodID.filter(el => el == t.id ? true : false)
              const both = listUserF.filter(el => el.id == t.id && el.packed == true && el.bought == true ? true : false)
              const gaag = listUserF.filter(elaa => elaa.id == t.id ? true : false)
              const boat = listUserF.filter(el => el.id == t.id && el.packingLocation == "Boat" ? true : false)
              const da4x4 = listUserF.filter(el => el.id == t.id && el.packingLocation == "4x4" ? true : false)
              const da4x42 = listUserF.filter(el => el.id == t.id && el.packed == true && el.packingLocation == "4x4" ? true : false)
              const da4x42x = listUserF.filter(el => el.id == t.id && el.bought == true && el.packingLocation == "4x4" ? true : false)
              const da4x42xx = listUserF.filter(el => el.id == t.id && el.packed == true && el.packingLocation == "Boat" ? true : false)
              const da4x42xxx = listUserF.filter(el => el.id == t.id && el.bought == true && el.packingLocation == "Boat" ? true : false)
  
              const checkFunc = () => {
                return (
                  <div className="avoid selection-first">
                  <FormControlLabel key={index}
                    control={
                      <Checkbox checked={getFoodID.some((p) => p === t.id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            const filter = listUserF.filter(el => el.id != t.id ? true : false)
                            setListUserF((state) => [...filter, { id: t.id, ...t, bought: false, packed: false, Quantity: '1' }]);
                            const getids = [...getFoodID]
                            getids.push(t.id)
                            setFoodID(getids);
                            const getwight = [...foodweights]
                            getwight.push({ id: t.id, Weight: t.Weight, Quantity: '1' })
                            setFoodWeight(getwight)
                          }
                          else {
                            let removeweight = foodweights.filter((o) => o.id !== t.id);
                            setFoodWeight(removeweight);
                            let list = listUserF.filter((o) => o.id !== t.id);
                            setListUserF(list);
                            let lists = getFoodID.filter((o) => o != t.id);
                            setFoodID(lists)
                          }
                        }}
                      />
                    }
                    label={
                      <span className="mobspanedit" style={{ fontWeight: 'bold' }}>
                        <span style={{ fontWeight: 'normal' }}>
                          {t.Name}
                        </span></span> }
                        />
                        <div className="selection-second" style={{borderLeft: '0'}}>
                        {getFoodID.map(el => {
                          if (el == t.id) {
                            let number = "1";
                            aawa.map(el => {
                              number = el.Quantity
                            });
                            const gaag = listUserF.filter(elaa => elaa.id == el ? true : false)
                            return (
                              <>
                                <div className="selection-wrapper">
                                  <TextField value={number}
                                  defaultValue="1"
                                  className="second-selection-qty"
                                    InputLabelProps={{ shrink: true, }}
                                    id="standard-basic"
                                    label="Qty" name="quantity" onWheel={(e) => e.target.blur()}
                                    InputProps={{
                                      inputProps: { min: 1, inputMode: 'numeric' }
                                    }}
                                    onChange={e => {
                                      quantityfunctionFoods(e.currentTarget.value, t.id, t)
                                    }} type="number" variant="outlined" />
                                
                                {getselecteddata.map(els => {
                                          return (
                                          <>
                                        <RadioGroup value={els.packingLocation ? els.packingLocation : "To Pack"} defaultValue="To Pack" row
                                        onChange={e => {
                                        const val = e.target.value
                                        const getd = getselecteddata.map(els => {
                                          return {
                                            ...els,
                                            packingLocation: val,
                                          }
                                        })
                                        const ar = [...getalldata, ...getd]
                                        setListUserF(ar)
                                        }}>
                                        <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                        <FormControlLabel value="Caravan" control={<Radio color="primary" />} label="Caravan" />
                                        </RadioGroup>
                                        </>
                                          )
                                        })}
                                        </div>
                              </>
                            )
                          }
                        })}
                        </div>
                  </div>
                )
              }
  
              if (
                selectFoodCategory?.Category == t.Category &&
                FoodStatusCheck.status == "ALL"
              ) {
                return (
                  checkfunction()
                );
              } else if (
                category.length && FoodStatusCheck.status == "BOUGHT" &&
                Foodpacking.status == "ALL" &&
                selectFoodCategory?.Category == t.Category ||
                Foodpacking.status == "ALL" && packed.length &&
                FoodStatusCheck.status == "PACKED" && selectFoodCategory?.Category == t.Category || UNDER.length &&
                Foodpacking.status == "ALL" &&
                FoodStatusCheck.status == "SHOPPING LIST" && selectFoodCategory?.Category == checkbox.Category) {
                return (
                  checkfunction()
                )
              } else if (
                category.length &&
                FoodStatusCheck.status == "BOUGHT" &&
                selectFoodCategory.Category == "ANY" &&
                Foodpacking.status == "ALL" ||
                packed.length &&
                FoodStatusCheck.status == "PACKED" &&
                Foodpacking.status == "ALL" &&
                selectFoodCategory.Category == "ANY" ||
                Foodpacking.status == "ALL" &&
                UNDER.length &&
                FoodStatusCheck.status == "SHOPPING LIST" &&
                selectFoodCategory.Category == "ANY"
              ) {
                return (
                  checkfunction()
                )
              }
              else if (
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Boat" && boat.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "4x4" && da4x4.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "4x4" && da4x42.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "4x4" && da4x42x.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Boat" && da4x42xx.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Boat" && da4x42xxx.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "ALL"
              ) {
                return (
                  checkFunc()
                  );
                }
            })}
            </div>
                        </AccordionDetails>
                        </Accordion>
            
            </> ) } ) }
              
            </>
          )
      } else {
        return (
          <>
            {food_sorted.map((key, index) => {
          return ( <>
          <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel-items-content"
                          id="panel-items"
                          style={{marginTop:'0'}}
                        >
                        <Typography>
                        {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                        <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? key : 'Unassigned'}</h3></>
                        ) : null}
                        </Typography></AccordionSummary>
                        <AccordionDetails>
                          <div className="tpgrid">
            {categorizedDataFoods[key]?.items.map((t, index) => {
              const aawa = listUserF.filter(el => el.id == t.id ? true : false)
              const getselecteddata = listUserF.filter(el => el.id == t.id ? true : false)
              const getalldata = listUserF.filter(el => el.id != t.id ? true : false)
              const category = listUserF.filter(el => el.id == t.id && el.bought == true && el.packed == false ? true : false)
              const packed = listUserF.filter(el => el.id == t.id && el.packed == true && el.bought == false ? true : false)
              const UNDER = getFoodID.filter(el => el == t.id ? true : false)
              const both = listUserF.filter(el => el.id == t.id && el.packed == true && el.bought == true ? true : false)
              const gaag = listUserF.filter(elaa => elaa.id == t.id ? true : false)
              const boat = listUserF.filter(el => el.id == t.id && el.packingLocation == "Boat" ? true : false)
              const da4x4 = listUserF.filter(el => el.id == t.id && el.packingLocation == "4x4" ? true : false)
              const da4x42 = listUserF.filter(el => el.id == t.id && el.packed == true && el.packingLocation == "4x4" ? true : false)
              const da4x42x = listUserF.filter(el => el.id == t.id && el.bought == true && el.packingLocation == "4x4" ? true : false)
              const da4x42xx = listUserF.filter(el => el.id == t.id && el.packed == true && el.packingLocation == "Boat" ? true : false)
              const da4x42xxx = listUserF.filter(el => el.id == t.id && el.bought == true && el.packingLocation == "Boat" ? true : false)
  
              const checkFunc = () => {
                return (
                  <div className="avoid selection-first">
                  <FormControlLabel key={index}
                  control={
                    <Checkbox checked={getFoodID.some((p) => p === t.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                      const filter = listUserF.filter(el => el.id != t.id ? true : false)
                      setListUserF((state) => [...filter, { id: t.id, ...t, bought: false, packed: false, Quantity: '1', packingLocation: '4x4' }]);
                      const getids = [...getFoodID]
                      getids.push(t.id)
                      setFoodID(getids);
                      const getwight = [...foodweights]
                      getwight.push({ id: t.id, Weight: t.Weight, Quantity: '1' })
                      setFoodWeight(getwight)
                      }
                      else {
                      let removeweight = foodweights.filter((o) => o.id !== t.id);
                      setFoodWeight(removeweight);
                      let list = listUserF.filter((o) => o.id !== t.id);
                      setListUserF(list);
                      let lists = getFoodID.filter((o) => o != t.id);
                      setFoodID(lists)
                      }
                    }}
                    />
                  }
                  label={
                    <span className="mobspanedit" style={{ fontWeight: 'bold' }}>
                    <span style={{ fontWeight: 'normal' }}>
                      {t.Name}
                    </span></span> }
                    />
                    <div className="selection-second" style={{borderLeft: '0'}}>
                    {getFoodID.map(el => {
                      if (el == t.id) {
                      let number = "1";
                      aawa.map(el => {
                        number = el.Quantity
                      });
                      const gaag = listUserF.filter(elaa => elaa.id == el ? true : false)
                      return (
                        <>
                        <div className="selection-wrapper">
                          <TextField value={number}
                          defaultValue="1"
                          className="second-selection-qty"
                          InputLabelProps={{ shrink: true, }}
                          id="standard-basic"
                          label="Qty" name="quantity" onWheel={(e) => e.target.blur()}
                          InputProps={{
                            inputProps: { min: 1, inputMode: 'numeric' }
                          }}
                          onChange={e => {
                            quantityfunctionFoods(e.currentTarget.value, t.id, t)
                          }} type="number" variant="outlined" />
                        
                        {getselecteddata.map(els => {
                              return (
                              <>
                            <RadioGroup value={els.packingLocation ? els.packingLocation : "4x4"} defaultValue="4x4" row
                            onChange={e => {
                            const val = e.target.value
                            const getd = getselecteddata.map(els => {
                              return {
                              ...els,
                              packingLocation: val,
                              }
                            })
                            const ar = [...getalldata, ...getd]
                            setListUserF(ar)
                            }}>
                            <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                            </RadioGroup>
                            </>
                              )
                            })}
                            </div>
                        </>
                      )
                      }
                    })}
                    </div>
                  </div>
                )
              }
  
              if (
                selectFoodCategory?.Category == t.Category &&
                FoodStatusCheck.status == "ALL"
              ) {
                return (
                  checkfunction()
                );
              } else if (
                category.length && FoodStatusCheck.status == "BOUGHT" &&
                Foodpacking.status == "ALL" &&
                selectFoodCategory?.Category == t.Category ||
                Foodpacking.status == "ALL" && packed.length &&
                FoodStatusCheck.status == "PACKED" && selectFoodCategory?.Category == t.Category || UNDER.length &&
                Foodpacking.status == "ALL" &&
                FoodStatusCheck.status == "SHOPPING LIST" && selectFoodCategory?.Category == checkbox.Category) {
                return (
                  checkfunction()
                )
              } else if (
                category.length &&
                FoodStatusCheck.status == "BOUGHT" &&
                selectFoodCategory.Category == "ANY" &&
                Foodpacking.status == "ALL" ||
                packed.length &&
                FoodStatusCheck.status == "PACKED" &&
                Foodpacking.status == "ALL" &&
                selectFoodCategory.Category == "ANY" ||
                Foodpacking.status == "ALL" &&
                UNDER.length &&
                FoodStatusCheck.status == "SHOPPING LIST" &&
                selectFoodCategory.Category == "ANY"
              ) {
                return (
                  checkfunction()
                )
              }
              else if (
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Boat" && boat.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "4x4" && da4x4.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "4x4" && da4x42.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "4x4" && da4x42x.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Boat" && da4x42xx.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Boat" && da4x42xxx.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "ALL"
              ) {
                return (
                  checkFunc()
                  );
                }
            })}
            </div>
                        </AccordionDetails>
                        </Accordion>
            
            </> ) } ) }
              
            </>
          )
      } }

  const [selectMealCategory, setSelectMealCategory] = useState("ANY")
  const handleMealChange = (e) => {
    setSelectMealCategory(e)
  }
  const [getid, setid] = useState([])
  useEffect(() => {
    const datasid = []
    listUserM?.map(el => {
      if (!getid.length) {
        datasid.push(el.id)
        setid(datasid)
        // console.log("confilict")
      }
      return null
    })
  }, [listUserM])

  listItemM.filter(function (array_el) {
    return getid.filter(function (anotherOne_el) {
      return anotherOne_el == array_el.id;
    })
  });

  const [meaweig, setmealweightsss] = useState([])
  useMemo(() => {
    if (!meaweig.length) {
      const dataaw = listUserM.map(el => {
        return {
          id: el.id,
          Weight: el.Foods,
          ...el
        }
      })
      setmealweightsss(dataaw)
    }
  }, [listUserM]);
  const quantityfunction = (number, id, item) => {
    const filla = listUserM.filter(v => v.id !== id)
    const nfilter = listUserM.filter(v => v.id == id)
    let awa;
    nfilter.map(el => {
      awa = el
    })
    setListUserM([...filla, { id, ...awa, Quantity: number }])

    const awaaaa = meaweig.map(el => {
      if (el.id == id) {
        return {
          ...el,
          Quantity: number
        }
      } else {
        return {
          ...el
        }
      }
    })
    setmealweightsss(awaaaa)
  }
  useEffect(() => {
    const awaaaa = meaweig.map((el, i) => {
      if (el.id == listUserM[i].id) {
        return {
          ...el,
          Quantity: listUserM[i].Quantity,
          Weight: el.Foods
        }
      } else {
        return {
          ...el,
          Weight: el.Foods
        }
      }
    })
    setmealweightsss(awaaaa)
  }, [])

  const awawameals = listItemM.filter(el => {
    if(el.Owner == SearchOwner.Name || SearchOwner.Name == "ANY" ){
      return true
    }else{
      return false
    }
  })

  const categorizedDataMeals = awawameals.reduce((acc, curr) => {
    const { CategoryValue, id, Category, Name, Foods } = curr;
    if (!acc[CategoryValue]) {
      acc[CategoryValue] = {
        items: [],
      };
    }
    acc[CategoryValue].items.push({ CategoryValue, Category, id, Name, Foods });
    return acc;
  }, {});

  const checkboxMealFunctionRender = () => (
    <>
    { Object.keys(categorizedDataMeals).sort().map((key, index) => {
        return ( <>
        <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {key == '1breakfast' ?  <><h3 style={{margin: '0', textTransform: 'uppercase'}}>Breakfast</h3></> : null}
          {key == '2lunch' ?  <><h3 style={{margin: '0', textTransform: 'uppercase'}}>Lunch</h3></> : null}
          {key == '3dinner' ?  <><h3 style={{margin: '0', textTransform: 'uppercase'}}>Dinner</h3></> : null}
          {key ?  null : <><h3 style={{margin: '0', textTransform: 'uppercase'}}>Unassigned</h3></>}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                      <div className="tpgrid">
      {categorizedDataMeals[key].items.map((t, index) => {
        if (selectMealCategory.Category == t.Category) {
          let result = t.Foods.reduce(function (acc, obj) { return acc + obj.Weight; }, 0);
          const aawa = listUserM.filter(el => el.id == t.id ? true : false)
          const getselecteddata = listUserM.filter(el => el.id == t.id ? true : false)
          const getalldata = listUserM.filter(el => el.id != t.id ? true : false)
          return (
            <>
              <FormControlLabel className="avoid sub-select-options" key={index * 9} style={{pointerEvents: 'none'}}
                control={
                  <Checkbox checked={getid.some((p) => p == t.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const getids = [...getid]
                        getids.push(t.id)
                        setid(getids);
                        const getwight = [...meaweig]
                        getwight.push({ ...t, id: t.id, Quantity: '1', Weight: t.Foods })
                        setmealweightsss(getwight)

                        const filter = listUserM.filter(el => el.id != t.id ? true : false)
                        setListUserM((state) => [...filter, { id: t.id, ...t, Weight: 0 }]);
                      }
                      else {
                        let removeweight = meaweig.filter((o) => o.id !== t.id);
                        setmealweightsss(removeweight);
                        let remove = listUserM.filter((o) => o.id !== t.id);
                        setListUserM(remove);
                        let list = getid.filter((o) => o != t.id);
                        setid(list)
                      }
                    }}
                  />
                }
                label={
                  <span className="mobspanedit" style={{ fontWeight: 'bold' }}>
                    <span style={{ fontWeight: 'normal' }}>
                      {t.Name}
                    </span>
                    <br />
                    {listUserM?.map(el => {
                      if (el.id == t.id) {
                        return (
                          <>
                            <div>
                              {country?.label == "HIKING" || country?.label == "BIKEPACKING" ? (
                                <>
                                  <div style={{ display: 'flex', width: '50%' }}>
                                    {tr?.map((item, i) => {
                                      const checka = el?.mealHiking?.filter(a => a.Name == item.Name && a.id == t.id ? true : false)
                                      return (
                                        <FormControlLabel key={i} className="avoid sub-select-options" style={{pointerEvents: 'none'}}
                                          control={
                                            <Checkbox
                                              key={`cb-${i * 6 * 6 * 6}`}
                                              name={(item.Name) + ' ' + (item.Weight)}
                                              checked={checka?.length ? true : false}
                                              color="primary"
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  const getd = getselecteddata.map(els => {
                                                    const getoldda = els?.mealHiking || [];
                                                    const newitem = { Name: item.Name }
                                                    newitem.Quantity = 1
                                                    newitem.id = els.id
                                                    newitem.Foods = els.Foods
                                                    newitem.Weight = els.Weight
                                                    getoldda.push(newitem)
                                                    return {
                                                      ...els,
                                                      mealHiking: getoldda
                                                    }
                                                  })
                                                  const ar = [...getalldata, ...getd]
                                                  setListUserM(ar)
                                                } else {
                                                  const getd = getselecteddata.map(els => {
                                                    const fil = els?.mealHiking.filter(u => u.Name != item.Name)
                                                    return {
                                                      ...els,
                                                      mealHiking: fil
                                                    }
                                                  })
                                                  const ar = [...getalldata, ...getd]
                                                  setListUserM(ar)
                                                }
                                              }}
                                            />
                                          }
                                          label={
                                            <span style={{ fontWeight: 'bold' }}>
                                              {item.Name}
                                            </span>}
                                        />
                                      )
                                    })}
                                  </div>
                                  <br />
                                  <div>
                                    {el?.mealHiking?.map(it => {
                                      const numbers = it.id == t.id && it.Quantity;
                                      return (
                                        <>
                                          <TextField value={numbers} required InputLabelProps={{ shrink: true, }}
                                            id="standard-basic"
                                            label={it.Name}
                                            name="quantity" onWheel={(e) => e.target.blur()}
                                            style={{ marginLeft: '10px' }}
                                            onChange={e => {
                                              const getd = getselecteddata.map(els => {
                                                const getsingledata = els.mealHiking.filter(elm => elm.Name == it.Name && elm.id == els.id ? true : false)
                                                if (getsingledata?.length) {
                                                  getsingledata[0].Quantity = e.target.value;
                                                }
                                                return {
                                                  ...els,
                                                  Quantity: e.target.value
                                                }
                                              })
                                              const ar = [...getalldata, ...getd]
                                              setListUserM(ar)
                                            }}
                                            type="Number"
                                            variant="outlined"
                                          />
                                          <br />
                                        </>
                                      )
                                    })}
                                  </div>
                                </>
                              ) : (
                                  <>
                                    {getid.map(el => {
                                      if (el == t.id) {
                                        let number = "1";
                                        aawa.map(el => {
                                          number = el.Quantity
                                        });
                                        return (
                                          <div style={{ display: 'flex' }}>
                                            <TextField required value={number}
                                              InputLabelProps={{ shrink: true, }}
                                              fullWidth id="standard-basic"
                                              label="Qty" name="quantity" onWheel={(e) => e.target.blur()}
                                              InputProps={{
                                                inputProps: { min: 1, inputMode: 'numeric' }
                                              }}
                                              onChange={e => {
                                                quantityfunction(e.currentTarget.value, t.id, t)
                                              }} type="number" variant="outlined" />

                                          </div>
                                        )
                                      }
                                    })}
                                  </>
                                )}
                            </div>
                          </>
                        )
                      }
                    })}

                  </span>}
              />
            </>
          );
        } else if (selectMealCategory == "ANY" || selectMealCategory.Category == "ANY") {
          let result = t.Foods.reduce(function (acc, obj) { return acc + obj.Weight; }, 0);
          const aawa = listUserM.filter(el => el.id == t.id ? true : false)
          const getselecteddata = listUserM.filter(el => el.id == t.id ? true : false)
          const getalldata = listUserM.filter(el => el.id != t.id ? true : false)
          return (
            <>
              <FormControlLabel className="avoid sub-select-options" key={index * 9} style={{pointerEvents: 'none'}}
                control={
                  <Checkbox checked={getid.some((p) => p == t.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const getids = [...getid]
                        getids.push(t.id)
                        setid(getids);
                        const getwight = [...meaweig]
                        getwight.push({ ...t, id: t.id, Quantity: '1', Weight: t.Foods })
                        setmealweightsss(getwight)

                        const filter = listUserM.filter(el => el.id != t.id ? true : false)
                        setListUserM((state) => [...filter, { id: t.id, ...t, Weight: 0 }]);
                      }
                      else {
                        let removeweight = meaweig.filter((o) => o.id !== t.id);
                        setmealweightsss(removeweight);
                        let remove = listUserM.filter((o) => o.id !== t.id);
                        setListUserM(remove);
                        let list = getid.filter((o) => o != t.id);
                        setid(list)
                      }
                    }}
                  />
                }
                label={
                  <span className="mobspanedit" style={{ fontWeight: 'bold' }}>
                    <span style={{ fontWeight: 'normal' }}>
                     {t.Name}
                    </span>
                    <br />
                    {listUserM?.map(el => {
                      if (el.id == t.id) {
                        return (
                          <>
                            <div className="foodadjust foodadjustm foodadjustodd" id={"meal-" + country?.label.replace(/\s+/g, '_').toLowerCase()}>
                              {country?.label == "OLD AIR +" ? (
                                <>
                                    {tr?.map((item, i) => {
                                      const checka = el?.mealHiking?.filter(a => a.Name == item.Name && a.id == t.id ? true : false)
                                      return (
                                        <FormControlLabel key={i} className="avoid sub-select-options" style={{pointerEvents: 'none'}}
                                          control={
                                            <Checkbox
                                              key={`cb-${i * 6 * 6 * 6}`}
                                              name={(item.Name) + ' ' + (item.Weight)}
                                              checked={checka?.length ? true : false}
                                              color="primary"
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  const getd = getselecteddata.map(els => {
                                                    const getoldda = els?.mealHiking || [];
                                                    const newitem = { Name: item.Name }
                                                    newitem.Quantity = 1
                                                    newitem.id = els.id
                                                    newitem.Foods = els.Foods
                                                    newitem.Weight = els.Weight
                                                    getoldda.push(newitem)
                                                    return {
                                                      ...els,
                                                      mealHiking: getoldda
                                                    }
                                                  })
                                                  const ar = [...getalldata, ...getd]
                                                  setListUserM(ar)
                                                } else {
                                                  const getd = getselecteddata.map(els => {
                                                    const fil = els?.mealHiking.filter(u => u.Name != item.Name)
                                                    return {
                                                      ...els,
                                                      mealHiking: fil
                                                    }
                                                  })
                                                  const ar = [...getalldata, ...getd]
                                                  setListUserM(ar)
                                                }
                                              }}
                                            />
                                          }
                                          label={
                                            <span style={{ fontWeight: 'bold' }}>
                                              {item.Name}
                                            </span>}
                                        />
                                      )
                                    })}
                                  <div className="foodadjustsub">
                                    {el?.mealHiking?.map(it => {
                                      const numbers = it.id == t.id && it.Quantity;
                                      return (
                                        <>
                                          <TextField value={numbers} required InputLabelProps={{ shrink: true, }}
                                            id="standard-basic"
                                            label={it.Name}
                                            name="quantity" onWheel={(e) => e.target.blur()}
                                            style={{ marginLeft: '10px' }}
                                            onChange={e => {
                                              const getd = getselecteddata.map(els => {
                                                const getsingledata = els.mealHiking.filter(elm => elm.Name == it.Name && elm.id == els.id ? true : false)
                                                if (getsingledata?.length) {
                                                  getsingledata[0].Quantity = e.target.value;
                                                }
                                                return {
                                                  ...els,
                                                  Quantity: e.target.value
                                                }
                                              })
                                              const ar = [...getalldata, ...getd]
                                              setListUserM(ar)
                                            }}
                                            type="Number"
                                            variant="outlined"
                                          />
                                          <br />
                                        </>
                                      )
                                    })}
                                  </div>
                                </>
                              ) : (
                                  <>
                                    {getid.map(el => {
                                      if (el == t.id) {
                                        let number = "1";
                                        aawa.map(el => {
                                          number = el.Quantity
                                        });
                                        return (
                                          <div style={{ display: 'flex' }}>
                                            <TextField required value={number}
                                              InputLabelProps={{ shrink: true, }}
                                              fullWidth id="standard-basic"
                                              label="Qty" name="quantity" onWheel={(e) => e.target.blur()}
                                              InputProps={{
                                                inputProps: { min: 1, inputMode: 'numeric' }
                                              }}
                                              defaultValue="1"
                                              onChange={e => {
                                                quantityfunction(e.currentTarget.value, t.id, t)
                                              }} type="number" variant="outlined" />

                                          </div>
                                        )
                                      }
                                    })}
                                  </>
                                )}
                            </div>
                          </>
                        )
                      }
                    })}

                  </span>}
              />
            </>
          );
        }
      })}
      </div>
                      </AccordionDetails>
                      </Accordion>
    </>
  )
    })}
    </>
  )

  const styles = {
    control: base => ({
      ...base,
      border: 0,
      borderRadius: 0,
      // This line disable the blue border
      boxShadow: "none",
      margin: 0,
      padding: 0,
      zIndex: 9999,
    }),
    valueContainer: (provided, state) => ({
      padding: 0,

    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
  }
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children?.map(children, child =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const [showfile, setShowFile] = useState(false)
  const [showfileName, setShowFileName] = useState('')


  const filteredArr = listItem.reduce((acc, current) => {
    const x = acc.find(item => item.Category === current.Category);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  filteredArr.sort(function (a, b) {
    if (a.Category < b.Category) {
      return -1;
    }
    if (a.Category > b.Category) {
      return 1;
    }
    return 0;
  })
  filteredArr?.unshift({
    Brand: "ANY",
    Category: "ANY",
    Name: "ANY",
    SubCategory: "ANY",
    Weight: "ANY",
    id: "909525454450-0c6b-11ec-90f3-ff0167255365",
    value: "ANY",
  })

  const filteredFood = listItemF.reduce((acc, current) => {
    const x = acc.find(item => item.Category === current.Category);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  filteredFood.sort(function (a, b) {
    if (a.Category < b.Category) {
      return -1;
    }
    if (a.Category > b.Category) {
      return 1;
    }
    return 0;
  })
  filteredFood?.unshift({
    Brand: "ANY",
    Category: "ANY",
    Name: "ANY",
    SubCategory: "ANY",
    Weight: "ANY",
    id: "909525454450-0c6b-11ec-90f3-ff0167255365",
    value: "ANY",
  })

  const filteredMeal = listItemM.reduce((acc, current) => {
    const x = acc.find(item => item.Category === current.Category);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  filteredMeal.sort(function (a, b) {
    if (a.Category < b.Category) {
      return -1;
    }
    if (a.Category > b.Category) {
      return 1;
    }
    return 0;
  })
  filteredMeal?.unshift({
    Brand: "ANY",
    Category: "ANY",
    Name: "ANY",
    SubCategory: "ANY",
    Weight: "ANY",
    id: "909525454450-0c6b-11ec-90f3-ff0167255365",
    value: "ANY",
  })
  let data = { ...item }

  const gettotaldistance = useMemo(() => {
    let initialValue = 0
    return item?.Itinerary?.reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue.Distance)
      , initialValue
    )
  });

  

  const lastdataitem = data?.Itinerary?.length - 1

  const [uploadingImage, setUploadingImage] = useState(true);

  const [itemsit, setItemsIt] = useState(data?.Itinerary)
 
  function onDragEnd(result) {
    const newItems = [...itemsit];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setItemsIt(newItems);
    setItem({ ...item, Itinerary: (newItems)});
  }

  const RealTimeItanary = () => {
    return (
      <>
      <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div className="droppable" {...provided.droppableProps} ref={provided.innerRef}>
        {data && data.Itinerary && data.Itinerary.length > 0 && data.Itinerary?.map((boob, index) => {
            const onImageChanges = (e) => {
            if (boob.FileName) {
              var file = e.target.files[0];
              // async magic goes here...
              if (file === null) {
                console.error(`not an image, the image file is a`)
              }
              const uploadTask = storage.ref(`${user1}/${boob.Date}/${boob.FileName}`).put(file)
              //initiates the firebase side uploading 
              uploadTask.on('state_changed',
                (snapShot) => {
                  //takes a snap shot of the process as it is happening
                }, (err) => {
                  //catches the errors
                  console.log(err)
                }, () => {
                  // gets the functions from storage refences the image storage in firebase by the children
                  // gets the download url then sets the image from firebase as the value for the imgUrl key:
                  storage.ref(`${user1}/${boob.Date}`).child(boob.FileName).getDownloadURL()
                    .then(fireBaseUrl => {
                      data.Itinerary[index].Document.push({ url: fireBaseUrl, name: boob.FileName })
                      setItem(data)
                    })
                })
            } else {
              var file = e.target.files[0];
              // async magic goes here...
              if (file === null) {
                console.error(`not an image, the image file is a`)
              }
              const uploadTask = storage.ref(`${user1}/${boob.Date}/${file.name}`).put(file)
              setUploadingImage(false)
              //initiates the firebase side uploading 
              uploadTask.on('state_changed',
                (snapShot) => {
                  //takes a snap shot of the process as it is happening
                }, (err) => {
                  //catches the errors
                  console.log(err)
                }, () => {
                  // gets the functions from storage refences the image storage in firebase by the children
                  // gets the download url then sets the image from firebase as the value for the imgUrl key:
                  storage.ref(`${user1}/${boob.Date}`).child(file.name).getDownloadURL()
                    .then(fireBaseUrl => {
                      data.Itinerary[index].Document.push({ url: fireBaseUrl, name: file.name })
                      setItem(data)
                      setUploadingImage(true)
                    })
                })
            }
          }
          
          if (getdataT?.DistancePref == true || getdataT?.DistancePref == undefined) {
            var distancei = 'km';
          } else {
            var distancei = 'mi';
          }
          
          return (
              <Draggable key={'item-'+index} draggableId={'item-'+index} index={index}>
                      {(provided) => (
                        <Accordion disableGutters={true}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Typography><DragIndicatorIcon className="mobilehide"></DragIndicatorIcon> <span className="iheader"><b>{'DAY' + ' ' + (index + 1)}</b> {new Date(boob.Date).toLocaleDateString()} <b style={{paddingLeft: '10px'}}>DISTANCE: </b> {boob.Distance ? boob.Distance + distancei : 'n/a' } </span> <span className="itineraryquick"><b>STAY: </b> {boob.Stay ? boob.Stay : 'n/a'}</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
              <div className="itineraryflex">
              <div style={{maxWidth:'150px', position:'relative'}}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                  label={'Day' + ' ' + (index + 1)}
                  inputFormat="dd/MM/yyyy"
                  closeOnSelect={true}
                  value={boob.Date}
                  views={['day']}
                  onChange={(newValue) => {
                    boob.Date = format(newValue, 'yyyy-MM-dd'); setList([...list]);
                  }}
                  renderInput={(params) => <TextField value={boob.Date} variant="outlined" {...params} />}
                />
              </LocalizationProvider>
              <br/><span className="mobilehide">
              {index !== 0 ? (
                        <>
                        <Button color="primary" component="span" style={{color: '#757575'}} onClick={() => handleRemoveFields(index)}>
                        <RemoveCircleIcon />&nbsp;&nbsp;DELETE DAY
                        </Button>
                      </>
                      ) : <><IconButton style={{display: 'none'}} size="small" aria-label="edit" onClick={() => handleRemoveFields(index)}><RemoveCircleIcon /></IconButton></> }
              </span>
              </div>
              { /* <TextField InputLabelProps={{ shrink: true, }} id="standard-basic" label="From" value={boob.From} name="From" onChange={e => { boob.From = e.target.value; setList([...list]); }} type="text" variant="outlined" />&nbsp;&nbsp;
              <TextField InputLabelProps={{ shrink: true, }} id="standard-basic" label="To" value={boob.To} name="To" onChange={e => { boob.To = e.target.value; setList([...list]); }} type="text" variant="outlined" />&nbsp;&nbsp; */ }
                <div style={{maxWidth:'250px'}}>
                <TextField InputLabelProps={{ shrink: true, }} id="standard-basic" label={'Distance (' + distancei + ')'} value={boob.Distance} name="Distance" onChange={e => { boob.Distance = e.target.value; setList([...list]); }} type="text" variant="outlined" />
                <TextField InputLabelProps={{ shrink: true, }} id="standard-basic" label="Camp" value={boob.Stay} name="Camp" onChange={e => { boob.Stay = e.target.value; setList([...list]); }} type="text" variant="outlined" /><br/>
                </div>
                <div className="alternatelabel">
                  <label>Route Details</label>
                  <TextareaAutosize value={boob.Notes} name="Notes" onChange={e => { boob.Notes = e.target.value; setList([...list]); }} style={{ width: '100%', border: '1px solid #c4c4c4', borderRadius: '3px', resize: 'none', fontFamily: 'inherit', fontSize: 'inherit', color: 'inherit', padding: '18.5px 14px', boxSizing: 'border-box', outline: 'none', margin: '10px 0', minHeight: '132px' }}></TextareaAutosize>
                </div>
                { /* <div className="alternatelabel">
                  <label>Sights &amp; Activities</label>
                  <TextareaAutosize value={boob.Activities} name="Activities" onChange={e => { boob.Activities = e.target.value; setList([...list]); }} style={{ width: '100%', border: '1px solid #c4c4c4', borderRadius: '3px', resize: 'none', fontFamily: 'inherit', fontSize: 'inherit', color: 'inherit', padding: '18.5px 14px', boxSizing: 'border-box', outline: 'none', margin: '10px 0', minHeight: '132px' }}></TextareaAutosize>
                </div> */ }
                { /* <>
                <div className="downloadcontainer" style={{maxWidth:'250px'}}>
                <span className="mobilehide" style={{position:'relative', display:'block', height:'35px'}}>
                <input className="documentupload" accept="image/*,.pdf,.docx,.xlsx,.gpx,text/xml,text/gpsxml,application/gpsxml"  id="icon-button-file" type="file" onChange={event => onImageChanges(event)} />
  <label htmlFor="icon-button-file" style={{left:'0'}}>
    <Button color="primary" component="span" style={{color: '#757575', paddingLeft: '0', position:'relative'}}>
      <CloudUploadIcon />&nbsp;&nbsp;Upload File
    </Button>
  </label></span>
                {boob?.Document?.length ? (
                  <>
                  {boob.Document.map(el => {
                    const isPDF = el?.name?.split('.')
                    const deleteFromFirebases = (url) => {
                      //1.
                      let pictureRef = storage.refFromURL(url);
                      //2.
                      pictureRef.delete()
                        .then(() => {
                          //3.
                          boob.Document = boob.Document.filter(urls => urls.url != url);
                          // item.Itinerary[index].Document = boob.Document
                          data.Itinerary[index].Document = boob.Document
                          setItem(data)
                          alert("File has been removed");
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    };
                    return (
                      <>
                        <div className="directdownload btndownload" onClick={() => {
                          setShowFileName(el.name)
                        }}>
                          {showfile == true && showfileName == el.name && (
                            <>
                              {isPDF[1] == "pdf" ? (
                                <div style={{ width: '100%', overflow: "hidden" }}>
                                  <PDF urls={el.url} />
                                </div>
                              ) : isPDF[1] == "gpx" ? (
                                <Button><a style={{ display: 'block' }} href={el.url}>Download {el.name}</a></Button>
                              )
                                  : (
                                    <img className="renderedupload" src={el.url} style={{ width: '50%', height: '300px' }} />
                                  )}
                            </>
                          )}
                          <div className="downloadbuttons btndownload">
                          
                          <Button className="btndownload" variant="outlined"><a target="_blank" href={el.url}>{el.name}</a>&nbsp;&nbsp;
                          <IconButton size="small" aria-label="download" onClick={() => {handleDownload(el.url, el.name)}}><CloudDownloadIcon /></IconButton>
                          <IconButton size="small" aria-label="delete" onClick={() => deleteFromFirebases(el.url)}><DeleteIcon /></IconButton>
                          </Button>
                          </div>
                        </div>
                      </>
                    )
                  })}
                  </> ) : null} </div>
                </> */ }
              

                { /* MOBILE DISPLAY
                <div className="desktophide mobileitinerarybtns">
                <div style={{textAlign:'right'}}>
                {index !== 0 ? (
                        <>
                        <Button color="primary" component="span" style={{color: '#757575'}} onClick={() => handleRemoveFields(index)}>
                        <RemoveCircleIcon />&nbsp;&nbsp;DELETE DAY
                        </Button>
                      </>
                      ) : <><IconButton style={{display: 'none'}} size="small" aria-label="edit" onClick={() => handleRemoveFields(index)}><RemoveCircleIcon /></IconButton></> }
                </div>
                <div style={{position:'relative'}}>
                <input className="documentupload" accept="image/*,.pdf,.docx,.xlsx,.gpx,text/xml,text/gpsxml,application/gpsxml"  id="icon-button-file" type="file" onChange={event => onImageChanges(event)} />
  <label htmlFor="icon-button-file">
    <Button color="primary" component="span" style={{color: '#757575', marginTop: '20px'}}>
      <CloudUploadIcon />&nbsp;&nbsp;Upload File
    </Button>
  </label>
                </div>
                </div>
               */ }
              </div></AccordionDetails></Accordion>)}
                    </Draggable>
                    
          );
        })}
        {provided.placeholder}
        </div>)}
          </Droppable>
        </DragDropContext><br/>
        <Button variant="outlined" style={{width:'120px', fontSize:'0.8rem'}} onClick={() => handleAddFields()}><AddCircleIcon />&nbsp;&nbsp;ADD DAY</Button><br/>
        <table className="table-packing-pdf" style={{display: 'none'}}>
                <thead>
                          <tr>
                          <th>Date</th>
                          <th>Stay</th>
                          <th>Activities</th>
                          <th>Notes</th>
                          </tr>
                        </thead>
                {data.Itinerary?.map((boob, index) => {
                  return (
                    <>
                <tr>
                <td>{boob.Date.split("-").reverse().join("/")}</td>
                  <td>{boob.Stay}</td>
                  <td>{boob.Activities}</td>
                  <td>{boob.Notes}</td>
                </tr>
                </>
                  )})}
                </table><br/>
                { /* <Button variant="outlined" onClick={savePDF}>Save as PDF</Button><br/><br/> */ }
      </>
    )
  }
  const onSubmit10second = (i) => {
    item.Gear = (listUser);
    item.Food = (listUserF);
    item.Meals = (listUserM);
    item.Document = allImages;
    item.Travelers = addtraivler;
    firebase
      .firestore()
      .collection("trips")
      .doc(item.id)
      .update(item)
  };



  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  let intervalID;
  useEffect(() => {
  if (editing) {
       intervalID = setInterval(() => {
       const formSubmitButton = document.querySelector(".btnaa");
       formSubmitButton.click();
       }, 1500);
  }
  return () => clearInterval(intervalID);
  }, [editing]);


  const getgearweight = useMemo(() => {
    let initialValue = 0
    return listUser.reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity)
      , initialValue
    )
  }, [listUser]);
  const getFoodweight = useMemo(() => {
    let initialValue = 0
    return foodweights.reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * (currentValue.Quantity ? Number(currentValue.Quantity) : '1')
      , initialValue
    )
  }, [foodweights]);

  let sum;
  const [n, setn] = useState(0)
  useMemo(() => {
    sum = meaweig.reduce(function (a, b) {
      b.Foods.forEach(function (c) {
        a += Number(c.Weight) * (b.Quantity ? Number(b.Quantity) : '1');
      });
      return a;
    }, 0);
    setn(sum)
  }, [meaweig]);
  const [newdara, setNewdara] = useState([])

  const UpdateCHeckbox2 = (val, item) => {
    const getdataaa = [...meaweig]
    const onedataaaa = getdataaa.map(el => {
      return {
        ...el,
        Weight: el.Weight.map(e => {
          if (e.label == item.label) {
            return {
              ...e,
              packingLocation: val
            }
          } else {
            return {
              ...e,
            }
          }
        })
      }
    })
    setmealweightsss(onedataaaa)
    const getdata = [...listUserM]
    const onedata = getdata.map(el => {
      return {
        ...el,
        Foods: el.Foods.map(e => {
          if (e.label == item.label) {
            return {
              ...e,
              packingLocation: val
            }
          } else {
            return {
              ...e,
            }
          }
        })
      }
    })
    setListUserM(onedata)
  }
  const UpdateCHeckbox = (e, i, index) => {
    const getdata = [...listUserM]
    if (e.target.checked) {
      const onedata = getdata.map(el => {
        return {
          ...el,
          Foods: el.Foods.map(e => {
            if (e.label == i.label) {
              return {
                ...e,
                packed: true
              }
            } else {
              return {
                ...e,
              }
            }
          })
        }
      })
      setListUserM(onedata)
    } else {
      const onedata = getdata.map(el => {
        return {
          ...el,
          Foods: el.Foods.map(e => {
            if (e.label == i.label) {
              return {
                ...e,
                packed: false
              }
            } else {
              return {
                ...e,
              }
            }
          })
        }
      })
      setListUserM(onedata)
    }
  }
  const UpdateCHeckboxboght = (e, i) => {
    const getdata = [...listUserM]
    if (e.target.checked) {
      const onedata = getdata.map(el => {
        return {
          ...el,
          Foods: el.Foods.map(e => {
            if (e.label == i.label) {
              return {
                ...e,
                bought: true
              }
            } else {
              return {
                ...e,
              }
            }
          })
        }
      })
      setListUserM(onedata)
    } else {
      const onedata = getdata.map(el => {
        return {
          ...el,
          Foods: el.Foods.map(e => {
            if (e.label == i.label) {
              return {
                ...e,
                bought: false
              }
            } else {
              return {
                ...e,
              }
            }
          })
        }
      })
      setListUserM(onedata)
    }
  }

  const daataa = []
  const red = newdara?.map(el => {
    el?.Foods?.map(e => {
      const newDa = {
        ...e,
        inputQuantity: el.Quantity ? el.Quantity : 1,
        Category: el.Category
      }
      daataa.push(newDa)
    })
    return {
      Foods: el?.Foods,
    }
  })

  function getUniqueDataCount(objArr, propName) {
    const c = [...objArr]
    var data = [];
    return objArr.reduce((c,data)=>{
         c[data[propName]] ??= {...data, inputQuantity:0};
         c[data[propName]].inputQuantity += Number(data.inputQuantity);
         return c;
    },{});
  }

  useMemo(() => {
    setNewdara(listUserM)
  }, [listUserM])
  var mydata;
useMemo(() => {
    mydata = Object.values(getUniqueDataCount(daataa, 'label'));
}, [daataa])
  let result = mydata.reduce((c, v) => {
    c[v.label] = (c[v.label] || 0) + parseFloat(v.Quantity);
    return c;
  }, {});
  var newCheck;
  useMemo(() => {
    newCheck = Object.values(mydata?.reduce((acc, cur) => Object.assign(acc, { [cur?.label]: cur }), {}))
  }, [mydata])
  newCheck?.sort(function (a, b) {
    var textA = a.label;
    var textB = b.label;
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  const arja = []
  const ata = listUserM?.map(el => {
    el?.mealHiking?.map(e => {
      arja.push(e)
    })
  })
  const totalweightsssa = getgearweight + getFoodweight + n

  const anyaaafil = newCheck;
  const baseaa = newCheck?.filter(el => el.packingLocation == Foodpackingss.status ? true : false)
  const newawdadaaawrr = Foodpackingss.status == "ALL" ? anyaaafil : baseaa

  const categorizedDatasG2 = listUser.reduce((acc, curr) => {
    const { id, Category, CategoryId, Quantity, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, packingLocation } = curr;
    if (!acc[packingLocation]) {
      acc[packingLocation] = {
        items: [],
      };
    }
    acc[packingLocation].items.push({ Category, CategoryId, Quantity, packingLocation, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, id });
    return acc;
  }, {});

    const categorizedDataFoods12 = newawdadaaawrr.reduce((acc, curr) => {
      const { id, Category, label, value, inputQuantity, Quantity, packingLocation, bought } = curr;
      if (!acc[Category]) {
      acc[Category] = {
        items: [],
      };
    }
    acc[Category].items.push({ Category, label, value, inputQuantity, Quantity, packingLocation, bought, id });
    return acc;
  }, {});
  
  const categorizedDataFoods2 = listUserF?.reduce((acc, curr) => {
    const { id, Category, Name, Quantity, Weight, packingLocation, bought, packed } = curr;
    if (!acc[packingLocation]) {
      acc[packingLocation] = {
        items: [],
      };
    }
    acc[packingLocation].items.push({ Category, Name, Quantity, Weight, packingLocation, bought, packed, id });
    return acc;
  }, {});

  const diffInMs = new Date(item.DateTo.toDate()) - new Date(item.DateFrom.toDate())
  const diffInDays = diffInMs == 60000 ? 0 : diffInMs / (1000 * 60 * 60 * 24) + 1;

  const travawa = listItemUser?.length ? listItemUser[0]?.Travellers != undefined ? [...listItemUser[0]?.Travellers] : [] : []
  travawa?.sort(function (a, b) {
    var textA = a.Name.toUpperCase();
    var textB = b.Name.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  travawa?.unshift({
    Name: 'ANY'
  })
  travawa?.push({
    Name: 'Shared'
  })
  listUser?.sort(function (a, b) {
    var textA = a?.Category?.toUpperCase();
    var textB = b?.Category?.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  const categorizedDatas = listUser.reduce((acc, curr) => {
    const { id, Category, CategoryId, Quantity, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, packingLocation } = curr;
    if (!acc[CategoryId]) {
      acc[CategoryId] = {
        items: [],
      };
    }
    acc[CategoryId].items.push({ Category, CategoryId, Quantity, packingLocation, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, id });
    return acc;
  }, {});

  const categorizedDatas3 = listUser?.reduce((acc, curr) => {
    const { id, Category, CategoryId, Quantity, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, packingLocation } = curr;
    if (!acc[packingLocation]) {
      acc[packingLocation] = {
        items: [],
      };
    }
    acc[packingLocation].items.push({ Category, CategoryId, Quantity, packingLocation, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, id });
    return acc;
  }, {});

  const combinedValues = [...listUser, ...listUserF];

  const categorizedDatas4 = combinedValues.reduce((acc, curr) => {
    const { id, Category, CategoryId, Quantity, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, packingLocation } = curr;
    if (!acc[packingLocation]) {
      acc[packingLocation] = {
        items: [],
      };
    }
    acc[packingLocation].items.push({ Category, CategoryId, Quantity, packingLocation, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, id });
    return acc;
  }, {});

  const getCategoryPacked = (key) => {
    const foundCategorykey = categorizedDatas[key].items[0].CategoryId
    const foundCategory = newata?.GearCategories?.find((element) => element.id == foundCategorykey)
    if (foundCategory) {
    return foundCategory.label
    } else {
      return '-'
    }
}

const getCategorySortPacked = (key) => {
  const foundCategorykey = categorizedDatas[key].items[0].CategoryId
  const foundCategory = newata?.GearCategories?.find((element) => element.id == foundCategorykey)
  if (foundCategory) {
  return foundCategory.catid
  } else {
    return '-'
  }
}

const getCategoryPackedAlt = (key) => {
  const foundCategorykey = categorizedDatas2[key].items[0].CategoryId
  const foundCategory = newata?.GearCategories?.find((element) => element.id == foundCategorykey)
  if (foundCategory) {
  return foundCategory.label
  } else {
    return '-'
  }
}

const getCategorySortPackedAlt = (key) => {
const foundCategorykey = categorizedDatas2[key].items[0].CategoryId
const foundCategory = newata?.GearCategories?.find((element) => element.id == foundCategorykey)
if (foundCategory) {
return foundCategory.catid
} else {
  return '-'
}
}

  const categorizedDatasFood = listUserF.reduce((acc, curr) => {
    const { id, Category, Name, Quantity, Weight, packingLocation, bought, packed } = curr;
    if (!acc[Category]) {
      acc[Category] = {
        items: [],
      };
    }
    acc[Category].items.push({ Category, Name, Quantity, Weight, packingLocation, bought, packed, id });
    return acc;
  }, {});

  /* Calculate for each mode of travel */
  
  var resSet4x4=listUser.filter(function(options){
    return options.packingLocation == "4x4";
    }).reduce(function(a,b){
    return a + parseFloat(b.Weight * b.Quantity);
  },0);

  var resSet4x4F=listUserF.filter(function(options){
    return options.packingLocation == "4x4";
    }).reduce(function(a,b){
    return a + parseFloat(b.Weight * b.Quantity);
  },0);

  var resSet4x4M = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
    Quantity: x.Quantity
  })) : x.Foods).filter(function(options) {
    return options.packingLocation == "4x4";
  }).reduce(function(a, b) {
    return a + parseFloat(b.Weight * b.Quantity);
  }, 0);

  var resSetCamper=listUser.filter(function(options){
  return options.packingLocation == "Camper";
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
  },0);

  var resSetCamperF=listUserF.filter(function(options){
    return options.packingLocation == "Camper";
    }).reduce(function(a,b){
    return a + parseFloat(b.Weight * b.Quantity);
  },0);

  var resSetCamperM = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
    Quantity: x.Quantity
  })) : x.Foods).filter(function(options) {
    return options.packingLocation == "Camper";
  }).reduce(function(a, b) {
    return a + parseFloat(b.Weight * b.Quantity);
  }, 0);
    
  var resSetCaravan=listUser.filter(function(options){
    return options.packingLocation == "Caravan";
    }).reduce(function(a,b){
    return a + parseFloat(b.Weight * b.Quantity);
    },0);

    var resSetCaravanF=listUserF.filter(function(options){
      return options.packingLocation == "Caravan";
      }).reduce(function(a,b){
      return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetCaravanM = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "Caravan";
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

    var resSetBoat=listUser.filter(function(options){
      return options.packingLocation == "Boat";
      }).reduce(function(a,b){
      return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetBoatF=listUserF.filter(function(options){
        return options.packingLocation == "Boat";
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
        },0);

        var resSetBoatM = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
          Quantity: x.Quantity
        })) : x.Foods).filter(function(options) {
          return options.packingLocation == "Boat";
        }).reduce(function(a, b) {
          return a + parseFloat(b.Weight * b.Quantity);
        }, 0);

        /* HIKING CALCS */

      var resSetHikeF0=listUserF.filter(function(options){
        return options.packingLocation == "PACK - " + tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeF1=listUserF.filter(function(options){
        return options.packingLocation == "PACK - " + tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeF2=listUserF.filter(function(options){
        return options.packingLocation == "PACK - " + tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      
      var resSetHikeF3=listUserF.filter(function(options){
        return options.packingLocation == "PACK - " + tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeF4=listUserF.filter(function(options){
        return options.packingLocation == "PACK - " + tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeF5=listUserF.filter(function(options){
        return options.packingLocation == "PACK - " + tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeG0=listUser.filter(function(options){
        return options.packingLocation == "Consumables" && options.Owner == tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeG1=listUser.filter(function(options){
        return options.packingLocation == "Consumables" && options.Owner == tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeG2=listUser.filter(function(options){
        return options.packingLocation == "Consumables" && options.Owner == tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeG3=listUser.filter(function(options){
        return options.packingLocation == "Consumables" && options.Owner == tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeG4=listUser.filter(function(options){
        return options.packingLocation == "Consumables" && options.Owner == tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeG5=listUser.filter(function(options){
        return options.packingLocation == "Consumables" && options.Owner == tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGBW0=listUser.filter(function(options){
        return options.packingLocation == "Base Weight" && options.Owner == tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGBW1=listUser.filter(function(options){
        return options.packingLocation == "Base Weight" && options.Owner == tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGBW2=listUser.filter(function(options){
        return options.packingLocation == "Base Weight" && options.Owner == tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGBW3=listUser.filter(function(options){
        return options.packingLocation == "Base Weight" && options.Owner == tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGBW4=listUser.filter(function(options){
        return options.packingLocation == "Base Weight" && options.Owner == tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGBW5=listUser.filter(function(options){
        return options.packingLocation == "Base Weight" && options.Owner == tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGW0=listUser.filter(function(options){
        return options.packingLocation == "Wear" && options.Owner == tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGW1=listUser.filter(function(options){
        return options.packingLocation == "Wear" && options.Owner == tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGW2=listUser.filter(function(options){
        return options.packingLocation == "Wear" && options.Owner == tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGW3=listUser.filter(function(options){
        return options.packingLocation == "Wear" && options.Owner == tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGW4=listUser.filter(function(options){
        return options.packingLocation == "Wear" && options.Owner == tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGW5=listUser.filter(function(options){
        return options.packingLocation == "Wear" && options.Owner == tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGTB0=listUser.filter(function(options){
        return options.packingLocation == "Travel Bag" && options.Owner == tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGTB1=listUser.filter(function(options){
        return options.packingLocation == "Travel Bag" && options.Owner == tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGTB2=listUser.filter(function(options){
        return options.packingLocation == "Travel Bag" && options.Owner == tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGTB3=listUser.filter(function(options){
        return options.packingLocation == "Travel Bag" && options.Owner == tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGTB4=listUser.filter(function(options){
        return options.packingLocation == "Travel Bag" && options.Owner == tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGTB5=listUser.filter(function(options){
        return options.packingLocation == "Travel Bag" && options.Owner == tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

        var resSetHike0 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
          Quantity: x.Quantity
        })) : x.Foods).filter(function(options) {
          return options.packingLocation == "PACK - " + tr[0]?.Name;
        }).reduce(function(a, b) {
          return a + parseFloat(b.Weight * b.Quantity);
        }, 0);
        var resSetHike1 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
          Quantity: x.Quantity
        })) : x.Foods).filter(function(options) {
          return options.packingLocation == "PACK - " + tr[1]?.Name;
        }).reduce(function(a, b) {
          return a + parseFloat(b.Weight * b.Quantity);
        }, 0);
        var resSetHike2 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
          Quantity: x.Quantity
        })) : x.Foods).filter(function(options) {
          return options.packingLocation == "PACK - " + tr[2]?.Name;
        }).reduce(function(a, b) {
          return a + parseFloat(b.Weight * b.Quantity);
        }, 0);
        var resSetHike3 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
          Quantity: x.Quantity
        })) : x.Foods).filter(function(options) {
          return options.packingLocation == "PACK - " + tr[3]?.Name;
        }).reduce(function(a, b) {
          return a + parseFloat(b.Weight * b.Quantity);
        }, 0);
        var resSetHike4 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
          Quantity: x.Quantity
        })) : x.Foods).filter(function(options) {
          return options.packingLocation == "PACK - " + tr[4]?.Name;
        }).reduce(function(a, b) {
          return a + parseFloat(b.Weight * b.Quantity);
        }, 0);

/* AIR+ CALCS */

var resSetAirGBW0 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "CARRY - " + (tr[0]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirGBW1 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "CARRY - " + (tr[1]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirGBW2 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "CARRY - " + (tr[2]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirGBW3 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "CARRY - " + (tr[3]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirGBW4 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "CARRY - " + (tr[4]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirGBW5 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "CARRY - " + (tr[5]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirWE0 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "WEAR - " + (tr[0]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirWE1 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "WEAR - " + (tr[1]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirWE2 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "WEAR - " + (tr[2]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirWE3 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "WEAR - " + (tr[3]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirWE4 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "WEAR - " + (tr[4]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirWE5 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "WEAR - " + (tr[5]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirBAG10 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG1 - " + (tr[0]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirBAG20 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG2 - " + (tr[0]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirBAG30 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG3 - " + (tr[0]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirBAG40 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG4 - " + (tr[0]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirBAG50 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG5 - " + (tr[0]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

  var resSetAirBAG11 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG1 - " + (tr[1]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirBAG21 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG2 - " + (tr[1]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirBAG31 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG3 - " + (tr[1]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirBAG41 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG4 - " + (tr[1]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirBAG51 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG5 - " + (tr[1]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirBAG12 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG1 - " + (tr[2]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirBAG22 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG2 - " + (tr[2]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirBAG32 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG3 - " + (tr[2]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirBAG42 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG4 - " + (tr[2]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

 var resSetAirBAG52 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG5 - " + (tr[2]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirBAG13 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG1 - " + (tr[3]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

  var resSetAirBAG23 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG2 - " + (tr[3]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

  var resSetAirBAG33 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG3 - " + (tr[3]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

  var resSetAirBAG43 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG4 - " + (tr[3]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

  var resSetAirBAG53 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG5 - " + (tr[3]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

  var resSetAirBAG14 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG1 - " + (tr[4]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

  var resSetAirBAG24 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG2 - " + (tr[4]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

  var resSetAirBAG34 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG3 - " + (tr[4]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

  var resSetAirBAG44 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG4 - " + (tr[4]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

  var resSetAirBAG54 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG5 - " + (tr[4]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

  var resSetAirBAG15 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG1 - " + (tr[5]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

  var resSetAirBAG25 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG2 - " + (tr[5]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

  var resSetAirBAG35 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG3 - " + (tr[5]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

  var resSetAirBAG45 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG4 - " + (tr[5]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

  var resSetAirBAG55 = listUser
  .flatMap((item) => item.gearHiking || [])
  .filter((options) => options.packingLocation === "BAG5 - " + (tr[5]?.Name || ""))
  .reduce((a, b) => a + parseFloat(b.Weight * b.Quantity || 0), 0);

var resSetAirFCO0=listUserF.filter(function(options){
  return options.packingLocation == "CARRY - " + tr[0]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFCO1=listUserF.filter(function(options){
  return options.packingLocation == "CARRY - " + tr[1]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFCO2=listUserF.filter(function(options){
  return options.packingLocation == "CARRY - " + tr[2]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFCO3=listUserF.filter(function(options){
  return options.packingLocation == "CARRY - " + tr[3]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFCO4=listUserF.filter(function(options){
  return options.packingLocation == "CARRY - " + tr[4]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFCO5=listUserF.filter(function(options){
  return options.packingLocation == "CARRY - " + tr[5]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG10=listUserF.filter(function(options){
  return options.packingLocation == "BAG1 - " + tr[0]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG11=listUserF.filter(function(options){
  return options.packingLocation == "BAG1 - " + tr[1]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG12=listUserF.filter(function(options){
  return options.packingLocation == "BAG1 - " + tr[2]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG13=listUserF.filter(function(options){
  return options.packingLocation == "BAG1 - " + tr[3]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG14=listUserF.filter(function(options){
  return options.packingLocation == "BAG1 - " + tr[4]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG15=listUserF.filter(function(options){
  return options.packingLocation == "BAG1 - " + tr[5]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG20=listUserF.filter(function(options){
  return options.packingLocation == "BAG2 - " + tr[0]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG21=listUserF.filter(function(options){
  return options.packingLocation == "BAG2 - " + tr[1]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG22=listUserF.filter(function(options){
  return options.packingLocation == "BAG2 - " + tr[2]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG23=listUserF.filter(function(options){
  return options.packingLocation == "BAG2 - " + tr[3]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG24=listUserF.filter(function(options){
  return options.packingLocation == "BAG2 - " + tr[4]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG25=listUserF.filter(function(options){
  return options.packingLocation == "BAG2 - " + tr[5]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG30=listUserF.filter(function(options){
  return options.packingLocation == "BAG3 - " + tr[0]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG31=listUserF.filter(function(options){
  return options.packingLocation == "BAG3 - " + tr[1]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG32=listUserF.filter(function(options){
  return options.packingLocation == "BAG3 - " + tr[2]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG33=listUserF.filter(function(options){
  return options.packingLocation == "BAG3 - " + tr[3]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG34=listUserF.filter(function(options){
  return options.packingLocation == "BAG3 - " + tr[4]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG35=listUserF.filter(function(options){
  return options.packingLocation == "BAG3 - " + tr[5]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG40=listUserF.filter(function(options){
  return options.packingLocation == "BAG4 - " + tr[0]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG41=listUserF.filter(function(options){
  return options.packingLocation == "BAG4 - " + tr[1]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG42=listUserF.filter(function(options){
  return options.packingLocation == "BAG4 - " + tr[2]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG43=listUserF.filter(function(options){
  return options.packingLocation == "BAG4 - " + tr[3]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG44=listUserF.filter(function(options){
  return options.packingLocation == "BAG4 - " + tr[4]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirFBAG45=listUserF.filter(function(options){
  return options.packingLocation == "BAG4 - " + tr[5]?.Name;
  }).reduce(function(a,b){
  return a + parseFloat(b.Weight * b.Quantity);
},0);

var resSetAirMC0 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "CARRY - " + tr[0]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG10 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG1 - " + tr[0]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG20 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG2 - " + tr[0]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG30 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG3 - " + tr[0]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG40 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG4 - " + tr[0]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMC1 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "CARRY - " + tr[1]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG11 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG1 - " + tr[1]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG21 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG2 - " + tr[1]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG31 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG3 - " + tr[1]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG41 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG4 - " + tr[1]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMC2 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "CARRY - " + tr[2]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG12 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG1 - " + tr[2]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG22 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG2 - " + tr[2]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG32 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG3 - " + tr[2]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG42 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG4 - " + tr[2]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMC3 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "CARRY - " + tr[3]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG13 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG1 - " + tr[3]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG23 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG2 - " + tr[3]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG33 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG3 - " + tr[3]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG43 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG4 - " + tr[3]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMC4 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "CARRY - " + tr[4]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG14 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG1 - " + tr[4]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG24 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG2 - " + tr[4]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG34 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG3 - " + tr[4]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

var resSetAirMBAG44 = listUserM.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
  Quantity: x.Quantity
})) : x.Foods).filter(function(options) {
  return options.packingLocation == "BAG4 - " + tr[4]?.Name;
}).reduce(function(a, b) {
  return a + parseFloat(b.Weight * b.Quantity);
}, 0);

/* END AIR+ CALCS */


        const maam = []
  listUser?.map(el => {
    
    /* const categorymatch = Object.values(newata?.GearCategories).filter(chunk => chunk.id === el.CategoryId)
    const realcat = categorymatch[0] */
    

    el?.gearHiking?.map(els => {
      const data = {
        Quantity: els.Quantity,
        packingLocation: els.packingLocation,
        Name: el.Name,
        Owner: els.Name,
        RealOwner: el.Owner,
        Brand: el.Brand,
        id: el.id,
        Category: el.Category,
        CategoryId: el.CategoryId,
        SubCategory: el.SubCategory,
        SubCategoryId: el.SubCategoryId,
        Weight: el.Weight,
        Status: el.Status
      }
      maam.push(data)
    })
  })

  maam?.sort(function (a, b) {
    var textA = a?.Category?.toUpperCase();
    var textB = b?.Category?.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });

  const categorizedDatas2 = maam.reduce((acc, curr) => {
    const { id, Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, Owner, RealOwner, packingLocation, Quantity, Weight, Status } = curr;

    // Check if newata and newata.GearCategories are defined
    if (newata && newata.GearCategories) {
        const categorymatch = Object.values(newata.GearCategories).filter(chunk => chunk.id === CategoryId);
        
        // Check if categorymatch has at least one element
        if (categorymatch.length > 0) {
            const realcat = categorymatch[0].label;

            if (!acc[Owner]) {
                acc[Owner] = {
                    items: [],
                };
            }

            acc[Owner].items.push({ id, realcat, Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, Owner, RealOwner, packingLocation, Quantity, Weight, Status });
        }
    }

    return acc;
}, {});


  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{
              position: 'absolute',
              right: 0,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
    setShowFile(!showfile);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const abcd = new Date(item.DateFrom.toDate()).toUTCString(); 
  if (abcd == 'Wed, 30 Dec 2099 16:00:00 GMT') {s
  } 

  const handleDownload = (url, filename) => {
    if (isPlatform("ios")) {
          console.log('Filename' + filename)
          console.log('URL' + url)
            let pdfOutput = url;
            const directory = File.tempDirectory;
            console.log('Dir' + directory)
            File.writeFile(directory, filename, pdfOutput, true).then(success => {
              FileOpener.showOpenWithDialog(directory + fileName, 'application/pdf')
                .then(() => console.log('File opened'))
                .catch(e => console.log('Error opening file', e));
            },
              err => {
                console.log(" writing File error : ", err)
              })
    } else {
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
    }
  }

  const tatata = addtraivler.reduce((a,v) =>  a = a + Number(v.Weight) , 0 );

  const [value, setValue] = React.useState("");

  const savePDF = () => {
    // See https://rawgit.com/MrRio/jsPDF/master/docs/jsPDF.html
    
    var randomno = Math.random()

    const doc = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "letter"
    });
    // doc.addImage('/logo_black.png', 'PNG', 40, 10, 183, 20)
    autoTable(doc, {
      html: '.table-packing-pdf',
      includeHiddenHtml: false,
      theme: 'grid',
      headStyles: { fontSize: '8', fillColor: [0, 0, 0] },
      bodyStyles: {fontSize: '8'},
    })
    autoTable(doc, {
      html: '.table-packing-pdf0',
      includeHiddenHtml: false,
      theme: 'grid',
      headStyles: { fontSize: '8', fillColor: [0, 0, 0] },
      bodyStyles: {fontSize: '8'},
    })
    autoTable(doc, {
      html: '.table-packing-pdf1',
      includeHiddenHtml: false,
      theme: 'grid',
      headStyles: { fontSize: '8', fillColor: [0, 0, 0] },
      bodyStyles: {fontSize: '8'},
    })
    autoTable(doc, {
      html: '.table-packing-pdf2',
      includeHiddenHtml: false,
      theme: 'grid',
      headStyles: { fontSize: '8', fillColor: [0, 0, 0] },
      bodyStyles: {fontSize: '8'},
    })
    autoTable(doc, {
      html: '.table-packing-pdf3',
      includeHiddenHtml: false,
      theme: 'grid',
      headStyles: { fontSize: '8', fillColor: [0, 0, 0] },
      bodyStyles: {fontSize: '8'},
    })
    autoTable(doc, {
      html: '.table-packing-pdf4',
      includeHiddenHtml: false,
      theme: 'grid',
      headStyles: { fontSize: '8', fillColor: [0, 0, 0] },
      bodyStyles: {fontSize: '8'},
    })
    doc.setFontSize(7)
    doc.text('ADVENTURE CURATED TRIP PLANNER',40,15)
    doc.output('bloburl');
    let pdfOutput = doc.output();
    if (isPlatform("ios")) {
      // for iOS device
      const directory = File.tempDirectory;
      const fileName = `Trip_Planner`+randomno+`.pdf`
      File.writeFile(directory, fileName, pdfOutput, true).then(success => {
        FileOpener.showOpenWithDialog(directory + fileName, 'application/pdf')
          .then(() => console.log('File opened'))
          .catch(e => console.log('Error opening file', e));
      },
        err => {
          console.log(" writing File error : ", err)
        })
    } else {
      // for desktop
      doc.save('Trip_Planner'+randomno+'.pdf');
    }
    
  };

  return (
    <div>
      <div className="backchevron" onClick={() => {
            history.push('/trips/index')
            setEditing(false)
          }}><FontAwesomeIcon icon={faChevronLeft} size="lg" /></div>
      <AppBar className="trip-nav" position="static" color="default">
        <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth" aria-label="" scrollButtons="off">
                <Tab icon={<FontAwesomeIcon icon={faInfoCircle} size="lg" />} onClick={() => setSaveState(true)} label={<><b>INFORMATION</b></>} wrapped />
                <Tab onClick={() => {
                  if (!inputFields?.length) {
                    setInputFields([
                      { Date: '', From: '', To: '', Distance: '', Stay: '', Notes: '', Activities: '', Document: [] }
                    ])
                  }
                }} icon={<FontAwesomeIcon icon={faCalendarDays} size="lg" />} label={<><b>ITINERARY</b></>} wrapped />
                { /*
                <Tooltip leaveTouchDelay={1000} TransitionComponent={Zoom} enterTouchDelay={0} title="Budget" placement="top" arrow>
                <Tab icon={<FontAwesomeIcon icon={faSackDollar} size="lg" />} onClick={() => setSaveState(true)} />
                </Tooltip> */ }
                <Tab icon={<FontAwesomeIcon icon={faCampground} size="lg" />} onClick={() => setSaveState(true)} label={<><b>TRIP GEAR</b></>} wrapped />
                { /*
                <Tooltip leaveTouchDelay={1000} TransitionComponent={Zoom} enterTouchDelay={0} title="Meal Plan" placement="top" arrow>
                <Tab icon={<FontAwesomeIcon icon={faUtensils} size="lg" />} onClick={() => setSaveState(true)} />
                </Tooltip> */ }
                <Tab icon={<FontAwesomeIcon icon={faUtensils} size="lg" />} onClick={() => setSaveState(true)} label={<><b>TRIP FOOD</b></>} wrapped />
                <Tab icon={<FontAwesomeIcon icon={faWeightScale} size="lg" />} onClick={() => setSaveState(true)} label={<><b>WEIGHTS</b></>} wrapped />
                <Tab icon={<FontAwesomeIcon icon={faListCheck} size="lg" />} onClick={() => setSaveState(true)} label={<><b>PACKING LIST</b></>} wrapped />
                { /* country.label == '4x4' || country.label == 'OVERLANDING WITH TRAILER' || country.label == '4x4 TOWING CARAVAN' || country.label == '4x4 TOWING BOAT' || country.label == 'MOTORBIKE' ? 
                <Tab className="stackedicons" icon={<><FontAwesomeIcon icon={faWeightScale} size="lg" /><FontAwesomeIcon className="stackicon" icon={faGauge} size="lg" /></>}  onClick={() => setSaveState(true)} label={<><b>WEIGHTS</b></>} wrapped />
                :
                <Tab icon={<FontAwesomeIcon icon={faWeightScale} size="lg" />} onClick={() => setSaveState(true)} label={<><b>WEIGHTS</b></>} wrapped />
              */ }

              </Tabs>
            </AppBar>
    <Paper className="trip-paper trip-paper-update" elevation={3}>
      <div id="updateItem" className="updateitem updatetrip">
        { /* <Button
          disableElevation
          variant="outlined"
          color="default"
          size="small"
          type="submit"
          className="btn"
          onClick={() => {
            history.push('/trips/index')
            setEditing(false)
          }}
          startIcon={<FirstPageTwoToneIcon />}
        >
          Back
        </Button> */}
        {selectedTab === 0 && (
          <>
            <div className="mobile-trip-first" style={{ position: 'relative' }}>
            <TextField required InputLabelProps={{ shrink: true, }} fullWidth id="standard-basic" label="Trip Name" value={item.Name} name="Name" onChange={onChange} type="text" variant="outlined" />
            <FormControlLabel className="bucketlist-toggle" control={<Switch checked={item.Bucket} onChange={handleChange} name="checkedA" color="primary" />} label={item.Bucket ? <StarIcon fontSize="large" style={{ color: 'gold' }} /> : <StarBorderIcon fontSize="large" style={{ color: 'gold' }} /> } />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
              <div style={{ width: '100%' }}>
                <Select
                  menuPortalTarget={document.body}
                  isSearchable={ false }
                  fullWidth
                  placeholder={"Mode of Travel"}
                  value={country}
                  options={data_meals}
                  getOptionLabel={x => x.label}
                  getOptionValue={x => x.value}
                  className="select indent selectalternate"
                  onChange={handleCategoryChange}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#f1f1f1',
                      primary: '#202020',
                    },
                  })}
                  components={{
                    ValueContainer: CustomValueContainer
                  }}
                  styles={{
                    container: (provided, state) => ({
                      ...provided,
                      marginTop: 50,
                      paddingLeft: "11px"
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      overflow: "visible",
                      borderColor: "#fff !important",
                      padding: "0 !important"
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: "#fff !important",
                      boxShadow: "none"
                    }),
                    placeholder: (provided, state) => ({
                      ...provided,
                      position: "absolute",
                      top: state.hasValue || state.selectProps.inputValue ? -13 : "50%",
                      left: "-4px",
                      background: "#fff",
                      padding: "0 5px",
                      transition: "top 0.5s, font-size 0.1s",
                      color: "#A2A2A2",
                      fontSize: (state.hasValue || state.selectProps.inputValue) && 12
                    })
                  }} />
                <div className={getdataT?.TipsPref == true || getdataT?.TipsPref == undefined ? 'tooltip-select' : 'notooltip'} style={{ top: '25px', right: '50px', position: 'absolute' }}>
                  <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="Select your mode of travel to utilise the customised feature sets." placement="top-start" arrow>
                    <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                  </Tooltip>
                </div>
              </div></div>
            <div className="indent" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                  style={{ width: '100%' }}
                  label={"Date From"}
                  inputFormat="dd/MM/yyyy"
                  value={JSON.stringify(item.DateFrom.seconds) > 2500000000 ? null : new Date(item.DateFrom.toDate()).toUTCString()}
                  onChange={(newValue) => {
                    setItem({ ...item, DateFrom: firebase.firestore.Timestamp.fromDate(new Date(newValue))});
                  }}
                  renderInput={(params) => <TextFields {...params} helperText={null} fullWidth />}
                />
              </LocalizationProvider>
              <div style={{ top: '28px', right: '50px', position: 'absolute' }}>
                <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="When changing dates you will need to manually adjust the itinerary. This is to ensure existing planning is not lost." placement="top-start" arrow>
                  <IconButton className={getdataT?.TipsPref == true || getdataT?.TipsPref == undefined ? 'tooltip' : 'notooltip'} size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                </Tooltip>
              </div>
            </div>
            <div className="indent" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  style={{ width: '100%' }}
                  label={"Date To"}
                  inputFormat="dd/MM/yyyy"
                  minDate={new Date(item.DateFrom.toDate())}
                  value={JSON.stringify(item.DateTo.seconds) > 2500000000 ? null : new Date(item.DateTo.toDate()).toUTCString()}
                  onChange={(newValue) => {
                    setItem({ ...item, DateTo: firebase.firestore.Timestamp.fromDate(new Date(newValue))});
                  }}
                  renderInput={(params) => <TextFields {...params} fullWidth />}
                />
                
              </LocalizationProvider>
              <div style={{ top: '28px', right: '50px', position: 'absolute' }}>
                <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="When changing dates you will need to manually adjust the itinerary. This is to ensure existing planning is not lost." placement="top-start" arrow>
                  <IconButton className={getdataT?.TipsPref == true || getdataT?.TipsPref == undefined ? 'tooltip' : 'notooltip'} size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                </Tooltip>
              </div>
            </div>
            <Button variant="outlined" onClick={(newValue) => {
                    setItem({ ...item, DateFrom: firebase.firestore.Timestamp.fromDate(new Date("2099-12-31T00:00:00Z")), DateTo: firebase.firestore.Timestamp.fromDate(new Date("2099-12-31T00:01:00Z"))});
                  }}>CLEAR DATES</Button>
            {item.DateTo != '' && new Date(item.DateFrom.toDate()).toUTCString() != 'Thu, 31 Dec 2099 00:00:00 GMT' ? (
              <TextField disabled InputLabelProps={{ shrink: true, }} fullWidth id="standard-basic" label="Number of Days" value={Math.ceil(diffInDays)} name="name" type="number" variant="outlined" />
            ) : (
                <>
                    <TextField onKeyPress={(event) => { if (event?.key === '-' || event?.key === '+') { event.preventDefault(); } }} InputLabelProps={{ shrink: true, }} inputProps={{ min: 1 }} fullWidth id="standard-basic" label="Number of Days" value={item.noofdays} name="noofdays" onChange={e => onChange2(e)} type="number" variant="outlined" />
                </>
              )}
            <div className="travellerscontainer">
                    <label className="travellerslabel">Travellers</label>

            {ListOfTraivlers(listItemUser, addtraivler, setaddTraverler)}
            <Tooltip className={getdataT?.TipsPref == true || getdataT?.TipsPref == undefined ? 'travellerstooltip' : 'notooltip'} leaveTouchDelay={3000} enterTouchDelay={50} placement="top-start" arrow title="You can save Travellers in your Profile. Access Profile via the right icon in the header menu.">
          <IconButton size="small" style={{top:'20%',right:'47px'}}>
          <NotListedLocationIcon />
          </IconButton>
          </Tooltip>
            </div>
            <Button
          disableElevation
          style={{opacity:'0', pointerEvents:'none'}}
          variant="outlined"
          color="default"
          size="large"
          type="submit"
          className="btn greybtn"
          onClick={() => {
            history.push('/trips/index')
            setEditing(false)
          }}
          startIcon={<FirstPageTwoToneIcon />}
        >
          Back
        </Button>&nbsp;&nbsp;
            <Button
              disableElevation
              variant="outlined"
              color="secondary"
              size="large"
              type="submit"
              className="btn deletebtn redbtn"
              onClick={() => deleteItem(item.id)}
              startIcon={<DeleteTwoToneIcon />}
            >
              Delete Trip
      </Button>
          </>
        )}
        {selectedTab === 1 && (
          <>
            { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="Total Distance" value={gettotaldistance} name="Date" type="number" variant="outlined" />&nbsp;&nbsp;
              <br />
        <br /> */ }
            {uploadingImage == true ? null : <div className="loadingcontainer loadingcontaineralt"><div className="loading"><CircularProgress style={{'color': '#ef4923'}} /></div></div>}
            <div className="imain">
            {RealTimeItanary()}
            </div>
            <br/>
          </>
        )}
        {selectedTab === 2 && (
          <>
            <div className="select4filter tripbuttons" style={{height:'auto', marginBottom:'20px !important'}}>
                <Button onClick={() => setSelectedTab(4)} className="btntripw" variant="outlined" size="large"><FontAwesomeIcon icon={faWeightScale} size="sm" /> {getgearweight.toFixed(2)}{getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}</Button>
            </div>
             <div style={{ clear: 'both'}}></div>
             <div style={{display: 'flex', flexDirection: 'column'}}>
            {packeditem == false ? (
              <>
                {checkboxGearFunctionRender()}
              </>
            ) : (
                <>
                  {listUser.length >= 1 ?
                  <>
                  <div className="pdf-container">
                  {country?.label == "HIKING" || country?.label == "BIKEPACKING" || country?.label == "AIR +" ? (
                    <>
                    {maam.length >= 1 ? null : 'Please ensure you have selected Travellers from the PLAN tab.'}
                    {Object.keys(categorizedDatas2).sort().map((key, index) => {
                          return (
                            <>
                              {/* <h3>{`${key}`}</h3> */}
                              <div className="packed_gear__">
                                  <Accordion style={{borderRadius:'0'}} elevation={1} defaultExpanded={false} className="filter-items-accordion-packing">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0.5px'}}
                      >
                      <Typography>
                                    <h3 style={{margin:'0',textTransform:'uppercase'}}>{`${key == 'undefined' || key == '' || key == 'To Pack' ? "To Pack" : key + "'s Gear"}`}</h3>
                                    </Typography></AccordionSummary>
                      <AccordionDetails>

                              <TableContainer component={Paper} elevation={0}>
                              <div className="table-responsive-vertical-meals">
                              <Table className={"table-packing table-packing-gear" /* + index*/} size="small">
                                  <TableHead>
                                  <TableRow>
                                    <TableCell className="threehundred">
                                      CATEGORY / SUBCATEGORY
                                        </TableCell>
                                        { /* <TableCell>
                                      SUBCATEGORY
                                </TableCell> */ }
                                    <TableCell className="fourhundred">
                                      NAME
                                        </TableCell>
                                        <TableCell className="eighty">
                                          QUANTITY
                                        </TableCell>
                                        <TableCell>
                                          PACKING
                                        </TableCell>
                                  </TableRow>
                                </TableHead>
                                    <TableBody>
                                    {categorizedDatas2[key]?.items
    .sort(function (aa, ab) {

      var brand0A = aa.realcat.toUpperCase();
      var brand0B = ab.realcat.toUpperCase();
      if (brand0A !== brand0B) {
        // Sort by owner
        return brand0A < brand0B ? -1 : 1;
      }

      var brandA = aa.SubCategory.toUpperCase();
      var brandB = ab.SubCategory.toUpperCase();
      if (brandA !== brandB) {
        // Sort by owner
        return brandA < brandB ? -1 : 1;
      }
      
      var brandAA = aa.Brand.toUpperCase();
      var brandAB = ab.Brand.toUpperCase();
      if (brandAA !== brandAB) {
        // Sort by owner
        return brandAA < brandAB ? -1 : 1;
      }

      var nameA = aa.Name.toUpperCase();
      var nameB = ab.Name.toUpperCase();
      if (nameA !== nameB) {
        // Sort by owner
        return nameA < nameB ? -1 : 1;
      }

    })
      .map((item, index) => {
        const number = item.Quantity
        const getdear = listUser.filter(el => el.id == item.id ? true : false)
        const getdear2 = listUser.filter(el => el.id != item.id ? true : false)
        const categorymatch = Object.values(newata?.GearCategories).filter(chunk => chunk.id === item.CategoryId);
        return (
          <TableRow key={item.Name} id={item.id}>
            <TableCell data-title="MEAL - NAME" className="threehundred"><b>{categorymatch[0].label}</b> &raquo; {item.SubCategory ? item.SubCategory : '-'}</TableCell>
            <TableCell data-title="MEAL - NAMEEXPANDED"><span className="qtybold">{item.Quantity} x </span> {item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {item.Brand == "- NO BRAND" || item.Brand == "" || item.Brand == "-"  ? '-' : item.Brand} {item.Name}</TableCell>
            <TableCell data-title="MEAL - HIDDEN" className="eighty" style={{textTransform: 'uppercase'}}>
              <div>{item.Quantity}</div>
              </TableCell>
              {listUser.map(el => {
                const thisowner = item.Owner;
                                  if (el.id == item.id) {
                                    const getdear = listUser.filter(el => el.id == item.id ? true : false)
                                    const getdear2 = listUser.filter(el => el.id != item.id ? true : false)
                                    return (
                                      <>
                                      
                                          <TableCell>
                                        {el?.gearHiking?.map(it => {
                                          const numbers = it.id == item.id && it.Quantity;
                                          return (
                                            <>{item.Owner == it.Name ? <TableCell style={{display:'table', minWidth:'100%', border:'0'}}>

                                                <RadioGroup value={it.packingLocation ? it.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                                                  const val = e.target.value
                                                  const getd = getdear.map(els => {
                                                    const getsingledata = els.gearHiking.filter(elm => elm.Name == it.Name && elm.id == item.id ? true : false)
                                                    getsingledata[0].packingLocation = val;
                                                    return {
                                                      ...els,
                                                      packingLocation: val
                                                    }
                                                  })
                                                  const ar = [...getdear2, ...getd]
                                                  setListUser(ar)
                                                }}>
                                                  {country?.label == "HIKING" || country?.label == "BIKEPACKING" ?
                                                  <>
                                                 { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                                  <FormControlLabel value="Base Weight" control={<Radio color="primary" />} label="Base" />
                                                  { /* <FormControlLabel value="Consumables" control={<Radio color="primary" />} label="Consumables" /> */ }
                                                  <FormControlLabel value="Wear" control={<Radio color="primary" />} label="Wear" />
                                                  { /* <FormControlLabel value="Travel Bag" control={<Radio color="primary" />} label="Travel Bag" /> */ }
                                                  </>
                                                  :
                                                  <>
                                                  { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                                  {tr?.map((item, i) => {
                                            return (
                                              <>
                                              <div style={{flexBasis: '100%', height: '0'}}></div>
                                              <FormControlLabel key={i} value={"CARRY - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Carry On"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"BAG1 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 1"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"BAG2 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 2"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"BAG3 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 3"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"WEAR - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Wear"}
                                                  
                                              />
                                              { /* <FormControlLabel key={i} value={"BAG4 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 4"}
                                                  
                                              /> */ }
                                              <div style={{flexBasis: '100%', height: '0'}}></div>
                                              </>) } ) }
                                              {tr.length >= 1 ? null : <><em>You need to add Travellers to assign a packing location</em></>}
                                                  </>}
                                                </RadioGroup>
                                                </TableCell> : null}
                                            </>
                                          )
                                        })}
                                        </TableCell>
                                      </>
                                    )
                                  }
                                })}
              
              
          </TableRow>
        );
      })
  }
                                  </TableBody>
                                </Table>
                              </div>
                            </TableContainer></AccordionDetails></Accordion>
                            </div>
                          </>
                        )
                      })}
                      <div style={{display:'none'}}>
                    <table className="table-packing-pdf">
                    <thead>
                      <tr>
                          <th>Category</th>
                          <th>Name</th>
                          <th>Traveller</th>
                          <th>Quantity</th>
                          <th>Packing Location</th>
                          </tr>
                        </thead>
                        {
                        Object.keys(categorizedDatas2).map((key, index) => {
                        return (
                        categorizedDatas2[key]?.items.map((item, index) => {
                      return (
                      <tr><td>{item.Category} / {item.SubCategory}</td>
                      <td>{item.Brand == "- NO BRAND" || item.Brand == "" || item.Brand == "-"  ? '-' : item.Brand} {item.Name}</td>
                      <td>{item.Owner}</td>
                      <td>{item.Quantity}</td>
                      <td>{item.packingLocation ? item.packingLocation : '-'}</td>
                      </tr>
        )}))})}
        </table>
                  </div>
                    </>
                  ) : (
                      <>
                        {Object.keys(categorizedDatas).map((key, index) => {
                          return (
                            <>
                            <div className="packed_gear__" style={{order:getCategorySortPacked(key)}}>
                                  <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion-packing" style={{borderRadius:'0'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0.5px'}}
                      >
                      <Typography>
                                    <h3 style={{margin:'0',textTransform:'uppercase'}}>{`${key == 'undefined' || key == '' || key == 'To Pack' ? "To Pack" : getCategoryPacked(key)}`}</h3>
                                    </Typography></AccordionSummary>
                      <AccordionDetails>

<TableContainer component={Paper} elevation={0}>
                                <div className="table-responsive-vertical-meals">
                                <Table className="table-packing table-packing-gear" size="small">
                                      <TableHead>
                                      <TableRow>
                                        <TableCell className="twohundred">
                                          SUBCATEGORY
                                            </TableCell>
                                            { /* <TableCell>
                                          SUBCATEGORY
                                    </TableCell> */ }
                                        <TableCell>
                                          NAME
                                            </TableCell>
                                            <TableCell className="twohundred">
                                          OWNER
                                            </TableCell>
                                            { /* <TableCell className="eighty">
                                              QUANTITY
                                          </TableCell> */ }
                                            <TableCell>
                                              PACKING
                                            </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
    categorizedDatas[key]?.items
      .sort(function (aa, ab) {
               
        var scategoryA = aa.SubCategory.toUpperCase();
        var scategoryB = ab.SubCategory.toUpperCase();
        if (scategoryA !== scategoryB) {
          // Sort by category
          return scategoryA < scategoryB ? -1 : 1;
        }

        var brandA = aa.Brand.toUpperCase();
        var brandB = ab.Brand.toUpperCase();
        if (brandA !== brandB) {
          // Sort by owner
          return brandA < brandB ? -1 : 1;
        }
        
        var nameA = aa.Name.toUpperCase();
        var nameB = ab.Name.toUpperCase();
        if (nameA !== nameB) {
          // Sort by owner
          return nameA < nameB ? -1 : 1;
        }

        
      })
      
      .map((item, index) => {
        const number = item.Quantity
        const getdear = listUser.filter(el => el.id == item.id ? true : false)
        const getdear2 = listUser.filter(el => el.id != item.id ? true : false)
        return (
          <TableRow key={item.Name} id={item.id}>
            <TableCell data-title="MEAL - NAME" className="threehundred">{item.SubCategory}</TableCell>
            { /* <TableCell>{item.SubCategory}</TableCell> */ }
            <TableCell data-title="MEAL - NAMEEXPANDED" style={{fontWeight: 'normal'}}><span className="qtybold">{item.Quantity} x </span> {item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} <strong>{item.Brand == "- NO BRAND" || item.Brand == "" || item.Brand == "-"  ? '-' : item.Brand}</strong> {item.Name}</TableCell>
            <TableCell data-title="MEAL - OWNER" className="onehundredtwenty" style={{textTransform: 'uppercase'}}>
              {item.Owner}
            </TableCell>
            { /* <TableCell data-title="MEAL - HIDDEN" className="eighty" style={{textTransform: 'uppercase', fontWeight: 'normal'}}>
              <div>{item.Quantity}</div>
        </TableCell> */ }
              <TableCell>
              {country?.label == "OVERLANDING WITH TRAILER" ? (
              <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                const val = e.target.value
                const getd = getdear.map(els => {
                  return {
                    ...els,
                    packingLocation: val
                  }
                })
                const ar = [...getdear2, ...getd]
                setListUser(ar)
              }}>
                { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" />     */ }
                <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                <FormControlLabel value="Camper" control={<Radio color="primary" />} label="Camper" />
              </RadioGroup>
              ) : country?.label == "4x4" ? (
                <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                  const val = e.target.value
                  const getd = getdear.map(els => {
                    return {
                      ...els,
                      packingLocation: val
                    }
                  })
                  const ar = [...getdear2, ...getd]
                  setListUser(ar)
                }}>  
                { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" />     */ }                                     
                  <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
              </RadioGroup>
              ) : country?.label == "4x4 TOWING CARAVAN" ? (
                <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                  const val = e.target.value
                  const getd = getdear.map(els => {
                    return {
                      ...els,
                      packingLocation: val
                    }
                  })
                  const ar = [...getdear2, ...getd]
                  setListUser(ar)
                }}>  
                { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" />     */ }                                        
                  <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                  <FormControlLabel value="Caravan" control={<Radio color="primary" />} label="Caravan" />
              </RadioGroup>
              ) : country?.label == "4x4 TOWING BOAT" ? (
                <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                  const val = e.target.value
                  const getd = getdear.map(els => {
                    return {
                      ...els,
                      packingLocation: val
                    }
                  })
                  const ar = [...getdear2, ...getd]
                  setListUser(ar)
                }}>  
                { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" />     */ }                                        
                  <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                  <FormControlLabel value="Boat" control={<Radio color="primary" />} label="Boat" />
              </RadioGroup>
              ) : country?.label == "MOTORBIKE" ? (
                <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                  const val = e.target.value
                  const getd = getdear.map(els => {
                    return {
                      ...els,
                      packingLocation: val
                    }
                  })
                  const ar = [...getdear2, ...getd]
                  setListUser(ar)
                }}>  
                { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" />     */ }                                        
                  <FormControlLabel value="Motorbike" control={<Radio color="primary" />} label="Motorbike" />
              </RadioGroup>
              ) : <>n/a</>}
              </TableCell>
          </TableRow>
        );
      })
  }
                                        </TableBody>
                                      </Table>
                                    </div>
                                  </TableContainer></AccordionDetails></Accordion>
                                  </div>
                                </>
                              )
                            })}
                          </>
                        )}
</div>

<div style={{display:'none'}}>
                    <table className="table-packing-pdf">
                    <thead>
                      <tr>
                          <th>Category</th>
                          <th>Name</th>
                          <th>Owner</th>
                          <th>Quantity</th>
                          <th>Packing Location</th>
                          </tr>
                        </thead>
                        {
                        Object.keys(categorizedDatas).map((key, index) => {
                        return (
                        categorizedDatas[key]?.items.map((item, index) => {
                      return (
                      <tr><td>{item.Category} / {item.SubCategory}</td>
                      <td>{item.Brand == "- NO BRAND" || item.Brand == "" || item.Brand == "-"  ? '-' : item.Brand} {item.Name}</td>
                      <td>{item.Owner}</td>
                      <td>{item.Quantity}</td>
                      <td>{item.packingLocation ? item.packingLocation : '-'}</td>
                      </tr>
        )}))})}
        </table>
                  </div>
</> : <><div style={{ clear: 'both', height: '20px' }}></div>Your list is currently empty.<div style={{ clear: 'both', height: '40px' }}></div></> }
                    </>
                  )}
                </div>  
                {packeditem == true ? 
                <><br/>
                  <Button variant="outlined" onClick={savePDF}>Save as PDF</Button></> : null}
                <br /><br />
             </>
            )}
        {selectedTab === 3 && (
          <>
          <div className="select4filter tripbuttons" style={{height:'auto', marginBottom:'20px !important'}}>
                <Button onClick={() => setSelectedTab(4)} className="btntripw" variant="outlined" size="large"><FontAwesomeIcon icon={faWeightScale} size="sm" /> {getFoodweight.toFixed(2)}{getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}</Button>
            </div>
                {packeditem2 == false ? (
<> { /*
<Select menuPortalTarget={document.body} isSearchable={ false } fullWidth placeholder="Category" value={selectFoodCategory}
              options={filteredFood}
              onChange={(e) => handleFoodChange(e)}
              getOptionLabel={x => x.Category}
              getOptionValue={x => x.Category}
              className="select select4filter selectalternate"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#f1f1f1',
                  primary: '#202020',
                },
              })}
              components={{
                ValueContainer: CustomValueContainer
              }}
              styles={{
                container: (provided, state) => ({
                  ...provided,
                  marginTop: 50,
                  paddingLeft: "11px"
                }),
                valueContainer: (provided, state) => ({
                  ...provided,
                  overflow: "visible",
                  borderColor: "#fff !important",
                  padding: "0 !important"
                }),
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "#fff !important",
                  boxShadow: "none"
                }),
                placeholder: (provided, state) => ({
                  ...provided,
                  position: "absolute",
                  top: state.hasValue || state.selectProps.inputValue ? -13 : "50%",
                  left: "-4px",
                  background: "#fff",
                  padding: "0 5px",
                  transition: "top 0.5s, font-size 0.1s",
                  color: "#A2A2A2",
                  fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                })
              }}
            /> */ }
            { /* <Select menuPortalTarget={document.body} fullWidth placeholder="Status Packed/Bought" value={FoodStatusCheck}
              options={stat}
              onChange={(e) => setFoodStatusCheck(e)}
              getOptionLabel={x => x.status}
              getOptionValue={x => x.status}
              className="select select4filter"
              components={{
                ValueContainer: CustomValueContainer
              }}
              styles={{
                container: (provided, state) => ({
                  ...provided,
                  marginTop: 50,
                  paddingLeft: "11px"
                }),
                valueContainer: (provided, state) => ({
                  ...provided,
                  overflow: "visible",
                  borderColor: "#fff !important",
                  padding: "0 !important"
                }),
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "#fff !important",
                  boxShadow: "none"
                }),
                placeholder: (provided, state) => ({
                  ...provided,
                  position: "absolute",
                  top: state.hasValue || state.selectProps.inputValue ? -13 : "50%",
                  left: "-4px",
                  background: "#fff",
                  padding: "0 5px",
                  transition: "top 0.5s, font-size 0.1s",
                  color: "#A2A2A2",
                  fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                })
              }}
            /> */ }
            <div style={{clear: 'both'}}></div>
            {country?.label == "AIR +" || country?.label == "OVERLANDING WITH TRAILER" || country?.label == "HIKING" || country?.label == "BIKEPACKING" || country?.label == "4x4 TOWING CARAVAN" || country?.label == "4x4 TOWING BOAT" ? (
              <>
                { /* <Select menuPortalTarget={document.body} placeholder="Packing Location" value={Foodpacking}
                  options={slectdata}
                  onChange={(e) => setfoodpacking(e)}
                  getOptionLabel={x => x.status}
                  getOptionValue={x => x.status}
                  className="select select4filter select4filterover"
                  components={{
                    ValueContainer: CustomValueContainer
                  }}
                  styles={{
                    container: (provided, state) => ({
                      ...provided,
                      marginTop: 50,
                      paddingLeft: "11px"
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      overflow: "visible",
                      borderColor: "#fff !important",
                      padding: "0 !important"
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: "#fff !important",
                      boxShadow: "none"
                    }),
                    placeholder: (provided, state) => ({
                      ...provided,
                      position: "absolute",
                      top: state.hasValue || state.selectProps.inputValue ? -13 : "50%",
                      left: "-4px",
                      background: "#fff",
                      padding: "0 5px",
                      transition: "top 0.5s, font-size 0.1s",
                      color: "#A2A2A2",
                      fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                    })
                  }}
                /> */ }
              </>
            ) : null}
              {checkboxFoodFunctionRender()}
            <br /><br /></>
                ) : (
                  <>{listUserF.length >= 1 ? 
                    <><div style={{ clear: 'both' }}></div>
                    {food_sorted.map((key, index) => {
                          return (
                            <>
                            <div className="packed_gear__">
                            {categorizedDatasFood[key]?.items.length >= 1 ?
                            <>
                            <Accordion style={{borderRadius:'0'}} elevation={1} defaultExpanded={false} className="filter-items-accordion-packing">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0.5px'}}
                      >
                      <Typography>
                                    <h3 style={{margin:'0',textTransform:'uppercase'}}>{`${key == 'undefined' || key == '' || key == 'To Pack' ? "To Pack" : key}`}</h3>
                                    </Typography></AccordionSummary>
                      <AccordionDetails>
                      <TableContainer component={Paper} elevation={0}>
                            <div className="table-responsive-vertical-meals">
                            <Table className="table-packing" size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell className="fourhundred"><TableSortLabel>
                                  INGREDIENT
                                </TableSortLabel></TableCell>
                                { /* <TableCell className="onehundredtwenty"><TableSortLabel>
                                  QUANTITY
                            </TableSortLabel></TableCell> */ }
                                <TableCell className="twohundred"><TableSortLabel>
                                  SHOPPING
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel>
                                  PACKING
                                </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {categorizedDatasFood[key]?.items
    .sort(function (aa, ab) {
        
      var categoryA = aa.Category.toUpperCase();
      var categoryB = ab.Category.toUpperCase();
      if (categoryA !== categoryB) {
        // Sort by category
        return categoryA < categoryB ? -1 : 1;
      }
           
      var nameA = aa.Name.toUpperCase();
      var nameB = ab.Name.toUpperCase();
      if (nameA !== nameB) {
        // Sort by owner
        return nameA < nameB ? -1 : 1;
      }

    })
      .map((item, index) => {
        return ( 
          <TableRow key={item.Name} id={item.id}>
            <TableCell data-title='MEAL - NAME'><span className="qtybold">{item.Quantity} x </span> {item.Name}</TableCell>
            { /* <TableCell className="eighty" data-title='MEAL - QUANTITY' style={{textTransform: 'uppercase', textAlign: 'center'}}><div>{item.Quantity ? item.Quantity : '1'}</div></TableCell> */ }
            <TableCell className="onehundredtwenty" data-title="MEAL - SHOPPING">
                                    <FormControlLabel style={{display: 'inline-block', width: 'auto', pointerEvents: 'none'}} className="avoid sub-select-options"
                                      control={
                                        <Checkbox checked={item.bought ? true : false}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          const filtear = listUserF.filter(el => el.id == item.id ? true : false)
                                          const filter = listUserF.filter(el => el.id != item.id ? true : false)
                                          const nfilter = listUserF.filter(v => v.id == item.id)
                                          let awa;
                                          nfilter.map(el => {
                                            awa = el
                                          })
                                          if (filtear.length) {
                                            setListUserF((state) => [...filter, { id: item.id, ...awa, bought: true }]);
                                          } else {
                                            setListUserF((state) => [...filter, { id: item.id, ...item, bought: true }]);
                                          }
                                        }
                                        else {
                                          const filtersaa = listUserF.filter(el => el.id == item.id && el.bought == true ? true : false)
                                          if (filtersaa.length) {
                                            const filla = listUserF.filter(v => v.id !== item.id)
                                            const nfilter = listUserF.filter(v => v.id == item.id)
                                            let awa;
                                            nfilter.map(el => {
                                              awa = el
                                            })
                                            setListUserF([...filla, { id: item.id, ...awa, bought: false }])
                                          } else {
                                            const filla = listUserF.filter(v => v.id !== item.id)
                                            const nfilter = listUserF.filter(v => v.id == item.id)
                                            let awa;
                                            nfilter.map(el => {
                                              awa = el
                                            })
                                            setListUserF([...filla, { id: item.id, ...awa, bought: false }])
                                          }
                                        }
                                      }}
                                    />

                                      }
                                      label={<span>Bought</span>}
                                    />
                                  </TableCell>
            <TableCell data-title="MEAL - PACKING">
                                    {country?.label == "OVERLANDING WITH TRAILER" ? (
                                      <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row 
                                      onChange={(e) => {
                                        const val = e.target.value;
                                        if (e.target.checked) {
                                          const filtear = listUserF.filter(el => el.id == item.id ? true : false)
                                          const filter = listUserF.filter(el => el.id != item.id ? true : false)
                                          const nfilter = listUserF.filter(v => v.id == item.id)
                                          let awa;
                                          nfilter.map(el => {
                                            awa = el
                                          })
                                          if (filtear.length) {
                                            setListUserF((state) => [...filter, { id: item.id, ...awa, packingLocation: val }]);
                                          } else {
                                            setListUserF((state) => [...filter, { id: item.id, ...item, packingLocation: val }]);
                                          }
                                        }
                                        else {
                                          const filtersaa = listUserF.filter(el => el.id == item.id && el.bought == true && el.packed == true ? true : false)
                                          if (filtersaa.length) {
                                            const filla = listUserF.filter(v => v.id !== item.id)
                                            const nfilter = listUserF.filter(v => v.id == item.id)
                                            let awa;
                                            nfilter.map(el => {
                                              awa = el
                                            })
                                            setListUserF([...filla, { id: item.id, ...awa, bought: false }])
                                          } else {
                                            const filla = listUserF.filter(v => v.id !== item.id)
                                            const nfilter = listUserF.filter(v => v.id == item.id)
                                            let awa;
                                            nfilter.map(el => {
                                              awa = el
                                            })
                                            setListUserF([...filla, { id: item.id, ...awa, bought: false }])
                                          }
                                        }
                                      }}>
                                        { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                        <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                        <FormControlLabel value="Camper" control={<Radio color="primary" />} label="Camper" />
                                      </RadioGroup>
                                    ) : country?.label == "4x4 TOWING CARAVAN" ? (
                                      <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row
                                      onChange={(e) => {
                                        const val = e.target.value;
                                        if (e.target.checked) {
                                          const filtear = listUserF.filter(el => el.id == item.id ? true : false)
                                          const filter = listUserF.filter(el => el.id != item.id ? true : false)
                                          const nfilter = listUserF.filter(v => v.id == item.id)
                                          let awa;
                                          nfilter.map(el => {
                                            awa = el
                                          })
                                          if (filtear.length) {
                                            setListUserF((state) => [...filter, { id: item.id, ...awa, packingLocation: val }]);
                                          } else {
                                            setListUserF((state) => [...filter, { id: item.id, ...item, packingLocation: val }]);
                                          }
                                        }
                                        else {
                                          const filtersaa = listUserF.filter(el => el.id == item.id && el.bought == true && el.packed == true ? true : false)
                                          if (filtersaa.length) {
                                            const filla = listUserF.filter(v => v.id !== item.id)
                                            const nfilter = listUserF.filter(v => v.id == item.id)
                                            let awa;
                                            nfilter.map(el => {
                                              awa = el
                                            })
                                            setListUserF([...filla, { id: item.id, ...awa, bought: false }])
                                          } else {
                                            const filla = listUserF.filter(v => v.id !== item.id)
                                            const nfilter = listUserF.filter(v => v.id == item.id)
                                            let awa;
                                            nfilter.map(el => {
                                              awa = el
                                            })
                                            setListUserF([...filla, { id: item.id, ...awa, bought: false }])
                                          }
                                        }
                                      }}>
                                        { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                        <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                        <FormControlLabel value="Caravan" control={<Radio color="primary" />} label="Caravan" />
                                      </RadioGroup>
                                    ) : country?.label == "4x4 TOWING BOAT" ? (
                                      <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row
                                      onChange={(e) => {
                                        const val = e.target.value;
                                        if (e.target.checked) {
                                          const filtear = listUserF.filter(el => el.id == item.id ? true : false)
                                          const filter = listUserF.filter(el => el.id != item.id ? true : false)
                                          const nfilter = listUserF.filter(v => v.id == item.id)
                                          let awa;
                                          nfilter.map(el => {
                                            awa = el
                                          })
                                          if (filtear.length) {
                                            setListUserF((state) => [...filter, { id: item.id, ...awa, packingLocation: val }]);
                                          } else {
                                            setListUserF((state) => [...filter, { id: item.id, ...item, packingLocation: val }]);
                                          }
                                        }
                                        else {
                                          const filtersaa = listUserF.filter(el => el.id == item.id && el.bought == true && el.packed == true ? true : false)
                                          if (filtersaa.length) {
                                            const filla = listUserF.filter(v => v.id !== item.id)
                                            const nfilter = listUserF.filter(v => v.id == item.id)
                                            let awa;
                                            nfilter.map(el => {
                                              awa = el
                                            })
                                            setListUserF([...filla, { id: item.id, ...awa, bought: false }])
                                          } else {
                                            const filla = listUserF.filter(v => v.id !== item.id)
                                            const nfilter = listUserF.filter(v => v.id == item.id)
                                            let awa;
                                            nfilter.map(el => {
                                              awa = el
                                            })
                                            setListUserF([...filla, { id: item.id, ...awa, bought: false }])
                                          }
                                        }
                                      }}>
                                        { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                        <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                        <FormControlLabel value="Boat" control={<Radio color="primary" />} label="Boat" />
                                      </RadioGroup>
                                    ) : country?.label == "4x4" ? (
                                      <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row
                                      onChange={(e) => {
                                        const val = e.target.value;
                                        if (e.target.checked) {
                                          const filtear = listUserF.filter(el => el.id == item.id ? true : false)
                                          const filter = listUserF.filter(el => el.id != item.id ? true : false)
                                          const nfilter = listUserF.filter(v => v.id == item.id)
                                          let awa;
                                          nfilter.map(el => {
                                            awa = el
                                          })
                                          if (filtear.length) {
                                            setListUserF((state) => [...filter, { id: item.id, ...awa, packingLocation: val }]);
                                          } else {
                                            setListUserF((state) => [...filter, { id: item.id, ...item, packingLocation: val }]);
                                          }
                                        }
                                        else {
                                          const filtersaa = listUserF.filter(el => el.id == item.id && el.bought == true && el.packed == true ? true : false)
                                          if (filtersaa.length) {
                                            const filla = listUserF.filter(v => v.id !== item.id)
                                            const nfilter = listUserF.filter(v => v.id == item.id)
                                            let awa;
                                            nfilter.map(el => {
                                              awa = el
                                            })
                                            setListUserF([...filla, { id: item.id, ...awa, bought: false }])
                                          } else {
                                            const filla = listUserF.filter(v => v.id !== item.id)
                                            const nfilter = listUserF.filter(v => v.id == item.id)
                                            let awa;
                                            nfilter.map(el => {
                                              awa = el
                                            })
                                            setListUserF([...filla, { id: item.id, ...awa, bought: false }])
                                          }
                                        }
                                      }}>
                                        { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                        <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                      </RadioGroup>
                                    ) : country?.label == "MOTORBIKE" ? (
                                      <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row
                                      onChange={(e) => {
                                        const val = e.target.value;
                                        if (e.target.checked) {
                                          const filtear = listUserF.filter(el => el.id == item.id ? true : false)
                                          const filter = listUserF.filter(el => el.id != item.id ? true : false)
                                          const nfilter = listUserF.filter(v => v.id == item.id)
                                          let awa;
                                          nfilter.map(el => {
                                            awa = el
                                          })
                                          if (filtear.length) {
                                            setListUserF((state) => [...filter, { id: item.id, ...awa, packingLocation: val }]);
                                          } else {
                                            setListUserF((state) => [...filter, { id: item.id, ...item, packingLocation: val }]);
                                          }
                                        }
                                        else {
                                          const filtersaa = listUserF.filter(el => el.id == item.id && el.bought == true && el.packed == true ? true : false)
                                          if (filtersaa.length) {
                                            const filla = listUserF.filter(v => v.id !== item.id)
                                            const nfilter = listUserF.filter(v => v.id == item.id)
                                            let awa;
                                            nfilter.map(el => {
                                              awa = el
                                            })
                                            setListUserF([...filla, { id: item.id, ...awa, bought: false }])
                                          } else {
                                            const filla = listUserF.filter(v => v.id !== item.id)
                                            const nfilter = listUserF.filter(v => v.id == item.id)
                                            let awa;
                                            nfilter.map(el => {
                                              awa = el
                                            })
                                            setListUserF([...filla, { id: item.id, ...awa, bought: false }])
                                          }
                                        }
                                      }}>
                                        { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                        <FormControlLabel value="Motorbike" control={<Radio color="primary" />} label="Motorbike" />
                                      </RadioGroup>
                                    ) : country?.label == "HIKING" || country?.label == "BIKEPACKING" ? (
                                      <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row
                                      onChange={(e) => {
                                        const val = e.target.value;
                                        if (e.target.checked) {
                                          const filtear = listUserF.filter(el => el.id == item.id ? true : false)
                                          const filter = listUserF.filter(el => el.id != item.id ? true : false)
                                          const nfilter = listUserF.filter(v => v.id == item.id)
                                          let awa;
                                          nfilter.map(el => {
                                            awa = el
                                          })
                                          if (filtear.length) {
                                            setListUserF((state) => [...filter, { id: item.id, ...awa, packingLocation: val }]);
                                          } else {
                                            setListUserF((state) => [...filter, { id: item.id, ...item, packingLocation: val }]);
                                          }
                                        }
                                        else {
                                          const filtersaa = listUserF.filter(el => el.id == item.id && el.bought == true && el.packed == true ? true : false)
                                          if (filtersaa.length) {
                                            const filla = listUserF.filter(v => v.id !== item.id)
                                            const nfilter = listUserF.filter(v => v.id == item.id)
                                            let awa;
                                            nfilter.map(el => {
                                              awa = el
                                            })
                                            setListUserF([...filla, { id: item.id, ...awa, bought: false }])
                                          } else {
                                            const filla = listUserF.filter(v => v.id !== item.id)
                                            const nfilter = listUserF.filter(v => v.id == item.id)
                                            let awa;
                                            nfilter.map(el => {
                                              awa = el
                                            })
                                            setListUserF([...filla, { id: item.id, ...awa, bought: false }])
                                          }
                                        }
                                      }}>
                                        { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                        {tr?.map((item, i) => {
                                            return (
                                              <>
                                              
                                              <FormControlLabel key={i} value={"PACK - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name}
                                                  
                                              />
                                              </>
                                            )
                                          })}
                                          {tr.length >= 1 ? null : <><em>You need to add Travellers to assign a packing location</em></>}
                                      </RadioGroup>
                                    ) : country?.label == "AIR +" ? (
                                      <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row
                                      onChange={(e) => {
                                        const val = e.target.value;
                                        if (e.target.checked) {
                                          const filtear = listUserF.filter(el => el.id == item.id ? true : false)
                                          const filter = listUserF.filter(el => el.id != item.id ? true : false)
                                          const nfilter = listUserF.filter(v => v.id == item.id)
                                          let awa;
                                          nfilter.map(el => {
                                            awa = el
                                          })
                                          if (filtear.length) {
                                            setListUserF((state) => [...filter, { id: item.id, ...awa, packingLocation: val }]);
                                          } else {
                                            setListUserF((state) => [...filter, { id: item.id, ...item, packingLocation: val }]);
                                          }
                                        }
                                        else {
                                          const filtersaa = listUserF.filter(el => el.id == item.id && el.bought == true && el.packed == true ? true : false)
                                          if (filtersaa.length) {
                                            const filla = listUserF.filter(v => v.id !== item.id)
                                            const nfilter = listUserF.filter(v => v.id == item.id)
                                            let awa;
                                            nfilter.map(el => {
                                              awa = el
                                            })
                                            setListUserF([...filla, { id: item.id, ...awa, bought: false }])
                                          } else {
                                            const filla = listUserF.filter(v => v.id !== item.id)
                                            const nfilter = listUserF.filter(v => v.id == item.id)
                                            let awa;
                                            nfilter.map(el => {
                                              awa = el
                                            })
                                            setListUserF([...filla, { id: item.id, ...awa, bought: false }])
                                          }
                                        }
                                      }}>
                                        { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                        {tr?.map((item, i) => {
                                            return (
                                              <>
                                              <div style={{flexBasis: '100%', height: '0'}}></div>
                                              <FormControlLabel key={i} value={"CARRY - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Carry On"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"BAG1 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 1"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"BAG2 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 2"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"BAG3 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 3"}
                                                  
                                              />
                                              { /* <FormControlLabel key={i} value={"BAG4 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 4"}
                                                  
                                              /> */ }
                                              <div style={{flexBasis: '100%', height: '0'}}></div>
                                              </>
                                            )
                                          })}
                                          {tr.length >= 1 ? null : <><em>You need to add Travellers to assign a packing location</em></>}
                                      </RadioGroup>
                                    ) : <>n/a</>}
                                  </TableCell>
          </TableRow>
        )
      })}
                            </TableBody>  
                            </Table> 
                            </div> 
                            </TableContainer></AccordionDetails></Accordion></> : null}
                          </div></>)})}
<br/><br/>
                                    <div style={{display:'none'}}>
                    <table className="table-packing-pdf">
                    <thead>
                      <tr>
                          <th>Category</th>
                          <th>Ingredient</th>
                          <th>Quantity</th>
                          <th>Shopping</th>
                          <th>Packing Location</th>
                          </tr>
                        </thead>
                        {
                                      Object.keys(categorizedDatasFood).map((key, index) => {
                                        return (
    categorizedDatasFood[key]?.items.sort(function (aa, ab) {
        
      var categoryA = aa.Category.toUpperCase();
      var categoryB = ab.Category.toUpperCase();
      if (categoryA !== categoryB) {
        // Sort by category
        return categoryA < categoryB ? -1 : 1;
      }
           
      var nameA = aa.Name.toUpperCase();
      var nameB = ab.Name.toUpperCase();
      if (nameA !== nameB) {
        // Sort by owner
        return nameA < nameB ? -1 : 1;
      }

    }).map((item, index) => {
        return (
                      <tr><td>{item.Category}</td><td>{item.Name}</td><td>{item.Quantity}</td><td>{item.bought == true ? 'Bought' : '-'}</td><td>
                    {item.packingLocation ? item.packingLocation : '-'}
                    </td></tr>
        )}))})}</table>
                  </div>
                  <Button variant="outlined" onClick={savePDF}>Save as PDF</Button><br/><br/>
                </>
                : <><div style={{ clear: 'both', height: '20px' }}></div>Your list is currently empty.<div style={{ clear: 'both', height: '40px' }}></div></>}</>)}
                
              </>
            )}
        {selectedTab === 99 && (
          <>
          <h4>MEAL PLAN <span className="h4weights"><FontAwesomeIcon icon={faWeightScale} size="sm" /> {n.toFixed(2)}{getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}</span></h4>
              <div className="select4filter" style={{height:'auto', marginBottom:'20px !important'}}>
                <Button className={(packeditem3 == true) ? "btntoggle" : "btntoggle btntoggled"} variant="outlined" size="large" onClick={e => setpackeditem3(false)}><FontAwesomeIcon icon={faPencil} size="lg" /> PLANNING</Button> <Button className={(packeditem3 == false) ? "btntoggle" : "btntoggle btntoggled"} variant="outlined" size="large" onClick={e => setpackeditem3(true)}><FontAwesomeIcon icon={faListCheck} size="lg" /> PACKING</Button>
                </div>
                { /* <Select menuPortalTarget={document.body} placeholder="Category" value={selectMealCategory}
                  options={filteredMeal}
                  onChange={handleMealChange}
                  getOptionLabel={x => x.Category}
                  getOptionValue={x => x.Category}
                  className="select select4filter"
                  components={{
                    ValueContainer: CustomValueContainer
                  }}
                  styles={{
                    container: (provided, state) => ({
                      ...provided,
                      marginTop: 50,
                      paddingLeft: "11px"
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      overflow: "visible",
                      borderColor: "#fff !important",
                      padding: "0 !important"
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: "#fff !important",
                      boxShadow: "none"
                    }),
                    placeholder: (provided, state) => ({
                      ...provided,
                      position: "absolute",
                      top: state.hasValue || state.selectProps.inputValue ? -13 : "50%",
                      left: "-4px",
                      background: "#fff",
                      padding: "0 5px",
                      transition: "top 0.5s, font-size 0.1s",
                      color: "#A2A2A2",
                      fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                    })
                  }}
                /> */ }
                <div style={{clear:'both'}}></div>
                {packeditem3 == false ? (
                  <>
            {country?.label == "AIR +" || country?.label == "OVERLANDING WITH TRAILER" || country?.label == "HIKING" || country?.label == "BIKEPACKING" || country?.label == "4x4 TOWING CARAVAN" || country?.label == "4x4 TOWING BOAT" ? (
              <>
                { /* <Select menuPortalTarget={document.body} placeholder="Packing Location" value={Foodpackingss}
                  options={slectdata}
                  onChange={(e) => setfoodpackingss(e)}
                  getOptionLabel={x => x.status}
                  getOptionValue={x => x.status}
                  className="select select4filter select4filterover"
                  components={{
                    ValueContainer: CustomValueContainer
                  }}
                  styles={{
                    container: (provided, state) => ({
                      ...provided,
                      marginTop: 50,
                      paddingLeft: "11px"
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      overflow: "visible",
                      borderColor: "#fff !important",
                      padding: "0 !important"
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: "#fff !important",
                      boxShadow: "none"
                    }),
                    placeholder: (provided, state) => ({
                      ...provided,
                      position: "absolute",
                      top: state.hasValue || state.selectProps.inputValue ? -13 : "50%",
                      left: "-4px",
                      background: "#fff",
                      padding: "0 5px",
                      transition: "top 0.5s, font-size 0.1s",
                      color: "#A2A2A2",
                      fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                    })
                  }}
                /> */ }
                </>
            ) : null}
                  {checkboxMealFunctionRender()}
                <br /><br />
                  </>
                ) : (
                  <>
                  {country?.label == "OLD AIR +" ? (
              <>
                {arja?.length ? (
                  <TableContainer component={Paper} elevation={0}>
                    <div className="table-responsive-vertical-meals">
                    <Table className="table-packing" size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell><TableSortLabel>
                                  INGREDIENT
                                </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel>
                                  QUANTITY
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel>
                                  SHOPPING
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel>
                                  PACKING
                                </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>
                        {arja?.map((item, i) => {
                          let it = item.Quantity
                          const anyfil = item.Foods;
                          const base = item.Foods.filter(el => el.packingLocation == Foodpackingss.status ? true : false)
                          const newawdadawrr = Foodpackingss.status == "ALL" ? anyfil : base
                          return (
                            <>
                              {newawdadawrr?.map(el => (
                                <TableBody key={i}>
                                  <TableRow hover key={item.label} id={item.label}>
                                    <TableCell data-title="MEAL - NAME">
                                      <div>
                                        <b>{item.Name}</b><br/>
                                        <span>{el.label}</span>
                                      </div>
                                    </TableCell>
                                    <TableCell data-title="MEAL - QUANTITY">
                                    <div>{el.Quantity ? Number(el.Quantity).toFixed(0) : '1'}</div>
                                    </TableCell>
                                    <TableCell data-title="MEAL - SHOPPING">
                                      <FormControlLabel className="avoid"
                                        control={
                                          <Checkbox
                                            checked={el.bought == true ? true : false}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                const newdataswa = listUserM.map(o => {
                                                  if (o.id == item.id) {
                                                    return {
                                                      ...o,
                                                      mealHiking: o.mealHiking.map(es => {
                                                        if (es.Name == item.Name) {
                                                          return {
                                                            ...es,
                                                            Foods: es.Foods.map(ea => {
                                                              if (ea.label == el.label) {
                                                                return {
                                                                  ...ea,
                                                                  bought: true
                                                                }
                                                              } else {
                                                                return {
                                                                  ...ea
                                                                }
                                                              }
                                                            })
                                                          }
                                                        } else {
                                                          return {
                                                            ...es
                                                          }
                                                        }
                                                      })
                                                    }
                                                  } else {
                                                    return {
                                                      ...o
                                                    }
                                                  }
                                                })
                                                setListUserM(newdataswa)
                                              } else {
                                                const newdataswa = listUserM.map(o => {
                                                  if (o.id == item.id) {
                                                    return {
                                                      ...o,
                                                      mealHiking: o.mealHiking.map(es => {
                                                        if (es.Name == item.Name) {
                                                          return {
                                                            ...es,
                                                            Foods: es.Foods.map(ea => {
                                                              if (ea.label == el.label) {
                                                                return {
                                                                  ...ea,
                                                                  bought: false
                                                                }
                                                              } else {
                                                                return {
                                                                  ...ea
                                                                }
                                                              }
                                                            })
                                                          }
                                                        } else {
                                                          return {
                                                            ...es
                                                          }
                                                        }
                                                      })
                                                    }
                                                  } else {
                                                    return {
                                                      ...o
                                                    }
                                                  }
                                                })
                                                setListUserM(newdataswa)
                                              }
                                            }}
                                          />
                                        }
                                        label={<span>Bought</span>}
                                      />
                                      { /* <FormControlLabel className="avoid"
                                        control={
                                          <Checkbox
                                            checked={el.packed == true ? true : false}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                const newdataswa = listUserM.map(o => {
                                                  if (o.id == item.id) {
                                                    return {
                                                      ...o,
                                                      mealHiking: o.mealHiking.map(es => {
                                                        if (es.Name == item.Name) {
                                                          return {
                                                            ...es,
                                                            Foods: es.Foods.map(ea => {
                                                              if (ea.label == el.label) {
                                                                return {
                                                                  ...ea,
                                                                  packed: true
                                                                }
                                                              } else {
                                                                return {
                                                                  ...ea
                                                                }
                                                              }
                                                            })
                                                          }
                                                        } else {
                                                          return {
                                                            ...es
                                                          }
                                                        }
                                                      })
                                                    }
                                                  } else {
                                                    return {
                                                      ...o
                                                    }
                                                  }
                                                })
                                                setListUserM(newdataswa)
                                              } else {
                                                const newdataswa = listUserM.map(o => {
                                                  if (o.id == item.id) {
                                                    return {
                                                      ...o,
                                                      mealHiking: o.mealHiking.map(es => {
                                                        if (es.Name == item.Name) {
                                                          return {
                                                            ...es,
                                                            Foods: es.Foods.map(ea => {
                                                              if (ea.label == el.label) {
                                                                return {
                                                                  ...ea,
                                                                  packed: false
                                                                }
                                                              } else {
                                                                return {
                                                                  ...ea
                                                                }
                                                              }
                                                            })
                                                          }
                                                        } else {
                                                          return {
                                                            ...es
                                                          }
                                                        }
                                                      })
                                                    }
                                                  } else {
                                                    return {
                                                      ...o
                                                    }
                                                  }
                                                })
                                                setListUserM(newdataswa)
                                              }
                                            }}
                                          />
                                        }
                                        label={<span>Pack</span>}
                                      /> */ }
                                    </TableCell>
                                    <TableCell data-title="MEAL - PACKING">
                                      {country?.label == "HIKING" || country?.label == "BIKEPACKING" ? (
                                        <RadioGroup value={el.packingLocation} row aria-label="position" name="position" defaultValue="top" onChange={e => {
                                          const val = e.target.value
                                          const newdataswa = listUserM.map(o => {
                                            if (o.id == item.id) {
                                              return {
                                                ...o,
                                                mealHiking: o.mealHiking.map(es => {
                                                  if (es.Name == item.Name) {
                                                    return {
                                                      ...es,
                                                      Foods: es.Foods.map(ea => {
                                                        if (ea.label == el.label) {
                                                          return {
                                                            ...ea,
                                                            packingLocation: val
                                                          }
                                                        } else {
                                                          return {
                                                            ...ea
                                                          }
                                                        }
                                                      })
                                                    }
                                                  } else {
                                                    return {
                                                      ...es
                                                    }
                                                  }
                                                })
                                              }
                                            } else {
                                              return {
                                                ...o
                                              }
                                            }
                                          })
                                          setListUserM(newdataswa)
                                        }}>

                                          {tr?.map((item, i) => {
                                      const checka = el?.mealHiking?.filter(a => a.Name == item.Name && a.id == checkbox.id ? true : false)
                                      return (
                                        <FormControlLabel key={i} value={"PACK - " + item.Name}
                                          control={
                                            <Radio
                                              key={`cb-${i * 6 * 6 * 6}`}
                                              name={(item.Name) + ' ' + (item.Weight)}
                                              color="primary"
                                            />
                                          }
                                          label={item.Name}
                                            
                                        />
                                      )
                                      
                                    })}
                                        </RadioGroup>
                                      ) : (
                                          <RadioGroup value={el.packingLocation ? el.packingLocation : "To Pack"} defaultValue="To Pack" onChange={e => {
                                            const val = e.target.value
                                            const newdataswa = listUserM.map(o => {
                                              if (o.id == item.id) {
                                                return {
                                                  ...o,
                                                  mealHiking: o.mealHiking.map(es => {
                                                    if (es.Name == item.Name) {
                                                      return {
                                                        ...es,
                                                        Foods: es.Foods.map(ea => {
                                                          if (ea.label == el.label) {
                                                            return {
                                                              ...ea,
                                                              packingLocation: val
                                                            }
                                                          } else {
                                                            return {
                                                              ...ea
                                                            }
                                                          }
                                                        })
                                                      }
                                                    } else {
                                                      return {
                                                        ...es
                                                      }
                                                    }
                                                  })
                                                }
                                              } else {
                                                return {
                                                  ...o
                                                }
                                              }
                                            })
                                            setListUserM(newdataswa)
                                          }}>
                                            { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                            <FormControlLabel value="Carry On" control={<Radio color="primary" />} label="Carry On" />
                                            <FormControlLabel value="Bag 1" control={<Radio color="primary" />} label="Bag 1" />
                                            <FormControlLabel value="Bag 2" control={<Radio color="primary" />} label="Bag 2" />
                                            <FormControlLabel value="Bag 3" control={<Radio color="primary" />} label="Bag 3" />
                                            { /* <FormControlLabel value="Bag 4" control={<Radio color="primary" />} label="Packed Bag 4" /> */ }
                                          </RadioGroup>
                                        )}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              ))}
                            </>
                          )
                        })}
                      </Table>
                    </div>
                  </TableContainer>
                ) : null}
              <br/><br/>
              </>
            ) : null}
            {country?.label != "" ? (
              <>



              
               {meal_sorted.map((key, index) => {
                          return (
                            <>
                            {categorizedDataFoods12[key]?.items.length >= 1 ?
                            <>
                            <Accordion style={{borderRadius:'0'}} elevation={1} defaultExpanded={false} className="filter-items-accordion-packing">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0.5px'}}
                      >
                      <Typography>
                                    <h3 style={{margin:'0',textTransform:'uppercase'}}>{`${key == 'undefined' || key == '' || key == 'To Pack' ? "To Pack" : key}`}</h3>
                                    </Typography></AccordionSummary>
                      <AccordionDetails>
                      <TableContainer component={Paper} elevation={0}>
                    <div className="table-responsive-vertical-meals">
                    <Table className="table-packing" size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell className="fourhundred"><TableSortLabel>
                                  INGREDIENT
                                </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel>
                                  QUANTITY
                                </TableSortLabel></TableCell>
                                <TableCell className="twohundred"><TableSortLabel>
                                  SHOPPING
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel>
                                  PACKING
                                </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>
                            {categorizedDataFoods12[key]?.items
    .map((d, index) => {
      return ( <>
        <TableBody>
        <TableRow>
        
        <TableCell data-title="MEAL - NAME">
        {d.label}
        </TableCell>
        <TableCell className="eighty" data-title="MEAL - QUANTITY">
        <div>{Number(d.inputQuantity * d.Quantity)}</div>
        </TableCell>
        <TableCell className="onehundredtwenty" data-title="MEAL - SHOPPING">
        <FormControlLabel className="avoid"
        control={
          <Checkbox
            checked={d.bought == true ? true : false}
            onChange={(e) => UpdateCHeckboxboght(e, d)}
          />
        }
        label={<span>Bought</span>}
      /></TableCell>
      <TableCell data-title="MEAL - PACKING">
                                        {country?.label == "BIKEPACKING" ? (
                                          <RadioGroup value={d.packingLocation ? d.packingLocation : "To Pack"} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, d)
                                          }}>
                                            { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                            <FormControlLabel value="Bike" control={<Radio color="primary" />} label="Mountain Bike" />
                                          </RadioGroup>
                                        ) : country?.label == "MOTORBIKE" ? (
                                          <RadioGroup value={d.packingLocation ? d.packingLocation : "To Pack"} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, d)
                                          }}>
                                            { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                            <FormControlLabel value="Motorbike" control={<Radio color="primary" />} label="Motorbike" />
                                          </RadioGroup>
                                        ) : country?.label == "4x4" ? (
                                          <RadioGroup value={d.packingLocation ? d.packingLocation : "To Pack"} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, d)
                                          }}>
                                            { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                            <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                          </RadioGroup>
                                        ) : country?.label == "OVERLANDING WITH TRAILER" ? (
                                          <RadioGroup value={d.packingLocation ? d.packingLocation : "To Pack"} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, d)
                                          }}>
                                            { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                            <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                            <FormControlLabel value="Camper" control={<Radio color="primary" />} label="Camper" />
                                          </RadioGroup>
                                        ) : country?.label == "4x4 TOWING CARAVAN" ? (
                                          <RadioGroup value={d.packingLocation ? d.packingLocation : "To Pack"} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, d)
                                          }}>
                                            { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                            <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                            <FormControlLabel value="Caravan" control={<Radio color="primary" />} label="Caravan" />
                                          </RadioGroup>
                                        ) : country?.label == "4x4 TOWING BOAT" ? (
                                          <RadioGroup value={d.packingLocation ? d.packingLocation : "To Pack"} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, d)
                                          }}>
                                            { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                            <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                            <FormControlLabel value="Boat" control={<Radio color="primary" />} label="Boat" />
                                          </RadioGroup>
                                        ) : country?.label == "AIR +" ? (
                                          <RadioGroup value={d.packingLocation ? d.packingLocation : "To Pack"} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, d)
                                          }}>
                                          <>
                                          { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                                  {tr?.map((item, i) => {
                                            return (
                                              <>
                                              <div style={{flexBasis: '100%', height: '0'}}></div>
                                              <FormControlLabel key={i} value={"CARRY - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Carry On"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"BAG1 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 1"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"BAG2 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 2"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"BAG3 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 3"}
                                                  
                                              />
                                              { /* <FormControlLabel key={i} value={"BAG4 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 4"}
                                                  
                                              /> */ }
                                              { /* <FormControlLabel key={i} value={"WEAR - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Wear"}
                                                  
                                              /> */ }
                                              <div style={{flexBasis: '100%', height: '0'}}></div>
                                              </>
                                            )
                                          })}
                                          {tr.length >= 1 ? null : <><em>You need to add Travellers to assign a packing location</em></>}</>
</RadioGroup>
                                        ) : country?.label == "HIKING" || country?.label == "BIKEPACKING" ? (
                                          <RadioGroup value={d.packingLocation ? d.packingLocation : "To Pack"} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, d)
                                          }}>
                                          <>
                                          { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                          {tr?.map((item, i) => {
                                            return (
                                              <>
                                              
                                              <FormControlLabel key={i} value={"PACK - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name}
                                                  
                                              />
                                              </>
                                            )
                                          })}
                                          {tr.length >= 1 ? null : <><em>You need to add Travellers to assign a packing location</em></>}</>
</RadioGroup>
                                        ) : <>n/a</>}
                                      </TableCell>
      </TableRow></TableBody></> )

    return (
      <>

      </>
    )
        } ) }</Table></div></TableContainer></AccordionDetails></Accordion></> : null }
                            </> ) } ) }
                {newCheck?.length ? (
                  <TableContainer component={Paper} elevation={0}>
                    <div className="table-responsive-vertical-meals" style={{'display':'none'}}>
                    <Table className="table-packing" size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell className="fourhundred"><TableSortLabel>
                                  INGREDIENT
                                </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel>
                                  QUANTITY
                                </TableSortLabel></TableCell>
                                <TableCell className="twohundred"><TableSortLabel>
                                  SHOPPING
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel>
                                  PACKING
                                </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>
                        {newawdadaaawrr?.map((item, i) => {
                          let it = item.inputQuantity
                          return (
                            <>
                              <TableBody key={i}>
                                <TableRow hover key={item.label} id={item.label}>
                                  <TableCell data-title="MEAL - NAME">
                                  <div>{ /* {item.ItemCopy == undefined || item.ItemCopy == null ? 2 : item.ItemCopy} x{' '} */ }
                                          {Object.keys(result).map(function (key) {
                                            if (key == item.label) {
                                              return (
                                                <>
                                                  <span>{key}</span>
                                                  <span></span>
                                                </>
                                              )
                                            }
                                          })}
                                          {item.Food == '' ? 'n/a' : null}
                                        </div>
                                  </TableCell>
                                  <TableCell data-title="MEAL - QUANTITY">
                                  <div>
                                          {Object.keys(result).map(function (key) {
                                            if (key == item.label) {
                                              return (
                                                <>
                                                  <span>{it ? Number(result[key]) * Number(it) : '1'}</span>
                                                  <span></span>
                                                </>
                                              )
                                            }
                                          })}
                                        </div>
                                  </TableCell>
                                  <TableCell data-title="MEAL - SHOPPING">
                                    <FormControlLabel className="avoid"
                                      control={
                                        <Checkbox
                                          checked={item.bought == true ? true : false}
                                          onChange={(e) => UpdateCHeckboxboght(e, item)}
                                        />
                                      }
                                      label={<span>Bought</span>}
                                    />
                                    { /* <FormControlLabel className="avoid"
                                      control={
                                        <Checkbox
                                          checked={item.packed == true ? true : false}
                                          onChange={(e) => UpdateCHeckbox(e, item)}
                                        />
                                      }
                                      label={<span>Pack</span>}
                                    /> */ }
                                    {/* ))} */}
                                  </TableCell>
                                  <TableCell data-title="MEAL - PACKING">
                                        {country?.label == "BIKEPACKING" ? (
                                          <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, item)
                                          }}>
                                            { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                            <FormControlLabel value="Bike" control={<Radio color="primary" />} label="Mountain Bike" />
                                          </RadioGroup>
                                        ) : country?.label == "MOTORBIKE" ? (
                                          <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, item)
                                          }}>
                                            { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                            <FormControlLabel value="Motorbike" control={<Radio color="primary" />} label="Motorbike" />
                                          </RadioGroup>
                                        ) : country?.label == "4x4" ? (
                                          <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, item)
                                          }}>
                                            { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                            <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                          </RadioGroup>
                                        ) : country?.label == "OVERLANDING WITH TRAILER" ? (
                                          <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, item)
                                          }}>
                                            { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                            <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                            <FormControlLabel value="Camper" control={<Radio color="primary" />} label="Camper" />
                                          </RadioGroup>
                                        ) : country?.label == "4x4 TOWING CARAVAN" ? (
                                          <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, item)
                                          }}>
                                            { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                            <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                            <FormControlLabel value="Caravan" control={<Radio color="primary" />} label="Caravan" />
                                          </RadioGroup>
                                        ) : country?.label == "4x4 TOWING BOAT" ? (
                                          <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, item)
                                          }}>
                                            { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                            <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                            <FormControlLabel value="Boat" control={<Radio color="primary" />} label="Boat" />
                                          </RadioGroup>
                                        ) : country?.label == "AIR +" ? (
                                          <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, item)
                                          }}>
                                          <>
                                          { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                                  {tr?.map((item, i) => {
                                            return (
                                              <>
                                              <div style={{flexBasis: '100%', height: '0'}}></div>
                                              <FormControlLabel key={i} value={"CARRY - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Carry On"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"BAG1 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 1"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"BAG2 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 2"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"BAG3 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 3"}
                                                  
                                              />
                                              { /* <FormControlLabel key={i} value={"BAG4 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 4"}
                                                  
                                              /> */ }
                                              { /* <FormControlLabel key={i} value={"WEAR - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Wear"}
                                                  
                                              /> */ }
                                              <div style={{flexBasis: '100%', height: '0'}}></div>
                                              </>
                                            )
                                          })}
                                          {tr.length >= 1 ? null : <><em>You need to add Travellers to assign a packing location</em></>}</>
</RadioGroup>
                                        ) : country?.label == "HIKING" || country?.label == "BIKEPACKING" ? (
                                          <RadioGroup value={item.packingLocation ? item.packingLocation : "To Pack"} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, item)
                                          }}>
                                          <>
                                          { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                          {tr?.map((item, i) => {
                                            return (
                                              <>
                                              
                                              <FormControlLabel key={i} value={"PACK - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name}
                                                  
                                              />
                                              </>
                                            )
                                          })}
                                          {tr.length >= 1 ? null : <><em>You need to add Travellers to assign a packing location</em></>}</>
</RadioGroup>
                                        ) : <>n/a</>}
                                      </TableCell>
                                </TableRow>
                              </TableBody>
                            </>
                          )
                        })}
                      </Table>
                    </div>
                    </TableContainer>
                    ) : <><div style={{ clear: 'both', height: '20px' }}></div>Your list is currently empty.<div style={{ clear: 'both', height: '40px' }}></div></>}
                  </>
                ) : null}
                <div style={{display:'none'}}>
                    <table className="table-packing-pdf">
                    <thead>
                      <tr>
                          <th>Ingredient</th>
                          <th>Quantity</th>
                          <th>Shopping</th>
                          <th>Packing Location</th>
                          </tr>
                        </thead>
                    {newawdadaaawrr?.map((item, i) => {
                      let it = item.inputQuantity
                      return (
                        <>
                      <tr><td>{Object.keys(result).map(function (key) {
                                            if (key == item.label) {
                                              return (
                                                <>
                                                  <span>{key}</span>
                                                  <span></span>
                                                </>
                                              )
                                            }
                                          })}</td><td>{Object.keys(result).map(function (key) {
                                            if (key == item.label) {
                                              return (
                                                <>
                                                  <span>{Number(result[key]) * Number(it)}</span>
                                                  <span></span>
                                                </>
                                              )
                                            }
                                          })}</td><td>{item.bought == true ? 'Bought' : '-'}</td><td>
                    {item.packingLocation == undefined ? '-' : <>{item.packingLocation}</>}
                    </td></tr>
                    </> )
                    })}</table>
                  </div><br/>
                  <Button variant="outlined" onClick={savePDF}>Save as PDF</Button><br/><br/>
                  </>
                )}
                
              </>
            )}
        {selectedTab === 4 && (
          <>
          <div className="cleantextfield cleantextfortrip" id={country?.label == "HIKING" || country?.label == "BIKEPACKING" || country?.label == "AIR +" ? "hiking-weights-overview" : "weights-overview"}>
          {country?.label == "HIKING" ? (
                <>
                <div className="travellerscontainer" style={{marginTop:'30px'}}>
                <label class="travellerslabel">GEAR + FOOD WEIGHTS</label>
                {tr[0]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[0]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW0.toFixed(2)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF0 + resSetHikeG0 + resSetHike0).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= PACK TOTAL" value={(resSetHikeF0 + resSetHikeG0 + resSetHike0 + resSetHikeGBW0).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW0.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB0.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[1]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[1]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= PACK TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[2]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[2]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= PACK TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[3]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[3]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= PACK TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[4]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[4]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= PACK TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[5]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[5]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= PACK TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                </div>
                </>
                ) : null}

                {country?.label == "BIKEPACKING" ? (
                <>
                <div className="travellerscontainer" style={{marginTop:'30px'}}>
                <label class="travellerslabel">GEAR + FOOD WEIGHTS</label>
                {tr[0]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[0]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW0.toFixed(2)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF0 + resSetHikeG0 + resSetHike0).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= BIKE TOTAL" value={(resSetHikeF0 + resSetHikeG0 + resSetHike0 + resSetHikeGBW0).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW0.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB0.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[1]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[1]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= BIKE TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[2]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[2]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= BIKE TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[3]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[3]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= BIKE TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[4]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[4]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= BIKE TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[5]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[5]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= BIKE TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                </div>
                </>
                ) : null}

                {country?.label == "AIR +" ? (
                <>
                <div className="travellerscontainer" style={{marginTop:'30px'}}>
                <label class="travellerslabel">GEAR + FOOD WEIGHTS</label>
                {tr[0]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[0]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARRY ON (GEAR + FOOD)" value={(resSetAirGBW0 + resSetAirFCO0 + resSetAirMC0).toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 1 (GEAR + FOOD)" value={(resSetAirBAG10 + resSetAirFBAG10 + resSetAirMBAG10).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 2 (GEAR + FOOD)" value={(resSetAirBAG20 + resSetAirFBAG20 + resSetAirMBAG20).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 3 (GEAR + FOOD)" value={(resSetAirBAG30 + resSetAirFBAG30 + resSetAirMBAG30).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 4 (GEAR + FOOD)" value={(resSetAirBAG40 + resSetAirFBAG40 + resSetAirMBAG40).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetAirWE0.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                </div>
                </>
                : null}
                {tr[1]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[1]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARRY ON (GEAR + FOOD)" value={(resSetAirGBW1 + resSetAirFCO1 + resSetAirMC1).toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 1 (GEAR + FOOD)" value={(resSetAirBAG11 + resSetAirFBAG11 + resSetAirMBAG11).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 2 (GEAR + FOOD)" value={(resSetAirBAG21 + resSetAirFBAG21 + resSetAirMBAG21).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 3 (GEAR + FOOD)" value={(resSetAirBAG31 + resSetAirFBAG31 + resSetAirMBAG31).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 4 (GEAR + FOOD)" value={(resSetAirBAG41 + resSetAirFBAG41 + resSetAirMBAG41).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetAirWE1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                </div>
                </>
                : null}
                {tr[2]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[2]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARRY ON (GEAR + FOOD)" value={(resSetAirGBW2 + resSetAirFCO2 + resSetAirMC2).toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 1 (GEAR + FOOD)" value={(resSetAirBAG12 + resSetAirFBAG12 + resSetAirMBAG12).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 2 (GEAR + FOOD)" value={(resSetAirBAG22 + resSetAirFBAG22 + resSetAirMBAG22).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 3 (GEAR + FOOD)" value={(resSetAirBAG32 + resSetAirFBAG32 + resSetAirMBAG32).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 4 (GEAR + FOOD)" value={(resSetAirBAG42 + resSetAirFBAG42 + resSetAirMBAG42).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetAirWE2.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                </div>
               </>
                : null}
                {tr[3]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[3]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARRY ON (GEAR + FOOD)" value={(resSetAirGBW3 + resSetAirFCO3 + resSetAirMC3).toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 1 (GEAR + FOOD)" value={(resSetAirBAG13 + resSetAirFBAG13 + resSetAirMBAG13).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 2 (GEAR + FOOD)" value={(resSetAirBAG23 + resSetAirFBAG23 + resSetAirMBAG23).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 3 (GEAR + FOOD)" value={(resSetAirBAG33 + resSetAirFBAG33 + resSetAirMBAG33).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 4 (GEAR + FOOD)" value={(resSetAirBAG43 + resSetAirFBAG43 + resSetAirMBAG43).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetAirWE3.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                </div>
               </>
                : null}
                {tr[4]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[4]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARRY ON (GEAR + FOOD)" value={(resSetAirGBW4 + resSetAirFCO4 + resSetAirMC4).toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 1 (GEAR + FOOD)" value={(resSetAirBAG14 + resSetAirFBAG14 + resSetAirMBAG14).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 2 (GEAR + FOOD)" value={(resSetAirBAG24 + resSetAirFBAG24 + resSetAirMBAG24).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 3 (GEAR + FOOD)" value={(resSetAirBAG34 + resSetAirFBAG34 + resSetAirMBAG34).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 4 (GEAR + FOOD)" value={(resSetAirBAG44 + resSetAirFBAG44 + resSetAirMBAG44).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetAirWE4.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                </div>
               </>
                : null}
                {tr[5]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[5]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARRY ON (GEAR + FOOD)" value={(resSetAirGBW5 + resSetAirFCO5 + resSetAirMC0).toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 1 (GEAR + FOOD)" value={(resSetAirBAG15 + resSetAirFBAG15 + resSetAirMBAG10).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 2 (GEAR + FOOD)" value={(resSetAirBAG25 + resSetAirFBAG25 + resSetAirMBAG10).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 3 (GEAR + FOOD)" value={(resSetAirBAG35 + resSetAirFBAG35 + resSetAirMBAG10).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 4 (GEAR + FOOD)" value={(resSetAirBAG45 + resSetAirFBAG45 + resSetAirMBAG10).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetAirWE5.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                </div>
               </>
                : null}
                </div>
                </>
                ) : null}

            {country?.label == "OVERLANDING WITH TRAILER" || country?.label == "4x4 TOWING CARAVAN" || country?.label == "4x4 TOWING BOAT" ? (
              <>
                <div className="travellerscontainer" style={{marginTop:'30px'}}>
                <label class="travellerslabel">GEAR + FOOD WEIGHTS</label>
                <div className="weightscontainer">
                <div className="weightsummary">
                <b>GEAR</b><br />
                <Weights listItemUser={listItemUser} data={listUser} country={country} gear={true} />
                </div>
                { /*
                <div className="weightsummary">
                <b>MEALS</b><br />
                <div className="weightsubcontainer">
                {country?.label == 'OVERLANDING WITH TRAILER' ?
                <>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="4x4 (kg)" value={resSet4x4M.toFixed(0)} type="number" variant="outlined" />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CAMPER (kg)" value={resSetCamperM.toFixed(0)} type="number" variant="outlined" />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={(resSet4x4M + resSetCamperM).toFixed(0)} type="number" variant="outlined" />
                </>
                : country?.label == '4x4 TOWING CARAVAN' ?
                <>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="4x4 (kg)" value={resSet4x4M.toFixed(0)} type="number" variant="outlined" />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARAVAN (kg)" value={resSetCaravanM.toFixed(0)} type="number" variant="outlined" />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={(resSet4x4M + resSetCaravanM).toFixed(0)} type="number" variant="outlined" />
                </>
                : country?.label == '4x4 TOWING BOAT' ?
                <>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="4x4 (kg)" value={resSet4x4M.toFixed(0)} type="number" variant="outlined" />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BOAT (kg)" value={resSetBoatM.toFixed(0)} type="number" variant="outlined" />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={(resSet4x4M + resSetCaravanM).toFixed(0)} type="number" variant="outlined" /> 
                </>
                : country?.label == 'HIKING' ?
                <>
                {tr[0]?.Name !== undefined ?
                <>
                <b>{tr[0]?.Name}'s Weights</b><br/>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PACK CONSUMABLES" value={resSetHike0.toFixed(0)} type="number" variant="outlined" style={{textTransform: 'uppercase', width:'23%',minWidth:'125px'}} />&nbsp;&nbsp;
                </> : null}
                {tr[1]?.Name !== undefined ?
                <>
                <b>{tr[1]?.Name}'s Weights</b><br/>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PACK CONSUMABLES" value={resSetHike1.toFixed(0)} type="number" variant="outlined" style={{textTransform: 'uppercase', width:'23%',minWidth:'125px'}} />&nbsp;&nbsp;
                </> : null}
                {tr[2]?.Name !== undefined ?
                <>
                <b>{tr[2]?.Name}'s Weights</b><br/>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PACK CONSUMABLES" value={resSetHike2.toFixed(0)} type="number" variant="outlined" style={{textTransform: 'uppercase', width:'23%',minWidth:'125px'}} />&nbsp;&nbsp;
                </> : null}
                {tr[3]?.Name !== undefined ?
                <>
                <b>{tr[3]?.Name}'s Weights</b><br/>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PACK CONSUMABLES" value={resSetHike3.toFixed(0)} type="number" variant="outlined" style={{textTransform: 'uppercase', width:'23%',minWidth:'125px'}} />&nbsp;&nbsp;
                </> : null}
                {tr[4]?.Name !== undefined ?
                <>
                <b>{tr[4]?.Name}'s Weights</b><br/>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PACK CONSUMABLES" value={resSetHike4.toFixed(0)} type="number" variant="outlined" style={{textTransform: 'uppercase', width:'23%',minWidth:'125px'}} />&nbsp;&nbsp;
                </> : null}
                {tr[5]?.Name !== undefined ?
                <>
                <b>{tr[5]?.Name}'s Weights</b><br/>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PACK CONSUMABLES" value={resSetHike5.toFixed(0)} type="number" variant="outlined" style={{textTransform: 'uppercase', width:'23%',minWidth:'125px'}} />&nbsp;&nbsp;
                </> : null}
                </>
                : null } */ }
                {/* <FWeight listItemUser={listItemUser} data={listUserM} country={country} /> */}
                { /* </div>
                </div> */ }
                <div className="weightsummary">
                <b>FOOD</b><br />
                {country?.label !== 'HIKING' && country?.label !== 'AIR +' ?
                <Weights listItemUser={listItemUser} data={listUserF} country={country} gear={false} />
                : null} 
                </div>
                </div>
                </div>
              </>
            ) : (
                <>
                {country?.label !== 'HIKING' && country?.label !== 'AIR +' ?
                <div className="travellerscontainer" style={{marginTop:'30px'}}>
                <label class="travellerslabel">GEAR + FOOD WEIGHTS</label>
                <div className="weightsubcontainer">
                  <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'GEAR (kg)' : '+ GEAR (lb)'} value={getgearweight.toFixed(0)} name="name" type="number" variant="outlined" />
                  <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ FOOD (kg)' : '+ FOOD (lb)'} value={getFoodweight.toFixed(0)} name="name" type="number" variant="outlined" />
                  { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ MEALS (kg)' : '+ MEALS (lb)'} value={n.toFixed(0)} name="name" type="number" variant="outlined" /> */ }
                  <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '= GEAR + FOOD (kg)' : '= GEAR + FOOD (lb)'} value={totalweightsssa.toFixed(0)} name="name" type="number" variant="outlined" /><br /><br />
                </div></div> : null}
                </>
              )}
            {gearcat == "4x4" && (
              <>
              {listItemUser[0]?.FourbyFourPlusCaravan !== undefined ?
                      <>
                <h3>4x4</h3>
                {listItemUser?.map(item => {
                  const fourbyfourpayload = Number(item.FourbyFourPlusCaravan.FourbyFour_GVM) - Number(item?.FourbyFourPlusCaravan.FourbyFour_Empty);
                  const fourbyfourremaining = Number(item.FourbyFourPlusCaravan.FourbyFour_GVM) - ((Number(item?.FourbyFourPlusCaravan.FourbyFour_TOTAL + totalweightsssa)).toFixed(0));
                  const fourfourcalctotal = Number((item?.FourbyFourPlusCaravan.FourbyFour_TOTAL) + totalweightsssa + tatata).toFixed(0);
                  return (
                    <>
                    <div className="travellerscontainer" style={{paddingTop:'18px'}}>
                          <label className="travellerslabel">ALL WEIGHTS</label>
                          <div className="garage-wrapper">
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'EMPTY (kg)' : 'EMPTY (lb)'} value={item?.FourbyFourPlusCaravan.FourbyFour_Empty} name="FourbyFour_Empty" type="number" variant="outlined" />
                          { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="GVM (kg)" value={item.FourbyFourPlusCaravan.FourbyFour_GVM} name="FourbyFour_GVM" type="number" variant="outlined" />&nbsp;
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PAYLOAD (kg)" value={fourbyfourpayload} name="FourbyFour_Payload" type="number" variant="outlined" />&nbsp; */ }
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ FUEL (kg)' : '+ FUEL (lb)'} value={item.FourbyFourPlusCaravan.FourbyFour_FUEL} name="FourbyFour_FUEL" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ WATER (kg)' : '+ WATER (lb)'} value={item.FourbyFourPlusCaravan.FourbyFour_Water} name="FourbyFour_Water" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ GEAR + FOOD (kg)' : '+ GEAR + FOOD (lb)'} value={totalweightsssa.toFixed(0)} name="FourbyFour_GplusF" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ TRAVELLERS (kg)' : '+ TRAVELLERS (lb)'} value={tatata.toFixed(0)} name="FourbyFour_GplusT" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= TOTAL" value={fourfourcalctotal} name="FourbyFour_TOTAL" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={(fourbyfourremaining - tatata).toFixed(0)} name="FourbyFour_TOTAL" type="number" variant="outlined" className={(fourbyfourremaining < 0 ) ? "negative" : "positive"} />
                        </div></div>
                        {listItemUser[0]?.TorquePower !== undefined ? <>
                        <div className="travellerscontainer" style={{paddingTop:'18px'}}>
              <label className="travellerslabel">PERFORMANCE</label>
              <div className="garage-wrapper">
              <TextField disabled type="number" label="TORQUE (Nm)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.Torque} onChange={(e) => setTorque(e.target.value)} name="Torque" variant="outlined" />
              <TextField disabled label="TORQUE : WEIGHT RATIO (Nm)" type="text" defaultValue="0" variant="outlined" value={fourfourcalctotal && item?.TorquePower.Torque ? '1 : ' +  (fourfourcalctotal / item?.TorquePower.Torque).toFixed(0) : 0} />
              <TextField disabled type="number" label="POWER (kW)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerKW} onChange={(e) => setPower(e.target.value)} name="Power" variant="outlined" />
              <TextField disabled label="POWER : WEIGHT RATIO (kW)" type="text" defaultValue="0" variant="outlined" value={fourfourcalctotal && item?.TorquePower.PowerKW ? '1 : ' +  (fourfourcalctotal / item?.TorquePower.PowerKW).toFixed(0) : 0} />
              <TextField disabled type="number" label="POWER (HP)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerHP} onChange={(e) => setPowerHP(e.target.value)} name="PowerHP" variant="outlined" />
              <TextField disabled label="POWER : WEIGHT RATIO (HP)" type="text" defaultValue="0" variant="outlined" value={fourfourcalctotal && item?.TorquePower.PowerHP ? '1 : ' +  (fourfourcalctotal / item?.TorquePower.PowerHP).toFixed(0) : 0} />
              </div>
              </div></> : null}
                        <em>Weight calculators are purely a guide. To ensure safety, legal, and insurance compliance, check your total weights on a weighbridge.</em><br /><br />
                    </>
                  )
                })}
                </> : <b>No Weights Defined</b>}
              </>
            )}
              {gearcat == "OVERLANDING WITH TRAILER" && (
                <>
                {listItemUser[0]?.FourbyFourPlusCaravan !== undefined ?
                      <>
                  {listItemUser?.map(item => {
                          const forbyfortotalplusball = Number(item?.FourbyFourPlusCaravan.FourbyFour_TOTAL) + Number(item?.Camper?.Camper_CamperBall) + Number(resSet4x4) + Number(tatata.toFixed(0));
                          const camper_Total = (Number(item?.Camper?.Camper_Empty) + Number(item?.Camper?.Camper_Water) + Number(resSetCamper) + Number(resSetCamperF) + Number(resSetCamperM)).toFixed(0) ;
                          const campernoballweight = Number(camper_Total) - Number(item?.Camper?.Camper_CamperBall);
                          const camperremaining = Number(item?.Camper?.Camper_ATM) - Number(campernoballweight) - Number(item?.Camper?.Camper_CamperBall);
                          const fourbyfourremaining = Number(item.FourbyFourPlusCaravan.FourbyFour_GVM) - Number(forbyfortotalplusball);
                          const forbyfortotal = Number(item?.FourbyFourPlusCaravan.FourbyFour_Empty) + Number(item.FourbyFourPlusCaravan.FourbyFour_FUEL) + Number(item.FourbyFourPlusCaravan.FourbyFour_Water);
                          const camper4x4_Total = Number(forbyfortotal) + Number(item?.Camper?.Camper_CamperBall) + Number(resSet4x4) + Number(tatata.toFixed(0));
                          const camperminusball_Total = Number(camper_Total) - Number(item?.Camper?.Camper_CamperBall);
                          const campertotal = Number(camper4x4_Total) + Number(camperminusball_Total);
                          const camperremaining_Total = Number(item?.Camper?.camperforbyfor_GCM) - Number(campertotal);
                          return (
                            <>
                              <div className="travellerscontainer" style={{paddingTop:'18px'}}>
                          <label className="travellerslabel">ALL WEIGHTS</label>
                          <b>CAMPER</b><br />
                          <div className="garage-wrapper">
                              <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'EMPTY (kg)' : 'EMPTY (lb)'} value={item?.Camper?.Camper_Empty} name="Camper_Empty" type="number" variant="outlined" />
                            { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="ATM (kg)" value={item?.Camper?.Camper_ATM} name="Camper_ATM" type="number" variant="outlined" />&nbsp;
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PAYLOAD (kg)" value={item?.Camper?.Camper_Payload} name="Camper_Payload" type="number" variant="outlined" />&nbsp; */ }
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ WATER (kg)' : '+ WATER (lb)'} value={item?.Camper?.Camper_Water} name="Camper_Water" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ GEAR + FOOD (kg)' : '+ GEAR + FOOD (lb)'} value={(resSetCamper + resSetCamperF + resSetCamperM).toFixed(0)} name="FourbyFour_GplusF" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={camperremaining.toFixed(0)} name="Camper_CampSerBall" type="number" variant="outlined" className={(camperremaining < 0 ) ? "negative" : "positive"} />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'BALL WEIGHT (kg)' : 'BALL WEIGHT (lb)'} value={item?.Camper?.Camper_CamperBall} name="Camper_CamperBall" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'CAMPER TOTAL (kg)' : 'CAMPER TOTAL (lb)'} value={campernoballweight} name="Camper_CampSerBall" type="number" variant="outlined" />
                            { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={item?.Camper?.Camper_Total} name="Total" type="number" variant="outlined" />&nbsp; */ }
                            
                            </div>
                            <b>4x4</b><br />
                            <div className="garage-wrapper">
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'EMPTY (kg)' : 'EMPTY (lb)'} value={item?.FourbyFourPlusCaravan.FourbyFour_Empty} name="FourbyFour_Empty" type="number" variant="outlined" />
                            { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="GVM (kg)" value={item.FourbyFourPlusCaravan.FourbyFour_GVM} name="FourbyFour_GVM" type="number" variant="outlined" />&nbsp;
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PAYLOAD (kg)" value={item.FourbyFourPlusCaravan.FourbyFour_Payload} name="FourbyFour_Payload" type="number" variant="outlined" />&nbsp; */ }
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ FUEL (kg)' : '+ FUEL (lb)'} value={item.FourbyFourPlusCaravan.FourbyFour_FUEL} name="FourbyFour_FUEL" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ WATER (kg)' : '+ WATER (lb)'} value={item.FourbyFourPlusCaravan.FourbyFour_Water} name="FourbyFour_Water" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ GEAR + FOOD (kg)' : '+ GEAR + FOOD (lb)'} value={(resSet4x4 + resSet4x4M + resSet4x4F).toFixed(0)} name="FourbyFour_GplusF" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ TRAVELLERS (kg)' : '+ TRAVELLERS (lb)'} value={tatata.toFixed(0)} name="FourbyFour_GplusT" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ BALL WEIGHT (kg)' : '+ BALL WEIGHT (lb)'} value={item?.Camper?.Camper_CamperBall} name="BALL WEIGHT" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '= 4x4 TOTAL (kg)' : '= 4x4 TOTAL (lb)'} value={forbyfortotalplusball.toFixed(0)} name="FourbyFour_TOTAL" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={fourbyfourremaining.toFixed(0)} name="FourbyFour_TOTAL" type="number" variant="outlined" className={(fourbyfourremaining < 0 ) ? "negative" : "positive"} />
                            </div>
                            <b>4x4 + CAMPER</b><br />
                            <div className="garage-wrapper">
                              <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '4x4 GCM (kg)' : '4x4 GCM (lb)'} value={item?.Camper?.camperforbyfor_GCM} name="Camper_Empty" type="number" variant="outlined" />
                              { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="4x4 TOTAL (kg)" value={campertotal} name="camperTotal" type="number" variant="outlined" />&nbsp; */ }
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '- 4x4 TOTAL (kg)' : '- 4x4 TOTAL (lb)'} value={camper4x4_Total.toFixed(0)} name="4x4Total" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '- CAMPER TOTAL (kg)' : '- CAMPER TOTAL (lb)'} value={camperminusball_Total} name="camperTotal" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={camperremaining_Total.toFixed(0)} name="Total" type="number" variant="outlined" className={(camperremaining_Total < 0 ) ? "negative" : "positive"} />
                            </div></div>
                            {listItemUser[0]?.TorquePower !== undefined ? <>
                            <div className="travellerscontainer" style={{paddingTop:'18px'}}>
                            <label className="travellerslabel">PERFORMANCE</label>
                            <div className="garage-wrapper">
                            <TextField disabled type="number" label="TORQUE (Nm)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.Torque} onChange={(e) => setTorque(e.target.value)} name="Torque" variant="outlined" />
                            <TextField disabled label="TORQUE : WEIGHT RATIO (Nm)" type="text" defaultValue="0" variant="outlined" value={camperminusball_Total && item?.TorquePower.Torque ? '1 : ' +  ((Number(camperminusball_Total) + Number(camper4x4_Total)) / item?.TorquePower.Torque).toFixed(0) : 0} />
                            <TextField disabled type="number" label="POWER (kW)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerKW} onChange={(e) => setPower(e.target.value)} name="Power" variant="outlined" />
                            <TextField disabled label="POWER : WEIGHT RATIO (kW)" type="text" defaultValue="0" variant="outlined" value={camperminusball_Total && item?.TorquePower.PowerKW ? '1 : ' +  ((Number(camperminusball_Total) + Number(camper4x4_Total)) / item?.TorquePower.PowerKW).toFixed(0) : 0} />
                            <TextField disabled type="number" label="POWER (HP)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerHP} onChange={(e) => setPowerHP(e.target.value)} name="PowerHP" variant="outlined" />
                            <TextField disabled label="POWER : WEIGHT RATIO (HP)" type="text" defaultValue="0" variant="outlined" value={camperminusball_Total && item?.TorquePower.PowerHP ? '1 : ' +  ((Number(camperminusball_Total) + Number(camper4x4_Total)) / item?.TorquePower.PowerHP).toFixed(0) : 0} />
                            </div>
                            </div></> : null}
                            <em>Weight calculators are purely a guide. To ensure safety, legal, and insurance compliance, check your total weights on a weighbridge.</em><br /><br />
                            </>
                          )
                        })}
                        <br />
                        </> : <>No Weights Defined</>}
                      </>
              )}
              {gearcat == "4x4 TOWING CARAVAN" && (
                <>
                {listItemUser[0]?.Caravan !== undefined ?
                      <>
                  {listItemUser?.map(item => {
                    const caravanpayload = Number(item?.Caravan?.Caravan_ATM) - Number(item?.Caravan?.Caravan_Empty);
                    const caravan_Total = (Number(item?.Caravan?.Caravan_Empty) + Number(item?.Caravan?.Caravan_Water) + Number(resSetCaravan) + Number(resSetCaravanF)).toFixed(0);
                    const caravanremaining = Number(item?.Caravan?.Caravan_ATM) - Number(caravan_Total);
                    const caravannoballweight = Number(caravan_Total) - Number(item?.Caravan?.caravan_forbyfor_caravanBall);
                    const forbyfortotal = Number(item?.FourbyFourPlusCaravan.FourbyFour_Empty) + Number(item.FourbyFourPlusCaravan.FourbyFour_FUEL) + Number(item.FourbyFourPlusCaravan.FourbyFour_Water);
                    const forbyfortotalcaraplusball = (Number(forbyfortotal) + Number(item?.Caravan?.caravan_forbyfor_caravanBall) + Number(tatata) + Number(resSet4x4) + Number(resSet4x4F)).toFixed(0);
                    const fourbyfourremaining = Number(item.FourbyFourPlusCaravan.FourbyFour_GVM) - (Number(forbyfortotal) + totalweightsssa + tatata + resSet4x4 + resSet4x4F);
                    const caravanminusball_Total = Number(caravan_Total) - Number(item?.Caravan?.caravan_forbyfor_caravanBall);
                    const caravantotal = Number(forbyfortotalcaraplusball) + Number(caravannoballweight) + Number(resSetCaravan) + Number(resSetCaravanF);
                    const caravanremaining_Total = Number(item?.Caravan?.caravan_GCM) - Number(caravantotal);
                    return (
                      <>
                        <div className="travellerscontainer" style={{paddingTop:'18px'}}>
                            <label className="travellerslabel">ALL WEIGHTS</label>
                              <b>CARAVAN</b><br />
                              <div className="garage-wrapper">
                              <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'EMPTY (kg)' : 'EMPTY (lb)'} value={item?.Caravan?.Caravan_Empty} name="Caravan_Empty" type="number" variant="outlined" />
                            { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="ATM (kg)" value={item?.Caravan?.Caravan_ATM} name="Caravan_ATM" type="number" variant="outlined" />&nbsp;
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PAYLOAD (kg)" value={caravanpayload} name="Caravan_Payload" type="number" variant="outlined" />&nbsp; */ }
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ WATER (kg)' : '+ WATER (lb)'} value={item?.Caravan?.Caravan_Water} name="Caravan_Water" type="number" variant="outlined" />
                            { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="GREYWATER (kg)" value={item?.Caravan?.Caravan_Greywater} name="Caravan_Greywater" type="number" variant="outlined" />&nbsp;+&nbsp; */ }
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ GEAR + FOOD (kg)' : '+ GEAR + FOOD (lb)'} value={(resSetCaravan + resSetCaravanF + resSetCaravanM).toFixed(0)} name="FourbyFour_GplusF" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '- BALL WEIGHT (kg)' : '- BALL WEIGHT (lb)'} value={item?.Caravan?.caravan_forbyfor_caravanBall} name="Caravan_Ball" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '= CARAVAN TOTAL (kg)' : '= CARAVAN TOTAL (lb)'} value={caravannoballweight} name="Caravan_Ball" type="number" variant="outlined" />
                            { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={item?.Caravan?.Caravan_Total} name="Caravan_total" type="number" variant="outlined" />&nbsp; */ }
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={caravanremaining.toFixed(0)} name="Caravan_Ball" type="number" variant="outlined" className={(caravanremaining < 0 ) ? "negative" : "positive"} />
                            </div>
                            <br /><b>4x4</b><br />
                            <div className="garage-wrapper">
                              <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'EMPTY (kg)' : 'EMPTY (lb)'} value={item?.FourbyFourPlusCaravan.FourbyFour_Empty} name="FourbyFour_Empty" type="number" variant="outlined" />
                            { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="GVM (kg)" value={item.FourbyFourPlusCaravan.FourbyFour_GVM} name="FourbyFour_GVM" type="number" variant="outlined" />&nbsp;
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PAYLOAD (kg)" value={item.FourbyFourPlusCaravan.FourbyFour_Payload} name="FourbyFour_Payload" type="number" variant="outlined" />&nbsp; */ }
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ FUEL (kg)' : 'FUEL (lb)'} value={item.FourbyFourPlusCaravan.FourbyFour_FUEL} name="FourbyFour_FUEL" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ WATER (kg)' : 'WATER (lb)'} value={item.FourbyFourPlusCaravan.FourbyFour_Water} name="FourbyFour_Water" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ GEAR + FOOD (kg)' : 'GEAR + FOOD (lb)'} value={(resSet4x4 + resSet4x4F + resSet4x4M).toFixed(0)} name="FourbyFour_GplusF" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ TRAVELLERS (kg)' : 'TRAVELLERS (lb)'} value={tatata.toFixed(0)} name="FourbyFour_GplusT" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ BALL WEIGHT (kg)' : 'BALL WEIGHT (lb)'} value={item?.Caravan?.caravan_forbyfor_caravanBall} name="Caravan_Ball" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '= 4x4 TOTAL (kg)' : '= 4x4 TOTAL (lb)'} value={forbyfortotalcaraplusball} name="FourbyFour_TOTAL" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'REMAINING (kg)' : 'REMAINING (lb)'} value={fourbyfourremaining.toFixed(0)} name="FourbyFour_TOTAL" type="number" variant="outlined" className={(fourbyfourremaining < 0 ) ? "negative" : "positive"} />
                            </div>
                            <br /><b>4x4 + CARAVAN</b><br />
                            <div className="garage-wrapper">
                              <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '4x4 GCM (kg)' : '4x4 GCM (lb)'} value={item?.Caravan?.caravan_GCM} name="Caravan_Empty" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '- 4x4 TOTAL (kg)' : '- 4x4 TOTAL (lb)'} value={forbyfortotalcaraplusball} name="Caravan_4x4 total" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '- CARAVAN TOTAL (kg)' : '- CARAVAN TOTAL (lb)'} value={caravanminusball_Total} name="Caravan_Total" type="number" variant="outlined" />
                            { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="4x4 CARAVAN TOTAL (kg)" value={item?.Caravan?.Caravan_forbyfor_Total} name="Total" type="number" variant="outlined" />&nbsp; */ }
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={caravanremaining_Total.toFixed(0)} name="Total" type="number" variant="outlined" className={(caravanremaining_Total < 0 ) ? "negative" : "positive"} />
                            </div></div>
                            {listItemUser[0]?.TorquePower !== undefined ? <>
                            <div className="travellerscontainer" style={{paddingTop:'18px'}}>
                            <label className="travellerslabel">PERFORMANCE</label>
                            <div className="garage-wrapper">
                            <TextField disabled type="number" label="TORQUE (Nm)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.Torque} onChange={(e) => setTorque(e.target.value)} name="Torque" variant="outlined" />
                            <TextField disabled label="TORQUE : WEIGHT RATIO (Nm)" type="text" defaultValue="0" variant="outlined" value={forbyfortotalcaraplusball && item?.TorquePower.Torque ? '1 : ' + ((Number(forbyfortotalcaraplusball) + Number(caravanminusball_Total)) / item?.TorquePower.Torque).toFixed(0) : 0} />
                            <TextField disabled type="number" label="POWER (kW)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerKW} onChange={(e) => setPower(e.target.value)} name="Power" variant="outlined" />
                            <TextField disabled label="POWER : WEIGHT RATIO (kW)" type="text" defaultValue="0" variant="outlined" value={forbyfortotalcaraplusball && item?.TorquePower.PowerKW ? '1 : ' +  ((Number(forbyfortotalcaraplusball) + Number(caravanminusball_Total)) / item?.TorquePower.PowerKW).toFixed(0) : 0} />
                            <TextField disabled type="number" label="POWER (HP)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerHP} onChange={(e) => setPowerHP(e.target.value)} name="PowerHP" variant="outlined" />
                            <TextField disabled label="POWER : WEIGHT RATIO (HP)" type="text" defaultValue="0" variant="outlined" value={forbyfortotalcaraplusball && item?.TorquePower.PowerHP ? '1 : ' +  ((Number(forbyfortotalcaraplusball) + Number(caravanminusball_Total)) / item?.TorquePower.PowerHP).toFixed(0) : 0} />
                            </div>
                            </div></> : null}
                            <em>Weight calculators are purely a guide. To ensure safety, legal, and insurance compliance, check your total weights on a weighbridge.</em><br /><br />
                            </>
                    )
                  })}
                  <br /></> : <>No Weights Defined</>}</>)}
              {gearcat == "4x4 TOWING BOAT" && (
                <>
                {listItemUser[0]?.BoatWeight !== undefined ?
                      <>
                  {listItemUser?.map(item => {
                    const boatremaining = Number(item?.BoatWeight?.BoatWeight_ATM) - Number(item?.BoatWeight?.BoatTotal);
                    const Boat_Total = (Number(item?.BoatWeight?.BoatWeight_Empty) + Number(item?.BoatWeight?.BoatWeight_Water) + Number(item?.BoatWeight?.BoatWeight_Fuel) + resSetBoat + resSetBoatF).toFixed(0);
                    const boatnoballweight = Number(Boat_Total) - Number(item?.BoatWeight?.BoatWeight_forbyfor_BoatBall);
                    const forbyfortotal = Number(item?.FourbyFourPlusCaravan.FourbyFour_Empty) + Number(item.FourbyFourPlusCaravan.FourbyFour_FUEL) + Number(item.FourbyFourPlusCaravan.FourbyFour_Water);
                    const forbyfortotalcaraplusball = (Number(forbyfortotal) + Number(item?.BoatWeight?.BoatWeight_forbyfor_BoatBall) + Number(tatata) + Number(resSet4x4) + Number(resSet4x4F)).toFixed(0);
                    const fourbyfourremaining = Number(item.FourbyFourPlusCaravan.FourbyFour_GVM) - (Number(forbyfortotal) + totalweightsssa + tatata);
                    const boatminusball_Total = Number(Boat_Total) - Number(item?.BoatWeight?.BoatWeight_forbyfor_BoatBall);
                    const boattotal = Number(forbyfortotalcaraplusball) + Number(boatminusball_Total);
                    const boatremaining_Total = Number(item?.BoatWeight?.BoatWeight_GCM) - Number(boattotal);
                    return (
                      <>
                        <div className="travellerscontainer" style={{paddingTop:'18px'}}>
                            <label className="travellerslabel">ALL WEIGHTS</label>
                              <b>BOAT</b><br />
                              <div className="garage-wrapper">
                              <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'EMPTY (kg)' : 'EMPTY (lb)'} value={item?.BoatWeight?.BoatWeight_Empty} name="BoatWeight_Empty" type="number" variant="outlined" />
                          { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="ATM (kg)" value={item?.BoatWeight?.BoatWeight_ATM} name="BoatWeight_ATM" type="number" variant="outlined" />&nbsp;
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PAYLOAD (kg)" value={item?.BoatWeight?.BoatWeight_Payload} name="BoatWeight_Payload" type="number" variant="outlined" />&nbsp; */ }
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ FUEL (kg)' : '+ FUEL (lb)'} value={item?.BoatWeight?.BoatWeight_Fuel} name="BoatWeight_Payload" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ WATER (kg)' : '+ WATER (lb)'} value={item?.BoatWeight?.BoatWeight_Water} name="BoatWeight_Water" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ GEAR + FOOD (kg)' : '+ GEAR + FOOD (lb)'} value={(resSetBoat + resSetBoatF + resSetBoatM).toFixed(0)} name="FourbyFour_GplusF" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '- BALL WEIGHT (kg)' : '- BALL WEIGHT (lb)'} value={item?.BoatWeight?.BoatWeight_forbyfor_BoatBall} name="BoatWeight_BoatBall" type="number" variant="outlined" />
                          { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={item?.BoatWeight?.BoatTotal} name="Total" type="number" variant="outlined" />&nbsp; */ }
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '= BOAT TOTAL (kg)' : '= BOAT TOTAL (lb)'} value={boatnoballweight} name="BoatWeight_BoatBall" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={boatremaining.toFixed(0)} name="BoatWeight_BoatBall" type="number" variant="outlined" className={(boatremaining < 0 ) ? "negative" : "positive"} />
                          </div>
                          <b>4x4</b><br />
                          <div className="garage-wrapper">
                              <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'EMPTY (kg)' : 'EMPTY (lb)'} value={item?.FourbyFourPlusCaravan.FourbyFour_Empty} name="FourbyFour_Empty" type="number" variant="outlined" />
                          { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="GVM (kg)" value={item.FourbyFourPlusCaravan.FourbyFour_GVM} name="FourbyFour_GVM" type="number" variant="outlined" />&nbsp;
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PAYLOAD (kg)" value={item.FourbyFourPlusCaravan.FourbyFour_Payload} name="FourbyFour_Payload" type="number" variant="outlined" />&nbsp; */ }
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ FUEL (kg)' : '+ FUEL (lb)'} value={item.FourbyFourPlusCaravan.FourbyFour_FUEL} name="FourbyFour_FUEL" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ WATER (kg)' : '+ WATER (lb)'} value={item.FourbyFourPlusCaravan.FourbyFour_Water} name="FourbyFour_Water" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ GEAR + FOOD (kg)' : '+ GEAR + FOOD (lb)'} value={(resSet4x4 + resSet4x4F + resSet4x4M).toFixed(0)} name="FourbyFour_GplusF" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ TRAVELLERS (kg)' : '+ TRAVELLERS (lb)'} value={tatata.toFixed(0)} name="FourbyFour_GplusT" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ BALL WEIGHT (kg)' : '+ BALL WEIGHT (lb)'} value={item?.BoatWeight?.BoatWeight_forbyfor_BoatBall} name="FourbyFour_TOTAL" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '= 4x4 TOTAL (kg)' : '= 4x4 TOTAL (lb)'} value={forbyfortotalcaraplusball} name="FourbyFour_TOTAL" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={fourbyfourremaining.toFixed(0)} name="FourbyFour_TOTAL" type="number" variant="outlined" className={(fourbyfourremaining < 0 ) ? "negative" : "positive"} />
                          </div>
                          <b>4x4 + BOAT</b><br />
                          <div className="garage-wrapper">
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '4x4 GCM (kg)' : '4x4 GCM (lb)'} value={item?.BoatWeight?.BoatWeight_GCM} name="BoatWeight_Empty" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '- 4x4 TOTAL (kg)' : '- 4x4 TOTAL (lb)'} value={forbyfortotalcaraplusball} name="BoatWeight_4x4 Total" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '= BOAT TOTAL (kg)' : '= BOAT TOTAL (lb)'} value={boatminusball_Total} name="BoatWeightttal" type="number" variant="outlined" />
                          { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="4x4 BOAT TOTAL (kg)" value={boattotal} name="Total" type="number" variant="outlined" />&nbsp; */ }
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={boatremaining_Total.toFixed(0)} name="Total" type="number" variant="outlined" className={(boatremaining_Total < 0 ) ? "negative" : "positive"} />
                          </div></div>
                          {listItemUser[0]?.TorquePower !== undefined ? <>
                          <div className="travellerscontainer" style={{paddingTop:'18px'}}>
                            <label className="travellerslabel">PERFORMANCE</label>
                            <div className="garage-wrapper">
                            <TextField disabled type="number" label="TORQUE (Nm)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.Torque} onChange={(e) => setTorque(e.target.value)} name="Torque" variant="outlined" />
                            <TextField disabled label="TORQUE : WEIGHT RATIO (Nm)" type="text" defaultValue="0" variant="outlined" value={forbyfortotalcaraplusball && item?.TorquePower.Torque ? '1 : ' + ((Number(forbyfortotalcaraplusball) + Number(boatminusball_Total)) / item?.TorquePower.Torque).toFixed(0) : 0} />
                            <TextField disabled type="number" label="POWER (kW)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerKW} onChange={(e) => setPower(e.target.value)} name="Power" variant="outlined" />
                            <TextField disabled label="POWER : WEIGHT RATIO (kW)" type="text" defaultValue="0" variant="outlined" value={forbyfortotalcaraplusball && item?.TorquePower.PowerKW ? '1 : ' +  ((Number(forbyfortotalcaraplusball) + Number(boatminusball_Total)) / item?.TorquePower.PowerKW).toFixed(0) : 0} />
                            <TextField disabled type="number" label="POWER (HP)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerHP} onChange={(e) => setPowerHP(e.target.value)} name="PowerHP" variant="outlined" />
                            <TextField disabled label="POWER : WEIGHT RATIO (HP)" type="text" defaultValue="0" variant="outlined" value={forbyfortotalcaraplusball && item?.TorquePower.PowerHP ? '1 : ' +  ((Number(forbyfortotalcaraplusball) + Number(boatminusball_Total)) / item?.TorquePower.PowerHP).toFixed(0) : 0} />
                            </div>
                            </div></> : null}
                          <em>Weight calculators are purely a guide. To ensure safety, legal, and insurance compliance, check your total weights on a weighbridge.</em><br /><br />
                            </>
                    )
                  })}
                  <br />
                  </> : <>No Weights Defined</>}
                </>)}
              {gearcat == "MOTORBIKE" && (
                <>
                {listItemUser[0]?.AdventureMotorBike !== undefined ?
                      <>
                  {listItemUser?.map(item => {
                    const adventureMotorbile_Payload = Number(item?.AdventureMotorBike?.AdventureMotorBike_GVM) - Number(item?.AdventureMotorBike?.AdventureMotorBike_Empty);
                    const adventure_Total = Number(item?.AdventureMotorBike?.AdventureMotorBike_Empty) + Number(item?.AdventureMotorBike?.AdventureMotorBike_Water) + Number(item?.AdventureMotorBike?.AdventureMotorBike_Fuel) + Number(totalweightsssa.toFixed(0)) + Number(tatata.toFixed(0));
                    const advmotoremaining = Number(item?.AdventureMotorBike?.AdventureMotorBike_GVM) - Number(adventure_Total);
                    return (
                      <>
                          <div className="travellerscontainer" style={{paddingTop:'18px'}}>
                          <label className="travellerslabel">ALL WEIGHTS</label>
                          <div className="garage-wrapper">
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'EMPTY (kg)' : 'EMPTY (lb)'} value={item?.AdventureMotorBike?.AdventureMotorBike_Empty} name="AdventureMotorBike_Empty" type="number" variant="outlined" />
                          { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="GVM (kg)" value={item?.AdventureMotorBike?.AdventureMotorBike_GVM} name="AdventureMotorBike_GVM" type="number" variant="outlined" />&nbsp;
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PAYLOAD (kg)" value={adventureMotorbile_Payload} name="AdventureMotorBike_Payload" type="number" variant="outlined" />&nbsp; */ }
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ FUEL (kg)' : '+ FUEL (lb)'} value={item?.AdventureMotorBike?.AdventureMotorBike_Fuel} name="AdventureMotorBike_Fuel" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ WATER (kg)' : '+ WATER (lb)'} value={item?.AdventureMotorBike?.AdventureMotorBike_Water} name="AdventureMotorBike_Water" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ GEAR + FOOD (kg)' : '+ GEAR + FOOD (lb)'} value={totalweightsssa.toFixed(0)} name="FourbyFour_GplusF" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ TRAVELLERS (kg)' : '+ TRAVELLERS (lb)'} value={tatata.toFixed(0)} name="FourbyFour_GplusT" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '= TOTAL (kg)' : '= TOTAL (lb)'} value={adventure_Total} name="total" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={advmotoremaining.toFixed(0)} name="total" type="number" variant="outlined" className={(advmotoremaining < 0 ) ? "negative" : "positive"} />
                          </div></div>
                          {listItemUser[0]?.TorquePower !== undefined ? <>
                          <div className="travellerscontainer" style={{paddingTop:'18px'}}>
                            <label className="travellerslabel">PERFORMANCE</label>
                            <div className="garage-wrapper">
                            <TextField disabled type="number" label="TORQUE (Nm)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.Torque} onChange={(e) => setTorque(e.target.value)} name="Torque" variant="outlined" />
                            <TextField disabled label="TORQUE : WEIGHT RATIO (Nm)" type="text" defaultValue="0" variant="outlined" value={adventure_Total && item?.TorquePower.Torque ? '1 : ' + (adventure_Total / item?.TorquePower.Torque).toFixed(0) : 0} />
                            <TextField disabled type="number" label="POWER (kW)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerKW} onChange={(e) => setPower(e.target.value)} name="Power" variant="outlined" />
                            <TextField disabled label="POWER : WEIGHT RATIO (kW)" type="text" defaultValue="0" variant="outlined" value={adventure_Total && item?.TorquePower.PowerKW ? '1 : ' +  (adventure_Total / item?.TorquePower.PowerKW).toFixed(0) : 0} />
                            <TextField disabled type="number" label="POWER (HP)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerHP} onChange={(e) => setPowerHP(e.target.value)} name="PowerHP" variant="outlined" />
                            <TextField disabled label="POWER : WEIGHT RATIO (HP)" type="text" defaultValue="0" variant="outlined" value={adventure_Total && item?.TorquePower.PowerHP ? '1 : ' +  (adventure_Total / item?.TorquePower.PowerHP).toFixed(0) : 0} />
                            </div>
                            </div></> : null}
                          <em>Weight calculators are purely a guide. To ensure safety, legal, and insurance compliance, check your total weights on a weighbridge.</em><br /><br />
                            </>
                    )
                  })}
                  <br /></> : <b>No Weights Defined</b>}
                </>
              )}
              {gearcat == "HIKING" && (<><br /><br /></>)}
              {gearcat == "BIKEPACKING" && (<><br /><br /></>)}
              {gearcat == "SEA KAYAKING" && (<><br /><br /></>)}
              {gearcat == "WHITEWATER KAYAKING" && (<><br /><br /></>)}
              {gearcat == "CANOEING" && (<><br /><br /></>)}
              {gearcat == "SAILING" && (<><br /><br /></>)}
              {gearcat == "AIR +" && (<><br /><br /></>)}
            </div>
          </>
        )}
        {selectedTab === 5 && (
          <>
              
          {country?.label == "HIKING" || country?.label == "BIKEPACKING" ?
          <div className="final-list-container">
          {Object.keys(categorizedDatas2).length !== 0 ? <>
          {Object.keys(categorizedDatas2).map((key, index) => {
            // HIKE - WITH GEAR
            const uniquePackingLocations = [...new Set(categorizedDatas2[key]?.items.sort((a, b) => a.packingLocation.localeCompare(b.packingLocation)).map(item => item.packingLocation))];
            const filteredItemsF = categorizedDataFoods2['PACK - ' + key]?.items
                          return (
                            <>
                            <Card>
                            <CardHeader sx={{ background: '#000', color: '#fff', fontWeight: '900' }} title={key}></CardHeader>
                            <CardContent>
                            <Typography component="div" className="typocol">
                              {uniquePackingLocations.map((location) => {
// Filter items for the current packingLocation
const filteredItems = categorizedDatas2[key]?.items.filter(item => item.packingLocation === location);
const filteredItemsF = categorizedDataFoods2['PACK - ' + key]?.items;
if (filteredItems?.length) {
return (
  <Table className="finallist" id={'finaltbl' + location.replace(' ', '-')} key={location}>
    <TableHead>
      <TableRow>
        <TableCell><b style={{textTransform:'uppercase'}}>{location == 'Base Weight' ? 'BASE' : location}</b></TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
    
      {filteredItems.map((item, index) => {
        const categorymatch = Object.values(newata?.GearCategories).filter(chunk => chunk.id === item.CategoryId)
        return (
        <TableRow key={index}>
          <TableCell>{categorymatch[0].label} &raquo; {item.SubCategory}</TableCell>
          <TableCell>{item.Quantity} x {item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {item.Brand == "- NO BRAND" || item.Brand == "" || item.Brand == "-"  ? '-' : item.Brand} {item.Name}</TableCell>
        </TableRow>
        )}
      )}

      </TableBody>
      <TableFooter>
      <TableRow className="totalrow">
      <TableCell>
      TOTAL
      </TableCell>
      <TableCell className="totalcell">
      {location == 'Base Weight' ? key == tr[0]?.Name ? resSetHikeGBW0.toFixed(3) : key == tr[1]?.Name ? resSetHikeGBW1.toFixed(3) : key == tr[2]?.Name ? resSetHikeGBW2.toFixed(3) : key == tr[3]?.Name ? resSetHikeGBW3.toFixed(3) : null : null}
      {location == 'Consumables' ? key == tr[0]?.Name ? resSetHikeG0.toFixed(3) : key == tr[1]?.Name ? resSetHikeG1.toFixed(3) : key == tr[2]?.Name ? resSetHikeG2.toFixed(3) : key == tr[3]?.Name ? resSetHikeG3.toFixed(3) : null : null}
      {location == 'Wear' ? key == tr[0]?.Name ? resSetHikeGW0.toFixed(3) : key == tr[1]?.Name ? resSetHikeGW1.toFixed(3) : key == tr[2]?.Name ? resSetHikeGW2.toFixed(3) : key == tr[3]?.Name ? resSetHikeGW3.toFixed(3) : null : null}
      {getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}
      </TableCell>
      </TableRow>
      {location == 'Consumables' ?
      <TableRow className="packtotal">
        <TableCell>
          <b>PACK TOTAL</b>
        </TableCell>
        <TableCell className="totalcell">
        {key == tr[0]?.Name ? (resSetHikeG0 + resSetHikeGBW0).toFixed(3) : key == tr[1]?.Name ? (resSetHikeG1 + resSetHikeGBW1).toFixed(3) : key == tr[2]?.Name ? (resSetHikeG2 + resSetHikeGBW2).toFixed(3) : key == tr[3]?.Name ? (resSetHikeG3 + resSetHikeGBW3).toFixed(3) : null}
        </TableCell>
      </TableRow> : null }
      </TableFooter>
  </Table>
);
}
})}
<Table className="finallist" id="finaltblConsumables">
<TableHead>
      <TableRow>
        <TableCell><b style={{textTransform:'uppercase'}}>CONSUMABLES</b></TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
{filteredItemsF?.map((item, index) => {
        return (
        <TableRow key={index}>
          <TableCell>{item.Category}</TableCell>
          <TableCell>{item.Quantity} x {item.Name}</TableCell>
        </TableRow>
        )
      }
      )}
      </TableBody>
      <TableFooter>
      <TableRow className="totalrow">
      <TableCell>
      TOTAL
      </TableCell>
      <TableCell className="totalcell">
      {key == tr[0]?.Name ? (resSetHikeF0).toFixed(3) : key == tr[1]?.Name ? (resSetHikeF1).toFixed(3) : key == tr[2]?.Name ? (resSetHikeF2).toFixed(3) : key == tr[3]?.Name ? (resSetHikeF3).toFixed(3) : null}{getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}
      </TableCell>
      </TableRow>
      </TableFooter>
</Table>
<Table id="finaltblPackTotal">
<TableFooter>
<TableRow className="packtotal">
        <TableCell>
        {country?.label == "BIKEPACKING" ? <b>BIKE TOTAL</b> : <b>PACK TOTAL</b>}
        </TableCell>
        <TableCell className="totalcell">
        {key == tr[0]?.Name ? (resSetHikeF0 + resSetHikeGBW0).toFixed(3) : key == tr[1]?.Name ? (resSetHikeF1 + resSetHikeGBW1).toFixed(3) : key == tr[2]?.Name ? (resSetHikeF2 + resSetHikeGBW2).toFixed(3) : key == tr[3]?.Name ? (resSetHikeF3 + resSetHikeGBW3).toFixed(3) : null}{getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}
        </TableCell>
      </TableRow>
      </TableFooter>
</Table>
                            </Typography>
                            </CardContent>
                            </Card>
                            </>
                          )
          })}
 </> : 
 <>
 {Object.keys(categorizedDataFoods2).map((key, index) => {
  // HIKE - WITHOUT GEAR
            const uniquePackingLocations = [...new Set(categorizedDataFoods2[key]?.items.sort((a, b) => a.packingLocation.localeCompare(b.packingLocation)).map(item => item.packingLocation))];
            const filteredItemsF = categorizedDataFoods2[key]?.items
                          return (
                            <>
                            <Card>
                            <CardHeader sx={{ background: '#000', color: '#fff', fontWeight: '900' }} title={key}></CardHeader>
                            <CardContent>
                            <Typography component="div" className="typocol">
<Table className="finallist" id="finaltblConsumables">
    <TableBody>
{filteredItemsF?.map((item, index) => {
        return (
        <TableRow key={index}>
          <TableCell>{item.Category}</TableCell>
          <TableCell>{item.Quantity} x {item.Name}</TableCell>
        </TableRow>
        )
      }
      )}
      </TableBody>
      <TableFooter>
      <TableRow className="totalrow">
      <TableCell>
      TOTAL
      </TableCell>
      <TableCell className="totalcell">
      {key.includes(tr[0]?.Name) ? (resSetHikeF0).toFixed(3) : key.includes(tr[1]?.Name) ? (resSetHikeF1).toFixed(3) : key.includes(tr[2]?.Name) ? (resSetHikeF2).toFixed(3) : key.includes(tr[3]?.Name) ? (resSetHikeF3).toFixed(3) : null}{getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}
      </TableCell>
      </TableRow>
      </TableFooter>
</Table>
                            </Typography>
                            </CardContent>
                            </Card>
                            </>
                          )
          })}
 </> }
</div> : country?.label == "AIR +" ? 
<div className="final-list-container">
{Object.keys(categorizedDatas4).length !== 0 ? <>
{Object.keys(categorizedDatas4).sort().map((key, index) => {
  // AIR - WITH GEAR
  const uniquePackingLocations = [...new Set(categorizedDatas4[key]?.items.map(item => item.packingLocation))];
                return (
                  <>
                  <Card>
                  <CardHeader sx={{ background: '#000', color: '#fff', fontWeight: '900' }} title={key}></CardHeader>
                  <CardContent>
                  <Typography component="div" className="typocol">
                    {uniquePackingLocations.map((location) => {
const filteredItems = categorizedDatasG2[key]?.items.filter(item => item.packingLocation === location);
const filteredItemsF = categorizedDataFoods2[location]?.items.filter(item => item.packingLocation === location);
const locationclean = location?.split(' - ')[0];
const travellerclean = location?.split(' - ')[1];
if (filteredItems?.length || filteredItemsF?.length) {
  return (
  <Table className="finallist" id={'finaltbl' + locationclean.replace(' ', '-')} key={locationclean}>
  <TableBody>
  {filteredItems ? 
          <>
  <TableRow>
    <TableCell><b style={{textTransform:'uppercase'}}>GEAR</b></TableCell>
    <TableCell></TableCell>
  </TableRow>
  
  {filteredItems?.map((item, index) => {
    const categorymatch = Object.values(newata?.GearCategories).filter(chunk => chunk.id === item.CategoryId)
    return (
    <TableRow key={index}>
      <TableCell>{categorymatch[0].label} &raquo; {item.SubCategory}</TableCell>
      <TableCell>{item.Quantity} x {item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {item.Brand == "- NO BRAND" || item.Brand == "" || item.Brand == "-"  ? '-' : item.Brand} {item.Name}</TableCell>
    </TableRow>
    )}
  )}
  </> : null }
  
  {filteredItemsF ?
  <>
  <TableRow>
    <TableCell><b style={{textTransform:'uppercase'}}>FOOD</b></TableCell>
    <TableCell></TableCell>
  </TableRow>
  
  {filteredItemsF?.map((item, index) => {
    return (
    <TableRow key={index}>
      <TableCell>{item.Category}</TableCell>
      <TableCell>{item.Name}</TableCell>
    </TableRow>
    )}
  )}
  </>
  : null}
  </TableBody>
  <TableFooter>
  <TableRow className="totalrow">
  <TableCell>
  TOTAL
  </TableCell>
  <TableCell className="totalcell">
  {locationclean == 'WEAR' ? travellerclean == tr[0]?.Name ? resSetAirWE0.toFixed(3) : travellerclean == tr[1]?.Name ? resSetAirWE1.toFixed(3) : travellerclean == tr[2]?.Name ? resSetAirWE2.toFixed(3) : travellerclean == tr[3]?.Name ? resSetAirWE3.toFixed(3) : null : null}
  {locationclean == 'CARRY' ? travellerclean == tr[0]?.Name ? (resSetAirGBW0 + resSetAirFCO0).toFixed(3) : travellerclean == tr[1]?.Name ? (resSetAirGBW1 + resSetAirFCO1).toFixed(3) : travellerclean == tr[2]?.Name ? (resSetAirGBW2 + resSetAirFCO2).toFixed(3) : travellerclean == tr[3]?.Name ? (resSetAirGBW3 + resSetAirFCO3).toFixed(3) : null : null}
  {locationclean == 'BAG1' ? travellerclean == tr[0]?.Name ? (resSetAirBAG10 + resSetAirFBAG10).toFixed(3) : travellerclean == tr[1]?.Name ? (resSetAirBAG11 + resSetAirFBAG11).toFixed(3) : travellerclean == tr[2]?.Name ? (resSetAirBAG12 + resSetAirFBAG12).toFixed(3) : travellerclean == tr[3]?.Name ? (resSetAirBAG13 + resSetAirFBAG13).toFixed(3) : null : null}
  {locationclean == 'BAG2' ? travellerclean == tr[0]?.Name ? (resSetAirBAG20 + resSetAirFBAG20).toFixed(3) : travellerclean == tr[1]?.Name ? (resSetAirBAG21 + resSetAirFBAG21).toFixed(3) : travellerclean == tr[2]?.Name ? (resSetAirBAG22 + resSetAirFBAG22).toFixed(3) : travellerclean == tr[3]?.Name ? (resSetAirBAG23 + resSetAirFBAG23).toFixed(3) : null : null}
  {locationclean == 'BAG3' ? travellerclean == tr[0]?.Name ? (resSetAirBAG30 + resSetAirFBAG30).toFixed(3) : travellerclean == tr[1]?.Name ? (resSetAirBAG31 + resSetAirFBAG31).toFixed(3) : travellerclean == tr[2]?.Name ? (resSetAirBAG32 + resSetAirFBAG32).toFixed(3) : travellerclean == tr[3]?.Name ? (resSetAirBAG33 + resSetAirFBAG33).toFixed(3) : null : null}
  {getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}
  </TableCell>
  </TableRow>
  </TableFooter>
  </Table>
  );
  }
  })}
                        </Typography>
                        </CardContent>
                        </Card>
                  </>
                )
})}</> : 
<>
{Object.keys(categorizedDataFoods2).sort().map((key, index) => {
  // AIR - WITHOUT GEAR
  const uniquePackingLocations = [...new Set(categorizedDataFoods2[key]?.items.sort((a, b) => a.packingLocation.localeCompare(b.packingLocation)).map(item => item.packingLocation))];
                return (
                  <>
                  <Card>
                  <CardHeader sx={{ background: '#000', color: '#fff', fontWeight: '900' }} title={key.replace("BAG1", "BAG 1").replace("BAG2", "BAG 2").replace("BAG3", "BAG 3").replace("CARRY", "CARRY ON")}></CardHeader>
                  <CardContent>
                  <Typography component="div" className="typocol">
                    {uniquePackingLocations.map((location) => {
const filteredItemsF = categorizedDataFoods2[location]?.items.filter(item => item.packingLocation === location);
const locationclean = location.split(' - ')[0];
if (filteredItemsF?.length) {
return (
<Table className="finallist" id={'finaltbl' + locationclean.replace(' ', '-')} key={locationclean}>
<TableBody>

<>
<TableRow>
<TableCell><b style={{textTransform:'uppercase'}}>FOOD</b></TableCell>
<TableCell></TableCell>
</TableRow>

{filteredItemsF?.map((item, index) => {
return (
<TableRow key={index}>
<TableCell>{item.Category}</TableCell>
<TableCell>{item.Name}</TableCell>
</TableRow>
)}
)}
</>

</TableBody>
<TableFooter>
<TableRow className="totalrow">
<TableCell>
TOTAL
</TableCell>
<TableCell className="totalcell">
{locationclean == 'CARRY' ? key.includes(tr[0]?.Name) ? (resSetAirGBW0 + resSetAirFCO0).toFixed(3) : key.includes(tr[1]?.Name) ? (resSetAirGBW1 + resSetAirFCO1).toFixed(3) : key.includes(tr[2]?.Name) ? (resSetAirGBW2 + resSetAirFCO2).toFixed(3) : key.includes(tr[3]?.Name) ? (resSetAirGBW3 + resSetAirFCO3).toFixed(3) : null : null}
{locationclean == 'BAG1' ? key.includes(tr[0]?.Name) ? (resSetAirBAG10 + resSetAirFBAG10).toFixed(3) : key.includes(tr[1]?.Name) ? (resSetAirBAG11 + resSetAirFBAG11).toFixed(3) : key.includes(tr[2]?.Name) ? (resSetAirBAG12 + resSetAirFBAG12).toFixed(3) : key.includes(tr[3]?.Name) ? (resSetAirBAG13 + resSetAirFBAG13).toFixed(3) : null : null}
{locationclean == 'BAG2' ? key.includes(tr[0]?.Name) ? (resSetAirBAG20 + resSetAirFBAG20).toFixed(3) : key.includes(tr[1]?.Name) ? (resSetAirBAG21 + resSetAirFBAG21).toFixed(3) : key.includes(tr[2]?.Name) ? (resSetAirBAG22 + resSetAirFBAG22).toFixed(3) : key.includes(tr[3]?.Name) ? (resSetAirBAG23 + resSetAirFBAG23).toFixed(3) : null : null}
{locationclean == 'BAG3' ? key.includes(tr[0]?.Name) ? (resSetAirBAG30 + resSetAirFBAG30).toFixed(3) : key.includes(tr[1]?.Name) ? (resSetAirBAG31 + resSetAirFBAG31).toFixed(3) : key.includes(tr[2]?.Name) ? (resSetAirBAG32 + resSetAirFBAG32).toFixed(3) : key.includes(tr[3]?.Name) ? (resSetAirBAG33 + resSetAirFBAG33).toFixed(3) : null : null}
{getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}
</TableCell>
</TableRow>
</TableFooter>
</Table>
);
}
})}
                  </Typography>
                  </CardContent>
                  </Card>
                  </>
                )
})}
</>
}
</div>
          : 
          <div className="final-list-container"> 
          {Object.keys(categorizedDatas4).length !== 0 ? <>
            {Object.keys(categorizedDatas4).sort().map((key, index) => {
            // ALL OTHER TRIPS - WITH GEAR
            const uniquePackingLocations = [...new Set(categorizedDatas4[key]?.items.map(item => item.packingLocation))];
                              return (
                                <>
                                <Card>
                                <CardHeader sx={{ background: '#000', color: '#fff', fontWeight: '900' }} title={key}></CardHeader>
                                <CardContent>
                                <Typography component="div">
                                  {uniquePackingLocations.map((location) => {
  const filteredItems = categorizedDatasG2[key]?.items.filter(item => item.packingLocation === location);
  const filteredItemsF = categorizedDataFoods2[key]?.items.filter(item => item.packingLocation === location);
  if (filteredItems?.length || filteredItemsF?.length) {
    return (
      <Table className="finallist" key={location}>
        <TableBody>
        {filteredItems ? 
        <>
          <TableRow>
            <TableCell><b style={{textTransform:'uppercase'}}>GEAR</b></TableCell>
            <TableCell></TableCell>
          </TableRow>
        
        
          {filteredItems?.map((item, index) => {
            const categorymatch = Object.values(newata?.GearCategories).filter(chunk => chunk.id === item.CategoryId)
            return (
            <TableRow key={index}>
              <TableCell>{categorymatch[0]?.label} &raquo; {item.SubCategory}</TableCell>
              <TableCell>{item.Quantity} x {item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {item.Brand == "- NO BRAND" || item.Brand == "" || item.Brand == "-"  ? '-' : item.Brand} {item.Name}</TableCell>
            </TableRow>
            )
          }
          )}
          

          <TableRow className="totalrow MuiTableRow-footer">
          <TableCell className="MuiTableCell-footer">
          TOTAL
          </TableCell>
          <TableCell className="MuiTableCell-footer totalcell">
          {location == '4x4' ? resSet4x4.toFixed(3) : null}
          {location == 'Camper' ? resSetCamper.toFixed(3) : null}
          {location == 'Caravan' ? resSetCaravan.toFixed(3) : null}
          {location == 'Boat' ? resSetBoat.toFixed(3) : null}  
          {location == 'Motorbike' ? resSetBike.toFixed(3) : null} 
          {getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}        
          </TableCell>
          </TableRow>

          </> : null }
          
          {filteredItemsF ? 
          <>
          <TableRow>
            <TableCell style={{borderBottom:'1px solid #202020'}}><b style={{textTransform:'uppercase'}}>FOOD</b></TableCell>
            <TableCell style={{borderBottom:'1px solid #202020'}}></TableCell>
          </TableRow>

          {filteredItemsF?.map((item, index) => {
            return (
            <TableRow key={index}>
              <TableCell>{item.Category}</TableCell>
              <TableCell>{item.Quantity} x {item.Name}</TableCell>
            </TableRow>
            )
          }
          )}
          
          <TableRow className="totalrow MuiTableRow-footer">
          <TableCell className="MuiTableCell-footer">
          TOTAL
          </TableCell>
          <TableCell className="MuiTableCell-footer totalcell">
          {location == '4x4' ? resSet4x4F.toFixed(3) : null}
          {location == 'Camper' ? resSetCamperF.toFixed(3) : null}
          {location == 'Caravan' ? resSetCaravanF.toFixed(3) : null}
          {location == 'Boat' ? resSetBoatF.toFixed(3) : null}  
          {location == 'Motorbike' ? resSetBikeF.toFixed(3) : null} 
          {getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}        
          </TableCell>
          </TableRow>
          </>
          : null }
          </TableBody>
          <TableFooter>
          <TableRow className="packtotal">
          <TableCell style={{textTransform:'uppercase'}}>
          {location} TOTAL
          </TableCell>
          <TableCell className="totalcell">
          {location == '4x4' ? (resSet4x4 + resSet4x4F).toFixed(3) : null}
          {location == 'Camper' ? (resSetCamper + resSetCamperF).toFixed(3) : null}
          {location == 'Caravan' ? (resSetCaravan + resSetCaravanF).toFixed(3) : null}
          {location == 'Boat' ? (resSetBoat + resSetBoatF).toFixed(3) : null}  
          {location == 'Motorbike' ? (resSetBike + resSetBikeF).toFixed(3) : null} 
          {getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}        
          </TableCell>
          </TableRow>
          </TableFooter>
      </Table>
    );
  }
  })}
                                </Typography>
                                </CardContent>
                                </Card>
                            </>
                          )
          })}
          </> : 
          <>
          {Object.keys(categorizedDataFoods2).sort().map((key, index) => {
            // ALL OTHER TRIPS - WITHOUT GEAR
            const uniquePackingLocations = [...new Set(categorizedDataFoods2[key]?.items.map(item => item.packingLocation))];
                          return (
                            <>
                            <Card>
                            <CardHeader sx={{ background: '#000', color: '#fff', fontWeight: '900' }} title={key}></CardHeader>
                            <CardContent>
                            <Typography component="div">
                              {uniquePackingLocations.map((location) => {
// Filter items for the current packingLocation
const filteredItemsF = categorizedDataFoods2[key]?.items.filter(item => item.packingLocation === location);
if (filteredItemsF?.length) {
return (
  <Table className="finallist" key={location}>
    <TableHead>
      <TableRow>
        <TableCell><b style={{textTransform:'uppercase'}}>FOOD</b></TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>

      <>
      {filteredItemsF?.map((item, index) => {
        return (
        <TableRow key={index}>
          <TableCell>{item.Category}</TableCell>
          <TableCell>{item.Quantity} x {item.Name}</TableCell>
        </TableRow>
        )
      }
      )}
      
      <TableRow className="totalrow MuiTableRow-footer">
      <TableCell className="MuiTableCell-footer">
      TOTAL
      </TableCell>
      <TableCell className="MuiTableCell-footer totalcell">
      {location == '4x4' ? resSet4x4F.toFixed(3) : null}
      {location == 'Camper' ? resSetCamperF.toFixed(3) : null}
      {location == 'Caravan' ? resSetCaravanF.toFixed(3) : null}
      {location == 'Boat' ? resSetBoatF.toFixed(3) : null}  
      {location == 'Motorbike' ? resSetBikeF.toFixed(3) : null} 
      {getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}        
      </TableCell>
      </TableRow>
      </>
      </TableBody>
      <TableFooter>
      <TableRow className="packtotal">
      <TableCell style={{textTransform:'uppercase'}}>
      {location} TOTAL
      </TableCell>
      <TableCell className="totalcell">
      {location == '4x4' ? (resSet4x4 + resSet4x4F).toFixed(3) : null}
      {location == 'Camper' ? (resSetCamper + resSetCamperF).toFixed(3) : null}
      {location == 'Caravan' ? (resSetCaravan + resSetCaravanF).toFixed(3) : null}
      {location == 'Boat' ? (resSetBoat + resSetBoatF).toFixed(3) : null}  
      {location == 'Motorbike' ? (resSetBike + resSetBikeF).toFixed(3) : null} 
      {getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}        
      </TableCell>
      </TableRow>
      </TableFooter>
  </Table>
);
}
})}
                            </Typography>
                            </CardContent>
                            </Card>
                            </>
                          )
          })}
          </>
          }
          

</div>

}</>

        )}

        { /* selectedTab === 6 && (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>FILENAME</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allImages?.map((image) => {
                    const dataname = image?.Test2?.split('.')
                    return (
                      <TableRow key={image}>
                        <TableCell>
                          <div>
                            <p onClick={() => openImage(image.Test)}>{image.Test2}</p>
                            {dataname.length && dataname[1] == "pdf" ? (
                              <>
                                {togle == true && image.Test == url && (
                                  <div style={{ width: '100%', overflow: "hidden" }}>
                                    <PDF urls={url} />
                                  </div>
                                )}
                              </>
                            ) : dataname.length && dataname[1] == "gpx" ? (
                              <>
                                {togle == true && image.Test == url && (
                                  <Button><a style={{ display: 'block' }} href={url}>Download</a></Button>
                                )}
                              </>
                            ) : (
                                  <>
                                    {togle == true && image.Test == url && (
                                      <>
                                        <img style={{ width: '100%' }} src={url} alt="no image" />
                                      </>
                                    )}
                                  </>
                                )}
                          </div>
                        </TableCell>
                        <TableCell>
                          <IconButton size="small" aria-label="delete" onClick={() => deleteFromFirebase(image.Test)}><DeleteIcon /></IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <br /><br />
            <div className="travellerscontainer">
                <input style={{fontSize:'16px', lineHeight:'36px'}} type="file" accept="image/*,.pdf,.docx,.xlsx,.gpx,text/xml,text/gpsxml,application/gpsxml" onChange={(e) => { onImageChange(e); }} />
                <Button style={{float:'right'}} variant="outlined" onClick={uploadToFirebase}>UPLOAD FILE</Button>
                <div style={{clear:'both'}}></div>
                </div>
          </>
                ) */}
        {selectedTab === 7 && (
          <>
          <h4>JOURNAL</h4>
            <TextField InputLabelProps={{ shrink: true, }} fullWidth id="standard-basic" value={item.Notes} name="Notes" onChange={onChange} multiline rows={12} variant="outlined" />
            { /* <br />
            <span className="renderedtext"><Linkify>{item.Notes}</Linkify></span> */ }
            <br /><br />
          </>
        )}
        {selectedTab === 999 && (
          <>
          <h4>BUDGET</h4>
          <b style={{fontSize: '14px'}}>GENERAL</b><br/>
          <div className="fields-space">
            <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Camping Fees" value={item?.Budget_CampingFees} name="Budget_CampingFees" onChange={onChange} onWheel={(e) => e.target.blur()} type="number" variant="outlined" />
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Accommodation" value={item?.Budget_Accommodation} name="Budget_Accommodation" onChange={onChange} onWheel={(e) => e.target.blur()} type="number" variant="outlined" />
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Food Shopping" value={item?.Budget_FoodShopping} name="Budget_FoodShopping" onChange={onChange} onWheel={(e) => e.target.blur()} type="number" variant="outlined" />
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Eating Out" value={item?.Budget_EatingOut} name="Budget_EatingOut" onChange={onChange} onWheel={(e) => e.target.blur()} type="number" variant="outlined" />
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Tours" value={item?.Budget_Tours} name="Budget_Tours" onChange={onChange} onWheel={(e) => e.target.blur()} type="number" variant="outlined" />
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Additional Costs" value={item?.Budget_Additional} name="Budget_Additional" onChange={onChange} onWheel={(e) => e.target.blur()} type="number" variant="outlined" />
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Airfares" value={item?.Budget_Airfares} name="Budget_Airfares" onChange={onChange} onWheel={(e) => e.target.blur()} type="number" variant="outlined" />
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Hire Car" value={item?.Budget_HireCar} name="Budget_HireCar" onChange={onChange} onWheel={(e) => e.target.blur()} type="number" variant="outlined" /><br /><br />
          </div>  
          <b style={{fontSize: '14px'}}>FUEL</b><br/>
          <div className="fields-space">
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label={getdataT?.DistancePref == true || getdataT?.DistancePref == undefined ? 'Kilometers' : 'Miles'} value={item.Budget_KM} name="Budget_KM" onChange={onChange} onWheel={(e) => e.target.blur()} type="number" variant="outlined" />
          {getdataT?.DistancePref == true || getdataT?.DistancePref == undefined ? <>
            <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="L/100km" value={item.Budget_L100KM} name="Budget_L100KM" onChange={onChange} type="number" onWheel={(e) => e.target.blur()} variant="outlined" />
          </> : <>
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="M/per gallon" value={item.Budget_MPG} name="Budget_MPG" onChange={onChange} type="number" onWheel={(e) => e.target.blur()} variant="outlined" />
          </>}
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Estimate Fuel Price" value={item.Budget_FuelPrice} name="Budget_FuelPrice" onChange={onChange} onWheel={(e) => e.target.blur()} type="number" variant="outlined" /><br /><br />
          </div>
            <span className="total">General: ${(Number(item.Budget_CampingFees) + Number(item.Budget_Accommodation) + Number(item.Budget_FoodShopping) + Number(item.Budget_EatingOut) + Number(item.Budget_Tours) + Number(item.Budget_Additional) + Number(item.Budget_Airfares) + Number(item.Budget_HireCar)).toLocaleString("en-US")}</span><br /><br />
          {getdataT?.DistancePref == true || getdataT?.DistancePref == undefined ? <>
          <span className="total">Fuel: ${(item.Budget_KM / 100 * item.Budget_L100KM * item.Budget_FuelPrice).toLocaleString("en-US")}</span><br /><br /></>
          : <>
          <span className="total">Fuel: ${isNaN(item.Budget_KM / item.Budget_MPG * item.Budget_FuelPrice) ? 0 : (item.Budget_KM / item.Budget_MPG * item.Budget_FuelPrice).toLocaleString("en-US")}</span><br /><br /></>
          }
          
          {getdataT?.DistancePref == true || getdataT?.DistancePref == undefined ? <>
            <span className="total">Total: ${((item.Budget_KM / 100 * item.Budget_L100KM * item.Budget_FuelPrice) + Number(item.Budget_CampingFees) + Number(item.Budget_Accommodation) + Number(item.Budget_FoodShopping) + Number(item.Budget_EatingOut) + Number(item.Budget_Tours) + Number(item.Budget_Additional) + Number(item.Budget_Airfares) + Number(item.Budget_HireCar)).toLocaleString("en-US")}</span><br /><br />
          </> : <>
          <span className="total">Total: ${(isNaN(item.Budget_KM / item.Budget_MPG * item.Budget_FuelPrice) ? 0 : (item.Budget_KM / item.Budget_MPG * item.Budget_FuelPrice) + Number(item.Budget_CampingFees) + Number(item.Budget_Accommodation) + Number(item.Budget_FoodShopping) + Number(item.Budget_EatingOut) + Number(item.Budget_Tours) + Number(item.Budget_Additional) + Number(item.Budget_Airfares) + Number(item.Budget_HireCar)).toLocaleString("en-US")}</span><br /><br />
              </>
          }

          </>
        )}
        { /*
        <Button
          disableElevation
          variant="outlined"
          color="default"
          size="large"
          className="btn"
          onClick={onSubmit}
          startIcon={<SaveAltTwoToneIcon />}
        >
          Update Trip
      </Button>&nbsp;&nbsp;
        */ }
        
        
      <div style={{ display: 'none' }}><br/>
          <Button
            disableElevation
            variant="outlined"
            color="default"
            size="large"
            className="btnaa"
            onClick={onSubmit10second}
            startIcon={<SaveAltTwoToneIcon />}
          >
            Update Trip
      </Button>&nbsp;&nbsp;
      </div>
      </div>
    </Paper>
    </div>
  );
};
export default UpdateItem;


const ListOfTraivlers = (userinfo, addtraivler, setaddTraverler) => {
  const AddTraiverler = (e, item) => {
    if (e.target.checked) {
      setaddTraverler([...addtraivler, item])
    } else {
      const filter = addtraivler.filter(el => el.Name != item.Name)
      setaddTraverler(filter)
    }
  }
  return (

    <>
      {userinfo && userinfo?.map((e, index) => (
        <>
          {e?.Travellers?.map(el => {
            const getdata = addtraivler.filter(ele => ele.Name == el.Name ? true : false)
            return (
              <FormControlLabel key={index + e.id} className="avoid"
                control={
                  <Checkbox
                    key={`cb-${index}dada`}
                    name={(el.Name) + ' ' + (el.Weight)}
                    value={el.Name}
                    checked={getdata.length ? true : false}
                    id={el.Name}
                    color="primary"
                    onChange={(e) => AddTraiverler(e, el)}
                  />
                }
                label={<span className="mob-radio" style={{ fontWeight: 'bold' }}>{el.Name} { /* <span style={{ fontWeight: 'normal', display: 'inline-block' }}>{el.Weight}kg</span> */ }</span>}
              />
            )
          })}
        </>
      ))}
    </>
  )
}