import React, { useState, useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import firebase from "../../config"
import { v4 as uuidv4 } from 'uuid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SaveAltTwoToneIcon from '@material-ui/icons/SaveAltTwoTone';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Divider from '@material-ui/core/Divider';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useItemsUser = () => {
    const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
    const user = firebase.auth().currentUser;
    const user1 = user.uid;
    useEffect(() => {
        const unsubscribe = firebase
            .firestore() //access firestore
            .collection("user") //access "items" collection
            .where("id", "==", `${user1}`)
            .onSnapshot(snapshot => {
                //You can "listen" to a document with the onSnapshot() method.
                const listItemsUsers = snapshot.docs.map(doc => ({
                    //map each document into snapshot
                    id: doc.id, //id and data pushed into items array
                    ...doc.data() //spread operator merges data to id.
                }));
                setItems(listItemsUsers); //items is equal to listItems
            });
        return () => unsubscribe();
    }, []);
    return items;
};
const CustomeGear = ({setDirty, dirty}) => {
    const user = firebase.auth().currentUser;
    const user1 = user.uid;
    const [customgear, setcustomgear] = useState([{
        label: '',
        value: '',
        id: uuidv4(),
        catid: '',
        subCategory: [
            { label: '', value: '', id: uuidv4() },
        ]
    }
    ])
    const [customgearUpdate, setcustomgearUpdate] = useState(null)

    const data = useItemsUser()

    useEffect(() => {
        setcustomgearUpdate(data[0]?.GearCategories)
    }, [data])
    
    const handleRemoveFields2 = (index) => {
        const newData = customgearUpdate?.filter((el, i) => i != index)
        setcustomgearUpdate(newData)
    }

    const [error, setError] = useState(true);

    const handleAddFields2 = () => {
        const values = [...customgearUpdate];
        values.push({
            label: '-',
            value: '-',
            id: uuidv4(),
            catid: '',
            subCategory: [
                { label: '-', value: '-', id: uuidv4() },
            ]
        });
        setcustomgearUpdate(values);
        setError(true);
    };
    const handleAddSubFields1 = (i) => {
        const values = [...customgearUpdate];
        values[i].subCategory.push({ label: '', value: '', id: uuidv4() });
        setcustomgearUpdate(values);
    };
    const handleRemoveFieldsSub2 = (i, index) => {
        const values = [...customgearUpdate];
        values[i]?.subCategory?.splice(index, 1);
        setcustomgearUpdate(values);
    }
    const bottomnavtarget = document.querySelector(".MuiBottomNavigation-root");
    const onChangeHandler2 = (e, i, index) => {
        setDirty(true);
        bottomnavtarget.classList.add('cf');
        const values = [...customgearUpdate];
        if (e.target.name === "Category") {
            values[i].label = e.target.value.toUpperCase();
            values[i].value = e.target.value.toUpperCase();
        }
        if (e.target.name === "value") {
            values[i].subCategory[index].value = e.target.value.toUpperCase();
            values[i].subCategory[index].label = e.target.value.toUpperCase();
        }
        setcustomgearUpdate(values)
    }

    const SubmitHandler = (e) => {
        e.preventDefault()
        if (data[0].GearCategories.length) {
            customgear.map(el => {
                data[0].GearCategories.push(el)
            })
        }
        {/* SORT FOR BELOW
        .sort(function (a, b) {
            var textA = a.label.toUpperCase();
            var textB = b.label.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })*/ }
        customgearUpdate?.map((e) => ({ ...e, subCategory: e.subCategory.sort((function (a, b) {
            var textA = a.label.toUpperCase();
            var textB = b.label.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }))}))
        
        
        firebase
            .firestore()
            .collection("user")
            .doc(user1)
            .update({
                GearCategories: customgearUpdate
            }).then(() => {
                setDirty(false)
                bottomnavtarget.classList.remove('cf')
                alert('Categories Updated')
            }
            )
    }

    const length = customgearUpdate?.length - 1;
  

    function onDragEnd(result) {
        const newItems = [...customgearUpdate];
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);
        newItems.forEach((item, index) => {
            item.catid = index;
          });
        setcustomgearUpdate(newItems);
      }

    return (
        <form onSubmit={SubmitHandler}>
            <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div className="droppable dndacc" {...provided.droppableProps} ref={provided.innerRef}>
            {customgearUpdate?.length && customgearUpdate?.map((el, i) => {
                return (
                    <Draggable key={'item-'+i} draggableId={'item-'+i} index={i}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Accordion style={{margin:'1px 0'}} elevation={1} defaultExpanded={false}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <DragIndicatorIcon className="mobilehide"></DragIndicatorIcon><Typography style={{fontWeight:'bold'}}>{el.label ? el.label : <span style={{color:'red', textTransform:'uppercase'}}>Please enter category</span>}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                    <>
                        <span className="profile-primary">
                            <TextField
                                InputLabelProps={{ shrink: true, }}
                                style={{ width: '100%' }}
                                id="standard-basic"
                                label="Category"
                                value={el.label}
                                required={true}
                                name="Category"
                                onChange={(e) => onChangeHandler2(e, i)}
                                type="text"
                                variant="outlined"
                            />

                            <IconButton size="small" aria-label="edit" onClick={() => handleRemoveFields2(i)}><RemoveCircleIcon /></IconButton>
                        </span>

                        {el.subCategory.map((item, index) => {
                            return (
                                <span className="profile-sub">
                                    <TextField
                                        InputLabelProps={{ shrink: true, }}
                                        id="standard-basic"
                                        label="Subcategory"
                                        value={item.label}
                                        required={true}
                                        name="value"
                                        onChange={(e) => onChangeHandler2(e, i, index)}
                                        type="text"
                                        variant="outlined"
                                        
                                    />
                                    <IconButton size="small" aria-label="edit" onClick={() => handleRemoveFieldsSub2(i, index)}><RemoveCircleIcon /></IconButton>
                                </span>
                            )
                        })}
                        <br /><Button className="profile-add" variant="outlined" size="small" aria-label="edit" onClick={() => handleAddSubFields1(i)}>Add SubCategory</Button>
                        <br /><br />
                    </>
                    </AccordionDetails></Accordion></div>)}</Draggable>)}
                )
            }
            {provided.placeholder}
             </div>)}</Droppable></DragDropContext>
             <Button size="large" className="btn stickybtn mobilesplitbtn blackbtn" variant="outlined" aria-label="edit" onClick={() => handleAddFields2()}>Add Category</Button>
            <Button
                disableElevation
                variant="outlined"
                color="default"
                size="large"
                type="submit"
                className="btn stickybtn stickybtn2 stickybtn3 mobilesplitbtn blackbtn dirtysubmit"
            >
                UPDATE CATEGORIES
             </Button>
             
        </form>
    )
}
export default CustomeGear