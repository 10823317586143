import React, { useContext, useState, useEffect } from "react";
import { Link, Redirect,useHistory } from "react-router-dom";
import { AuthContext } from "../Auth";
// import firebaseConfig from "../config.js";

import firebase from "../../config"

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils } from '@fortawesome/free-solid-svg-icons'
import { faAppleAlt } from '@fortawesome/free-solid-svg-icons'
import { faRoute } from '@fortawesome/free-solid-svg-icons'
import { faCampground } from '@fortawesome/free-solid-svg-icons'
import { faTruckMonster } from '@fortawesome/pro-solid-svg-icons'
import { faUserVneck } from '@fortawesome/pro-solid-svg-icons'


const BottomNav = () => {
  const pathname = window.location.pathname;
  const spli = window?.location?.search?.split('?')
  const history = useHistory() 
  const [value, setValue] = React.useState(pathname);
  useEffect(() => {
    return history.listen((location) => { 
       setValue(location.pathname)
    }) 
 },[history]) 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { currentUser } = useContext(AuthContext);
  if (!currentUser) {
    return (
      <>
      </>
    );
  }
  const redloader = () => {
    history.push('/trips/index')
    window.location.reload();
  }

  // disabled={value === '/user/index'}

  return (
    <>
      <BottomNavigation value={value} onChange={handleChange} showLabels={true}>
        <BottomNavigationAction onClick={() => pathname == '/user/index' && window.location.reload()} label="PROFILE" value="/user/index" icon={<FontAwesomeIcon icon={faUserVneck} size="lg" style={{color: '#a5a5a5'}} />} component={Link} to='/user/index' />
        <BottomNavigationAction onClick={() => pathname == '/gear/index' && window.location.reload()} label="ALL GEAR" value="/gear/index" icon={<FontAwesomeIcon icon={faCampground} size="lg" style={{color: '#a5a5a5'}} />} component={Link} to='/gear/index' />
        <BottomNavigationAction onClick={() => pathname == '/food/index' && window.location.reload()} label="ALL FOOD" value="/food/index" icon={<FontAwesomeIcon icon={faUtensils} size="lg" style={{color: '#a5a5a5'}} />} component={Link} to='/food/index' />
        { /* <BottomNavigationAction onClick={() => pathname == '/garage/index' && window.location.reload()} label="VEHICLES +" value="/garage/index" icon={<FontAwesomeIcon icon={faTruckMonster} size="lg" style={{color: '#a5a5a5'}} />} component={Link} to='/garage/index' /> */ }
        <BottomNavigationAction className="bottom-trips" onClick={() => pathname == '/trips/index' && window.location.reload()} label="TRIPS" value="/trips/index" icon={<FontAwesomeIcon style={{fontSize:'40px', color: '#a5a5a5'}} icon={faRoute} size="lg" />} component={Link} to='/trips/index' />
      </BottomNavigation>
    </>
  );
};

export default BottomNav;